import NamedDto from 'modules/base/dto/NamedDto';
import CompetitionPartDetailsDto from './CompetitionPartDetailsDto';
import RaceDetailsDto from './RaceDetailsDto';
import CompetitionDistanceDetailsDto from './CompetitionDistanceDetailsDto';
import EntryPriceDetailsDto from './EntryPriceDetailsDto';

export class CompetitionDetailsDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.address = null;
		/** @type {string} */
		this.coordLat = null;
		/** @type {string} */
		this.coordLng = null;
		/** @type {Date} */
		this.date = null;
		/** @type {Date} */
		this.endDate = null;
		//rendező adatai
		/** @type {string} */
		this.orgName = null;
		/** @type {string} */
		this.orgEmail = null;
		/** @type {string} */
		this.orgPhoneNumber = null;
		/** @type {string} */
		this.orgWebsite = null;
		/** @type {string} */
		this.directorName = null;
		/** @type {string} */
		this.directorEmail = null;
		/** @type {string} */
		this.directorPhoneNumber = null;
		/** @type {string} */
		this.orgPresName = null;
		/** @type {string} */
		this.orgPresEmail = null;
		/** @type {string} */
		this.orgPresPhoneNumber = null;
		//MTSZ küldöttek
		/** @type {string} */
		this.refereeName = null;
		/** @type {string} */
		this.refereeEmail = null;
		/** @type {string} */
		this.refereePhoneNumber = null;
		/** @type {string} */
		this.supervisorName = null;
		/** @type {string} */
		this.supervisorEmail = null;
		/** @type {string} */
		this.supervisorPhoneNumber = null;
		//Nevezés
		/** @type {string} */
		this.admissionFee = null;
		/** @type {string} */
		this.entryFee = null;
		/** @type {string} */
		this.entryOtherInfo = null;
		//Versenyiroda
		/** @type {string} */
		this.officeAddress = null;
		/** @type {string} */
		this.officeHours = null;
		/** @type {string} */
		this.officeRepName = null;
		/** @type {string} */
		this.officeRepEmail = null;
		/** @type {string} */
		this.officeRepPhoneNumber = null;
		//Technikai információ
		/** @type {string} */
		this.techAddress = null;
		/** @type {Date} */
		this.techDate = null;
		/** @type {string} */
		this.waterTemp = null;
		/** @type {string} */
		this.waterOtherInfo = null;
		/** @type {string} */
		this.bikeTrackOtherInfo = null;
		/** @type {string} */
		this.runningTrackOtherInfo = null;
		/** @type {string} */
		this.timingType = null;
		/** @type {string} */
		this.timingTiming = null;
		/** @type {Boolean} */
		this.bolyozas = null;
		/** @type {string} */
		this.techOtherInfo = null;
		/** @type {string} */
		this.pricing = null;
		/** @type {string} */
		this.parkingInfo = null;
		/** @type {string} */
		this.regulations = null;
		/** @type {string} */
		this.officialWebsite = null;
		/** @type {Date} */
		this.preRegDeadline1 = null;
		/** @type {Date} */
		this.preRegDeadline2 = null;
		/** @type {Date} */
		this.preRegDeadline3 = null;
		/** @type {Date} */
		this.regDeadline = null;
		/** @type {CompetitionPartDetailsDto[]} */
		this.competitionPartList = null;
		/** @type {CompetitionDistanceDetailsDto[]} */
		this.distanceList = null;
		/** @type {RaceDetailsDto[]} */
		this.raceList = null;
		/** @type {EntryPriceDetailsDto[]} */
		this.entryPriceList = null;
		/** @type {NamedDto} */
		this.createdOrg = null;
	}

	/**
	 * @typedef {Object} _CompetitionDetailsDtoProps
	 * @property {string} address
	 * @property {string} coordLat
	 * @property {string} coordLng
	 * @property {Date} date
	 * @property {Date} endDate
	 * @property {string} orgName
	 * @property {string} orgEmail
	 * @property {string} orgPhoneNumber
	 * @property {string} orgWebsite
	 * @property {string} directorName
	 * @property {string} directorEmail
	 * @property {string} directorPhoneNumber
	 * @property {string} orgPresName
	 * @property {string} orgPresEmail
	 * @property {string} orgPresPhoneNumber
	 * @property {string} refereeName
	 * @property {string} refereeEmail
	 * @property {string} refereePhoneNumber
	 * @property {string} supervisorName
	 * @property {string} supervisorEmail
	 * @property {string} supervisorPhoneNumber
	 * @property {string} admissionFee
	 * @property {string} entryFee
	 * @property {string} entryOtherInfo
	 * @property {string} officeAddress
	 * @property {string} officeHours
	 * @property {string} officeRepName
	 * @property {string} officeRepEmail
	 * @property {string} officeRepPhoneNumber
	 * @property {string} techAddress
	 * @property {Date} techDate
	 * @property {string} waterTemp
	 * @property {string} waterOtherInfo
	 * @property {string} bikeTrackOtherInfo
	 * @property {string} runningTrackOtherInfo
	 * @property {string} timingType
	 * @property {string} timingTiming
	 * @property {boolean} bolyozas
	 * @property {string} techOtherInfo
	 * @property {string} pricing
	 * @property {string} parkingInfo
	 * @property {string} regulations
	 * @property {string} officialWebsite
	 * @property {Date} preRegDeadline1
	 * @property {Date} preRegDeadline2
	 * @property {Date} preRegDeadline3
	 * @property {Date} regDeadline
	 * @property {CompetitionPartDetailsDto[]} competitionPartList
	 * @property {CompetitionDistanceDetailsDto[]} distanceList
	 * @property {RaceDetailsDto[]} raceList
	 * @property {EntryPriceDetailsDto[]} entryPriceList
	 * @property {NamedDto} createdOrg
	 * @typedef {import("../NamedDto").NamedDtoProps & _CompetitionDetailsDtoProps} CompetitionDetailsDtoProps
	 */
	/**
	 *
	 * @param {CompetitionDetailsDtoProps} props
	 * @returns {CompetitionDetailsDto}
	 */
	setup(props) {
		super.setup(props);
		this.address = props.address;
		this.coordLat = props.coordLat;
		this.coordLng = props.coordLng;
		this.date = props.date !== null ? new Date(props.date) : null;
		this.endDate = props.endDate !== null ? new Date(props.endDate) : null;
		this.orgName = props.orgName;
		this.orgEmail = props.orgEmail;
		this.orgPhoneNumber = props.orgPhoneNumber;
		this.orgWebsite = props.orgWebsite;
		this.directorName = props.directorName;
		this.directorEmail = props.directorEmail;
		this.directorPhoneNumber = props.directorPhoneNumber;
		this.orgPresName = props.orgPresName;
		this.orgPresEmail = props.orgPresEmail;
		this.orgPresPhoneNumber = props.orgPresPhoneNumber;
		this.refereeName = props.refereeName;
		this.refereeEmail = props.refereeEmail;
		this.refereePhoneNumber = props.refereePhoneNumber;
		this.supervisorName = props.supervisorName;
		this.supervisorEmail = props.supervisorEmail;
		this.supervisorPhoneNumber = props.supervisorPhoneNumber;
		this.admissionFee = props.admissionFee;
		this.entryFee = props.entryFee;
		this.entryOtherInfo = props.entryOtherInfo;
		this.officeAddress = props.officeAddress;
		this.officeHours = props.officeHours;
		this.officeRepName = props.officeRepName;
		this.officeRepEmail = props.officeRepEmail;
		this.officeRepPhoneNumber = props.officeRepPhoneNumber;
		this.techAddress = props.techAddress;
		this.techDate = props.techDate !== null ? new Date(props.techDate) : null;
		this.waterTemp = props.waterTemp;
		this.waterOtherInfo = props.waterOtherInfo;
		this.bikeTrackOtherInfo = props.bikeTrackOtherInfo;
		this.runningTrackOtherInfo = props.runningTrackOtherInfo;
		this.timingType = props.timingType;
		this.timingTiming = props.timingTiming;
		this.bolyozas = props.bolyozas;
		this.techOtherInfo = props.techOtherInfo;
		this.pricing = props.pricing;
		this.parkingInfo = props.parkingInfo;
		this.regulations = props.regulations;
		this.officialWebsite = props.officialWebsite;
		this.preRegDeadline1 = props.preRegDeadline1 !== null ? new Date(props.preRegDeadline1) : null;
		this.preRegDeadline2 = props.preRegDeadline2 !== null ? new Date(props.preRegDeadline2) : null;
		this.preRegDeadline3 = props.preRegDeadline3 !== null ? new Date(props.preRegDeadline3) : null;
		this.regDeadline = props.regDeadline !== null ? new Date(props.regDeadline) : null;
		this.competitionPartList = props.competitionPartList != null ? props.competitionPartList.map(d => new CompetitionPartDetailsDto().setup(d)) : [];
		this.distanceList = props.distanceList != null ? props.distanceList.map(d => new CompetitionDistanceDetailsDto().setup(d)) : [];
		this.raceList = props.raceList != null ? props.raceList.map(d => new RaceDetailsDto().setup(d)) : [];
		this.entryPriceList = props.entryPriceList != null ? props.entryPriceList.map(d => new EntryPriceDetailsDto().setup(d)) : [];
		this.createdOrg = props.createdOrg != null ? new NamedDto().setup(props.createdOrg) : null;
		return this;
	}
}

export default CompetitionDetailsDto;
