import { Link } from 'react-router-dom';

function PersonReRegistrationNotification(props) {
	return (
		<div className="pt-4 pl-32">
			<Link to={`/athlete/edit/${props.item.data}`}> Megnyit</Link>
		</div>
	);
}

export default PersonReRegistrationNotification;
