import { useEffect, useState } from 'react';
import { Button, Icon } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { useLocation } from 'react-router-dom';
import PaymentService from '../service/PaymentService';

function PaymentTableCell(props) {
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const location = useLocation();

	const handleClick = () => {
		setButtonDisabled(true);
		PaymentService.paymentStartWithUrl(props.item.id, `${process.env.REACT_APP_PUBLIC_URL}/?path=${location.pathname}`).then(resp => {
			window.open(resp, '_self');
		});
	};

	useEffect(() => {
		if (props.item !== null) {
			setButtonDisabled(props.item.paymentStatus !== 'Unpaid');
		}
	}, [props.item]);
	return (
		<TableCell className="p-4 md:p-16" scope="row">
			<Button
				className="whitespace-nowrap mx-4"
				variant="contained"
				color="secondary"
				onClick={() => handleClick()}
				disabled={buttonDisabled || props.config.disablePayment}
				startIcon={<Icon className="hidden sm:flex">shopping_cart</Icon>}
			>
				Fizetés
			</Button>
		</TableCell>
	);
}

export default PaymentTableCell;
