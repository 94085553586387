import { Link } from 'react-router-dom';

function AthleteTransferWaitForClubNotification(props) {
	return (
		<div className="pt-4 pl-32">
			<Link to={`/club-answer/${props.item.data.id}`}> Megnyit</Link>
		</div>
	);
}

export default AthleteTransferWaitForClubNotification;
