import nBossConnection from '../nBossConnection';

class LocaleService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'nBoss_LocaleControl';
	}

	/**
	 * @return {Promise<string[]>}
	 */
	getAvailableLocales() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getAvailableLocales`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<string>}
	 */
	getCurrentAdminLocale() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getCurrentAdminLocale`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<string>}
	 */
	getCurrentLocale() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getCurrentLocale`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<string>}
	 */
	getDefaultAdminLocale() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getDefaultAdminLocale`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<string>}
	 */
	getDefaultLocale() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getDefaultLocale`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} locale
	 * @return {Promise<Object>}
	 */
	setCurrentAdminLocale(locale) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/setCurrentAdminLocale`, { locale })
				.then(response => resolve(response))
				.catch(reject);
		});
	}

    	/**
	 * @param {string} locale
	 * @return {Promise<Object>}
	 */
	setCurrentLocale(locale) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/setCurrentLocale`, { locale })
				.then(response => resolve(response))
				.catch(reject);
		});
	}
}

export default new LocaleService();
