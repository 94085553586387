import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormControl, Select, FormHelperText, InputLabel, MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import { Lock, ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';
import { determineEditableAccess } from 'modules/ui/editform/EditFormUtils';

function EditFormAgeGroupField(props) {
	const methods = useFormContext();
	const { control, getValues, setValue } = methods;
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const [items, setItems] = useState([]);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const valueKey = !_.isUndefined(props.fieldConfig.type) && props.fieldConfig.type === 'enum' ? 'value' : 'id';
	const labelKey = !_.isUndefined(props.fieldConfig.type) && props.fieldConfig.type === 'enum' ? 'label' : 'name';

	const formPath = props.fieldPrefix.split('.');
	const competitionPartList = methods.watch('competitionPartList');
	const competitionPartKeyList = methods.watch(`${props.fieldPrefix}.competitionPartKeyList`);

	useEffect(() => {
		if (competitionPartKeyList && competitionPartList) {
			const distanceIdList = [];
			competitionPartKeyList.forEach(pKey => {
				const competitionPart = competitionPartList.find(cp => cp.key === pKey);
				if (competitionPart && competitionPart.distance && distanceIdList.indexOf(competitionPart.distance) === -1) {
					distanceIdList.push(competitionPart.distance);
				}
			});
			if (distanceIdList.length > 0) {
				setItems(props.fieldConfig.formData.ageGroups.filter(ag => props.fieldConfig.formData.distances.findIndex(dist => distanceIdList.indexOf(dist.id) > -1 && dist.ageGroupList.findIndex(dag => dag.id === ag.id) > -1) > -1));
			} else {
				if (value.length) onChange([]);
				setItems([]);
			}
		} else {
			if (value.length) onChange([]);
			setItems([]);
		}
	}, [competitionPartList, competitionPartKeyList]);

	const teamScoreCalcList = useWatch({
		control,
		name: `${props.fieldPrefix}teamScoreCalcList`
	});

	const renderValue = selected => {
		if (_.isArray(selected))
			return items
				.filter(d => selected.indexOf(d[valueKey]) > -1)
				.map(d => d[labelKey])
				.join(', ');
		if (!_.isUndefined(selected) && selected !== null && items) {
			const itemIndex = items.findIndex(d => selected === d[valueKey]);
			if (itemIndex > -1) return items[itemIndex][labelKey];
		}
		return '';
	};

	const handleTeamScoreCalcClick = (e, v) => {
		e.stopPropagation();
		if (teamScoreCalcList.indexOf(v) > -1) {
			setValue(
				`${props.fieldPrefix}teamScoreCalcList`,
				teamScoreCalcList.filter(d => d !== v)
			);
		} else {
			setValue(`${props.fieldPrefix}teamScoreCalcList`, [...teamScoreCalcList, v]);
		}
	};

	return (
		<FormControl className={clsx('w-full mt-8 mb-16', props.fieldConfig.className)} variant="outlined" error={error} required={props.fieldConfig.required}>
			<InputLabel id={`${props.field.name}-label`}>{!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}</InputLabel>
			{items != null ? (
				<Select
					labelId={`${props.field.name}-label`}
					id={props.field.name}
					multiple={!!props.fieldConfig.multiple}
					label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
					value={!!props.fieldConfig.multiple && value === null ? [] : value}
					defaultValue={props.fieldConfig.multiple ? [] : null}
					onChange={e => onChange(e.target.value)}
					inputProps={{ readOnly }}
					renderValue={selected => renderValue(selected)}
					IconComponent={readOnly ? Lock : ArrowDropDown}
				>
					<MenuItem value={null}>&nbsp;</MenuItem>
					{!_.isUndefined(props.fieldConfig.multiple) && props.fieldConfig.multiple
						? items.map((item, i) => (
								<MenuItem key={item[valueKey]} value={item[valueKey]}>
									<Checkbox checked={value && value.indexOf(item[valueKey]) > -1} />
									<ListItemText primary={item[labelKey]} />
									<Checkbox
										onClick={e => handleTeamScoreCalcClick(e, item[valueKey])}
										checked={teamScoreCalcList && teamScoreCalcList.indexOf(item[valueKey]) > -1}
										disabled={value && value.indexOf(item[valueKey]) === -1}
									/>
								</MenuItem>
						  ))
						: items.map((item, i) => (
								<MenuItem key={item[valueKey]} value={item[valueKey]}>
									{item[labelKey]}
								</MenuItem>
						  ))}
				</Select>
			) : null}
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export default EditFormAgeGroupField;
