import { authRoles } from 'app/auth';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import ValueSetEditService from './service/ValueSetEditService';
import OrderableValueSetEditForm from './editform/OrderableValueSetEditForm';
import OrderableValueSetEditService from './service/OrderableValueSetEditService';
import OrderableValueSetTable from './table/OrderableValueSetTable';
import OrderableValueSetTableService from './service/OrderableValueSetTableService';
import OrderableParentValueSetEditService from './service/OrderableParentValueSetEditService';
import OrderableParentValueSetTableService from './service/OrderableParentValueSetTableService';
import OrderableParentValueSetEditForm from './editform/OrderableParentValueSetEditForm';
import DistanceEditForm from '../distance/editform/DistanceEditForm';

const GroupAgeGroupTable = withReducer('groupAgeGroupTable', reducer('groupAgeGroupTable'))(OrderableValueSetTable);
const CompetitionPartQualificationTable = withReducer('competitionPartQualificationTable', reducer('competitionPartQualificationTable'))(OrderableValueSetTable);
const DistanceTable = withReducer('distanceTable', reducer('distanceTable'))(OrderableValueSetTable);
const SportCategoryTable = withReducer('sportCategoryTable', reducer('sportCategoryTable'))(OrderableValueSetTable);
const CompetitorCompetitionTable = withReducer('competitorCompetitionTable', reducer('competitorCompetitionTable'))(OrderableValueSetTable);

export default [
	{
		path: '/group-age-group/edit/:id',
		component: () => <OrderableValueSetEditForm formKey="groupAgeGroupEditForm" service={new ValueSetEditService('groupAgeGroupEditControl')} title="Korosztály csoport" path="group-age-group" />,
		auth: authRoles.valueSet
	},
	{
		path: '/group-age-group/:sessionNameParam?',
		component: () => <GroupAgeGroupTable tableKey="groupAgeGroupTable" service={new OrderableValueSetTableService('groupAgeGroupTableControl')} title="Korosztály csoport" path="group-age-group" />,
		auth: authRoles.valueSet
	},
	{
		path: '/comp-qualification/edit/:id',
		component: () => <OrderableValueSetEditForm formKey="competitionPartQualificationEditForm" service={new OrderableValueSetEditService('competitionPartQualificationEditControl')} title="Versenyminősítés" path="comp-qualification" />,
		auth: authRoles.valueSet
	},
	{
		path: '/comp-qualification/:sessionNameParam?',
		component: () => (
			<CompetitionPartQualificationTable tableKey="competitionPartQualificationTable" service={new OrderableValueSetTableService('competitionPartQualificationTableControl')} title="Versenyminősítés" path="comp-qualification" />
		),
		auth: authRoles.valueSet
	},
	{
		path: '/distance/edit/:id',
		component: () => <DistanceEditForm />,
		auth: authRoles.valueSet
	},
	{
		path: '/distance/:sessionNameParam?',
		component: () => <DistanceTable tableKey="distanceTable" service={new OrderableValueSetTableService('distanceTableControl')} title="Táv" path="distance" />,
		auth: authRoles.valueSet
	},
	{
		path: '/sport-category/edit/:id',
		component: () => <OrderableValueSetEditForm formKey="sportCategoryEditForm" service={new OrderableValueSetEditService('sportCategoryEditControl')} title="Szakág" path="sport-category" />,
		auth: authRoles.valueSet
	},
	{
		path: '/sport-category/:sessionNameParam?',
		component: () => <SportCategoryTable tableKey="sportCategoryTable" service={new OrderableValueSetTableService('sportCategoryTableControl')} title="Szakág" path="sport-category" />,
		auth: authRoles.valueSet
	},
	{
		path: '/competitor-competition/edit/:id',
		component: () => (
			<OrderableValueSetEditForm formKey="competitorCompetitionEditForm" service={new OrderableValueSetEditService('competitorCompetitionEditControl')} title="Egyéni Regisztráció versenyei" path="competitor-competition" />
		),
		auth: authRoles.valueSet
	},
	{
		path: '/competitor-competition/:sessionNameParam?',
		component: () => (
			<CompetitorCompetitionTable tableKey="competitorCompetitionTable" service={new OrderableValueSetTableService('competitorCompetitionTableControl')} title="Egyéni Regisztráció versenyei" path="competitor-competition" />
		),
		auth: authRoles.valueSet
	}
];
