import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PersonEditService from '../service/PersonEditService';

const useStyles = makeStyles(theme => ({
	personalFormInfo: {
		'& .valid': {
			backgroundColor: '#b6d7a8'
		},
		'& .invalid': {
			backgroundColor: '#ea9999'
		}
	}
}));

function PersonFormInfo(props) {
	const { data } = props;
	const classes = useStyles(props);
	const [paid, setPaid] = useState(false);
	const [doctor, setDoctor] = useState(false);
	const [status, setStatus] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const needDataReload = useSelector(rootState => rootState.personUserPaymentTable.tableBrowserEvent.needDataReload);

	useEffect(() => {
		if (data && data.id && (data.isAthlete || data.isCompetitor)) {
			setStatus(data.userStatus === 'Verified');
			setDoctor(data.cachedDoctorValid);
			setPaid(data.cachedCurrentLicensePaymentPaid);
			setLoaded(true);
		} else {
			setLoaded(false);
		}
	}, [data]);

	useEffect(() => {
		if (needDataReload && data && data.id) {
			PersonEditService.getStatus(data.id).then(resp => {
				setStatus(resp.userStatus === 'Verified');
				setDoctor(resp.cachedDoctorValid);
				setPaid(resp.cachedCurrentLicensePaymentPaid);
				setLoaded(true);
			});
		}
	}, [needDataReload]);

	if (loaded) {
		return (
			<div className={clsx(classes.personalFormInfo, 'mb-16 flex flex-row justify-start items-center')}>
				{(data.athleteRegType == null || data.athleteRegType !== 'REG_HU') && <Chip className={`ml-8 ${paid ? 'valid' : 'invalid'}`} label="Befizetett" />}
				{/*<Chip className={`ml-8 ${doctor ? 'valid' : 'invalid'}`} label="Sportorvosi" />*/}
				<Chip className={`ml-8 ${status ? 'valid' : 'invalid'}`} label="Ellenőrzött" />
				{data.cachedLicenseNumber ? <p className="ml-8 text-lg font-bold">Licensz szám:{data.cachedLicenseNumber}</p> : null}
			</div>
		);
	}

	return <></>;
}
export default PersonFormInfo;
