import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormDateTimeField from 'modules/ui/editform/fields/EditFormDateTimeField';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormAutoCompleteField from 'modules/ui/editform/fields/EditFormAutoCompleteField';
import UserPaymentEditService from '../service/UserPaymentEditService';

const config = () => {
	return {
		key: 'userPaymentEdit',
		text: 'Befizetések',
		textKey: 'userPayment',
		service: UserPaymentEditService,
		fields: [
			{
				key: 'user',
				text: 'Felhasználó',
				component: EditFormAutoCompleteField,
				required: true,
				query: (query, limit) => UserPaymentEditService.getUserSelection(query, limit)
			},
			{
				key: 'description',
				text: 'Megnevezés',
				textKey: 'description',
				component: EditFormTextField,
				required: true
			},
			{
				key: 'payableNet',
				text: 'Nettó fizetendő',
				textKey: 'payableNet',
				component: EditFormTextField,
				type: 'number',
				required: true
			},
			{
				key: 'payableGross',
				text: 'Bruttó fizetendő',
				textKey: 'payableGross',
				component: EditFormTextField,
				type: 'number',
				required: true
			},
			{
				key: 'payableVat',
				text: 'Áfa tartalom',
				textKey: 'payableVat',
				component: EditFormTextField,
				type: 'number',
				required: true
			},
			{
				key: 'billingDate',
				text: 'Számlázás dátuma',
				textKey: 'billingDate',
				component: EditFormDateTimeField,
				required: true
			},
			{
				key: 'paymentStatus',
				text: 'Státusz',
				textKey: 'paymentStatus',
				component: EditFormSelectField,
				type: 'enum',
				items: () => UserPaymentEditService.getPaymentStatusSelection()
			},
			{
				key: 'paymentDate',
				text: 'Befizetés dátuma',
				textKey: 'paymentDate',
				component: EditFormDateTimeField
			}
		]
	};
};

function UserPaymentEditForm() {
	const myConfig = config();
	return <EditFormPage config={myConfig} />;
}

export default UserPaymentEditForm;
