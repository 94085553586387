import OrgRegistrationNotification from 'app/admin/notification/OrgRegistrationNotification';
import NewCompetitionNotification from 'app/admin/notification/NewCompetitionNotification';
import OrgAthleteDoctorDateExpireNotification from 'app/admin/notification/OrgAthleteDoctorDateExpireNotification';
import PersonDoctorDateExpireNotification from 'app/admin/notification/PersonDoctorDateExpireNotification';
import AthleteTransferStartedNotificaion from 'app/admin/notification/AthleteTransferStartedNotification';
import AthleteTransferWaitForAthleteNotificaion from 'app/admin/notification/AthleteTransferWaitForAthleteNotification';
import AthleteTransferAthleteAcceptedNotification from 'app/admin/notification/AthleteTransferAthleteAcceptedNotification';
import AthleteTransferAthleteRejectedNotification from 'app/admin/notification/AthleteTransferAthleteRejectedNotification';
import AthleteTransferWaitForClubNotification from 'app/admin/notification/AthleteTransferWaitForClubNotification';
import AthleteTransferClubAcceptedNotification from 'app/admin/notification/AthleteTransferClubAcceptedNotification';
import AthleteTransferClubRejectedNotification from 'app/admin/notification/AthleteTransferClubRejectedNotification';
import AthleteTransferAwaitingPaymentNotification from 'app/admin/notification/AthleteTransferAwaitingPaymentNotification';
import AthleteTransferUnpaidNotification from 'app/admin/notification/AthleteTransferUnpaidNotification';
import AthleteTransferFinishedNotification from 'app/admin/notification/AthleteTransferFinshedNotification';
import AthleteTransferAwaitingPaymentTransferNotification from 'app/admin/notification/AthleteTransferAwaitingPaymentTransferNotification';
import AthleteTransferWaitForAdminNotification from 'app/admin/notification/AthleteTransferWaitForAdminNotification';
import AthleteTransferAdminAcceptedNotification from 'app/admin/notification/AthleteTransferAdminAcceptedNotification';
import AthleteTransferAdminRejectedNotification from 'app/admin/notification/AthleteTransferAdminRejectedNotification';
import PersonReRegistrationNotification from 'app/admin/notification/PersonReRegistrationNotification';

const NotificationRepo = {
	OrgRegistation: OrgRegistrationNotification,
	NewCompetition: NewCompetitionNotification,
	OrgAthleteDoctorDateExpire: OrgAthleteDoctorDateExpireNotification,
	PersonDoctorDateExpire: PersonDoctorDateExpireNotification,
	AthleteTransferStarted: AthleteTransferStartedNotificaion,
	AthleteTransferWaitForAthlete: AthleteTransferWaitForAthleteNotificaion,
	AthleteTransferAthleteAccepted: AthleteTransferAthleteAcceptedNotification,
	AthleteTransferAthleteRejected: AthleteTransferAthleteRejectedNotification,
	AthleteTransferWaitForClub: AthleteTransferWaitForClubNotification,
	AthleteTransferClubAccepted: AthleteTransferClubAcceptedNotification,
	AthleteTransferClubRejected: AthleteTransferClubRejectedNotification,
	AthleteTransferAwaitingPayment: AthleteTransferAwaitingPaymentNotification,
	AthleteTransferUnpaid: AthleteTransferUnpaidNotification,
	AthleteTransferFinshed: AthleteTransferFinishedNotification,
	AthleteTransferAwaitingPaymentTransfer: AthleteTransferAwaitingPaymentTransferNotification,
	AthleteTransferWaitForAdmin: AthleteTransferWaitForAdminNotification,
	AthleteTransferAdminAccepted: AthleteTransferAdminAcceptedNotification,
	AthleteTransferAdminRejected: AthleteTransferAdminRejectedNotification,
	PersonReRegistration: PersonReRegistrationNotification
};
export default NotificationRepo;
