import NamedDto from 'modules/base/dto/NamedDto';
import CompetitionEntryTableDto from './CompetitionEntryTableDto';
import CompetitionPartDetailsDto from './CompetitionPartDetailsDto';
import RaceDetailsDto from './RaceDetailsDto';

export class CompetitionCalendarDetailsDto extends NamedDto {
	constructor() {
		super();
		/** @type {Date} */
		this.date = null;
		/** @type {Date} */
		this.endDate = null;
		/** @type {string} */
		this.officialWebsite = null;
		/** @type {Date} */
		this.preRegDeadline1 = null;
		/** @type {Date} */
		this.preRegDeadline2 = null;
		/** @type {Date} */
		this.preRegDeadline3 = null;
		/** @type {Date} */
		this.regDeadline = null;
		/** @type {CompetitionPartDetailsDto[]} */
		this.competitionPartList = null;
		/** @type {RaceDetailsDto[]} */
		this.raceList = null;
		/** @type {string} */
		this.address = null;
	}

	/**
	 * @typedef {Object} _CompetitionCalendarDetailsDtoProps
	 * @property {Date} date
	 * @property {Date} endDate
	 * @property {string} officialWebsite
	 * @property {Date} preRegDeadline1
	 * @property {Date} preRegDeadline2
	 * @property {Date} preRegDeadline3
	 * @property {Date} regDeadline
	 * @property {string} address
	 * @property {CompetitionPartDetailsDto[]} competitionPartList
	 * @property {RaceDetailsDto[]} raceList
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _CompetitionCalendarDetailsDtoProps} CompetitionCalendarDetailsDtoProps
	 */
	/**
	 *
	 * @param {CompetitionCalendarDetailsDtoProps} props
	 * @returns {CompetitionCalendarDetailsDto}
	 */
	setup(props) {
		super.setup(props);
		this.date = props.date !== null ? new Date(props.date) : null;
		this.endDate = props.endDate !== null ? new Date(props.endDate) : null;
		this.officialWebsite = props.officialWebsite;
		this.preRegDeadline1 = props.preRegDeadline1 !== null ? new Date(props.preRegDeadline1) : null;
		this.preRegDeadline2 = props.preRegDeadline2 !== null ? new Date(props.preRegDeadline2) : null;
		this.preRegDeadline3 = props.preRegDeadline3 !== null ? new Date(props.preRegDeadline3) : null;
		this.regDeadline = props.regDeadline !== null ? new Date(props.regDeadline) : null;
		this.address = props.address;
		this.competitionPartList = props.competitionPartList != null ? props.competitionPartList.map(d => new CompetitionPartDetailsDto().setup(d)) : [];
		this.raceList = props.raceList != null ? props.raceList.map(d => new RaceDetailsDto().setup(d)) : [];
		return this;
	}
}

export default CompetitionCalendarDetailsDto;
