import { Link } from 'react-router-dom';

function OrgRegistrationNotification(props) {
	return (
		<div className="pt-4 pl-32">
			<Link to={`/${props.item.data.isOrganizer ? 'organizer' : 'club'}/edit/${props.item.data.userId}`}> Megnyit</Link>
		</div>
	);
}

export default OrgRegistrationNotification;
