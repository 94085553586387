import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class PersonAthleteTransferListDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.licenseType = null;
		/** @type {string} */
		this.licenseNumber = null;
	}

	/**
	 * @typedef {Object} PersonAthleteTransferListDto
	 * @property {string} licenseType
	 * @property {string} licenseNumber
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & PersonAthleteTransferListDto} PersonAthleteTransferListDtoProps
	 */
	/**
	 *
	 * @param {PersonAthleteTransferListDtoProps} props
	 * @returns {PersonAthleteTransferListDto}
	 */
	setup(props) {
		super.setup(props);
		this.licenseType = props.licenseType;
		this.licenseNumber = props.licenseNumber;
		return this;
	}
}
export default PersonAthleteTransferListDto;
