import { Button, Icon, Input, Paper, Tooltip } from '@material-ui/core';
import { useTheme, ThemeProvider, makeStyles, fade } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import UploadIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaFilePdf, FaTable } from "react-icons/fa";
// import { TbTableExport, TbFileTypePdf } from 'react-icons/tb';
// import { TbFileTypePdf } from "react-icons/tb";
import TableHeaderImportButton from './header/TableHeaderImportButton';

const useStyles = makeStyles(theme => ({
	tableBrowserHeader: {
		'& a[target=_blank]': {
			background: 'transparent',
			color: theme.palette.type === 'light' ? fade('#000', 0.54) : fade('#fff'),
			textDecoration: 'none',
			borderBottom: 0,
			'&:hover': {
				background: theme.palette.type === 'light' ? fade('#000', 0.04) : fade('#fff', 0.08),
				textDecoration: 'none'
			}
		}
	}
}));

function TableBrowserHeader(props) {
	const theme = useTheme();
	const classes = useStyles(props);
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const tableSessionInitialized = useSelector(rootState => rootState[props.config.key].tableBrowserEvent.tableSessionInitialized);
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const filterSelection = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterSelection);
	const [searchText, setSearchText] = useState('');
	const querySourceMode = filterSelection != null && filterSelection.enumMap != null && filterSelection.enumMap.querySource != null;
	const queryFieldName = querySourceMode ? 'query' : _.isUndefined(props.config.toolbarSearchFieldKey) ? 'name' : props.config.toolbarSearchFieldKey;
	const querySourceList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.querySourceList);
	const { t } = useTranslation(props.config.topic ? ['tableBrowser', props.config.topic] : 'tableBrowser');

	useEffect(() => {
		if (tableSessionInitialized) {
			const timeOutId = setTimeout(() => submitSearchText(searchText), 500);
			return () => clearTimeout(timeOutId);
		}
		return null;
	}, [searchText]);

	useEffect(() => {
		const index = filterList.findIndex(el => el.propertyName === queryFieldName);
		if (index > -1) {
			setSearchText(filterList[index].value);
		} else {
			setSearchText('');
		}
	}, [filterList]);

	useEffect(() => {
		if (searchText.length > 0) {
			submitSearchText(searchText);
		}
	}, [querySourceList]);

	const submitSearchText = text => {
		if (text.length > 0) {
			const newFilterList = filterList.filter(item => item.propertyName !== queryFieldName && item.propertyName !== 'querySource');
			newFilterList.push({ propertyName: queryFieldName, type: 'LIKE', value: text });
			const sourceFilter = [];
			if (querySourceMode) {
				sourceFilter.push({
					propertyName: 'querySource',
					type: 'IN',
					value: querySourceList.length > 0 ? querySourceList : filterSelection.enumMap.querySource.items.map(item => item.value)
				});
			}
			dispatch({
				type: `${props.config.key}/filter/setFilterList`,
				payload: [...newFilterList, ...sourceFilter]
			});
		} else {
			dispatch({ type: `${props.config.key}/filter/removeFilter`, payload: queryFieldName });
		}
	};

	return (
		<div className={clsx('flex flex-1 w-full items-center justify-between', classes.tableBrowserHeader)}>
			<div className="flex flex-col">
				{props.config.backLink ? (
					<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
						<Typography className="flex items-center" component={Link} onClick={() => dispatch({ type: `${props.config.key}/event/cleanTableBrowserEventSlice` })} to={() => props.config.backLink()} role="button" color="inherit">
							<Icon className="text-20">{theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}</Icon>
							<span className="hidden sm:flex mx-4 font-medium">{t('BACK')}</span>
						</Typography>
					</motion.div>
				) : null}
				<div className="flex">
					{!props.config.hideTitle ? (
						<div className="flex items-center">
							<Typography component={motion.span} initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.2 } }} delay={300} className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold">
								{!_.isUndefined(props.config.text) ? props.config.text : t(`${props.config.topic}:${props.config.key}`)}
							</Typography>
						</div>
					) : null}
					{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('filter') > -1 ? (
						<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
							<Tooltip title={t('FILTERS')}>
								<IconButton aria-label="filters" onClick={() => dispatch({ type: `${props.config.key}/event/showFiltersDialog` })}>
									<FilterListIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</motion.div>
					) : null}
				</div>
			</div>
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('search') > -1 ? (
				<div className="flex flex-1 items-center justify-center px-12">
					<ThemeProvider theme={mainTheme}>
						<Paper component={motion.div} initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }} className="flex items-center w-full max-w-512 px-8 py-4 rounded-16 shadow">
							<Icon color="action">search</Icon>

							<Input
								placeholder={t('SEARCH')}
								className="flex flex-1 mx-8"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchText(ev.target.value || '')}
							/>
							{querySourceMode ? (
								<Tooltip title="Mezőlista">
									<IconButton color="action" onClick={() => dispatch({ type: `${props.config.key}/event/showQuerySourceDialog` })}>
										<ListIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							) : null}
						</Paper>
					</ThemeProvider>
				</div>
			) : (
				<div className="flex flex-1" />
			)}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('filter') > -1 && filterList.length > 0 ? (
				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
					<Tooltip title={t('CLEAR_FILTERS')}>
						<IconButton aria-label="filtersClear" onClick={() => dispatch({ type: `${props.config.key}/filter/clearFilterList` })}>
							<RemoveCircleOutlineIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</motion.div>
			) : null}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('refresh') > -1 ? (
				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
					<Tooltip title={t('REFRESH')}>
						<IconButton aria-label="refresh" onClick={() => dispatch({ type: `${props.config.key}/event/setNeedDataReload` })}>
							<RefreshIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</motion.div>
			) : null}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('import') > -1 ? <TableHeaderImportButton config={props.config} /> : null}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('export') > -1 ? (
				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
					<Tooltip title={/*filterList.length === 0 ? 'A versenyre való szűrés szükséges' :*/ t('EXPORT')}>
						<IconButton aria-label="export" component="a" target={/*filterList.length === 0 ? "_self" :*/ "_blank"} /*disabled={filterList.length === 0}*/ href={/*filterList.length === 0 ? '#' :*/ props.config.exportList(sessionName)}>
							<FaTable fontSize="medium" />
						</IconButton>
					</Tooltip>
				</motion.div>
			) : null}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('exportPdf') > -1 ? (
				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
					<Tooltip title={t('EXPORT_TO_PDF')}>
						<IconButton aria-label="export" component="a" target="_blank" href={props.config.exportPdf(sessionName)}>
							<FaFilePdf fontSize="medium" />
						</IconButton>
					</Tooltip>
				</motion.div>
			) : null}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('new') > -1 ? (
				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
					<Button component={Link} to={() => props.config.editFormState(null)} className="whitespace-nowrap" variant="contained" color="secondary" startIcon={<Icon className="hidden sm:flex">add</Icon>}>
						<span className="hidden sm:flex">{props.config.newButtonText ? props.config.newButtonText : t('ADD_NEW')}</span>
						<span className="flex sm:hidden">{t('ADD_NEW_SHORT')}</span>
					</Button>
				</motion.div>
			) : null}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('newDir') > -1 ? (
				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
					<Tooltip title={t('NEW_FOLDER')}>
						<IconButton aria-label="create_new_folder" component={Link} to={() => props.config.editDirState(null)}>
							<CreateNewFolderIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</motion.div>
			) : null}
			{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('newFile') > -1 ? (
				<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
					<Tooltip title={t('NEW_FILE')}>
						<IconButton aria-label="upload_file" component={Link} to={() => props.config.editFormState(null)}>
							<UploadIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</motion.div>
			) : null}
			{props.config.toolbarActionList != null
				? props.config.toolbarActionList.map((btn, i) => (
					<motion.div key={i} initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
						{_.isUndefined(btn.component) ? (
							_.isUndefined(btn.action) ? (
								<Tooltip title={`${!_.isUndefined(btn.text) ? btn.text : t(`${props.config.topic}:${btn.key}`)}`}>
									<IconButton component={Link} to={() => btn.link(sessionName)}>
										<Icon fontSize="small">{btn.icon}</Icon>
									</IconButton>
								</Tooltip>
							) : (
								<Tooltip title={`${!_.isUndefined(btn.text) ? btn.text : t(`${props.config.topic}:${btn.key}`)}`}>
									<IconButton onClick={() => btn.action(sessionName)}>
										<Icon fontSize="small">{btn.icon}</Icon>
									</IconButton>
								</Tooltip>
							)
						) : (
							<btn.component />
						)}
					</motion.div>
				))
				: null}
		</div>
	);
}

export default TableBrowserHeader;
