import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';

class CompetitorService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'competitorControl';
	}

	/**
	 * @param {string} athleteRegType
	 * @param {number} competitionId
	 * @return {Promise<EntityValidationResult>}
	 */
	updateCompetitorToTSE(athleteRegType, competitionId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/updateCompetitorToTSE`, {
					athleteRegType,
					competitionId
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getCompetitionList() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getCompetitionList`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new CompetitorService();
