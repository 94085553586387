import NamedDto from 'modules/base/dto/NamedDto';

export class PersonStatusDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.userStatus = null;
		/** @type {boolean} */
		this.cachedCurrentLicensePaymentPaid = null;
		/** @type {boolean} */
		this.cachedDoctorValid = null;
	}

	/**
	 * @typedef {Object} _PersonStatusDtoProps
	 * @property {string} userStatus
	 * @property {boolean} cachedCurrentLicensePaymentPaid
	 * @property {boolean} cachedDoctorValid
	 * @typedef {import("../NamedDto").NamedDtoProps & _PersonStatusDtoProps} PersonStatusDtoProps
	 */
	/**
	 *
	 * @param {PersonStatusDtoProps} props
	 * @returns {PersonStatusDto}
	 */
	setup(props) {
		super.setup(props);
		this.userStatus = props.userStatus;
		this.cachedCurrentLicensePaymentPaid = props.cachedCurrentLicensePaymentPaid;
		this.cachedDoctorValid = props.cachedDoctorValid;
		return this;
	}
}

export default PersonStatusDto;
