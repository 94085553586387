import { UserPaymentTableService } from './UserPaymentTableService';

class BulkUserPaymentTableService extends UserPaymentTableService {
	constructor() {
		super();
		this.CONTROL = 'bulkUserPaymentTableControl';
	}
}

export default new BulkUserPaymentTableService();
