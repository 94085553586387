import _ from '@lodash';
import { Tab } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function EditFormTab(props) {
	const { formState } = useFormContext();
	const { config, tab } = props;
	const { t } = useTranslation(config.topic ? ['editform', config.topic] : 'editform');
	const labelText = !_.isUndefined(tab.text) ? tab.text : t(`${config.topic}:${tab.key}`);
	let hasError = false;
	if (tab.errorFields && formState.errors) {
		const errorFields = Object.keys(formState.errors);
		errorFields.forEach(errorField => {
			tab.errorFields.forEach(errorFieldCheck => {
				const re = new RegExp(errorFieldCheck);
				if (re.test(errorField)) {
					hasError = true;
				}
			});
		});
	}
	if (hasError) return <Tab {...props} className="h-64" label={<div className="text-red-500">{labelText}</div>} />;
	return <Tab {...props} className="h-64" label={labelText} />;
}

export default EditFormTab;
