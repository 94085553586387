import ClubDto from 'app/admin/organization/dto/ClubDto';
import NamedDto from 'modules/base/dto/NamedDto';
import PersonAthleteTransferListDto from './PersonAthleteTransferListDto';

export class PersonTransferListDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.cachedLicenseNumber = null;
		/** @type {boolean} */
		this.cachedCurrentLicensePaymentPaid = null;
		/** @type {ClubDto} */
		this.club = null;
		/** @type {boolean} */
		this.isAthlete = null;
		/** @type {string} */
		this.userStatus = null;
		/** @type {PersonAthleteTransferListDto} */
		this.athlete = null;
	}

	/**
	 * @typedef {Object} PersonTransferListDto
	 * @property {string} email
	 * @property {string} cachedLicenseNumber
	 * @property {boolean} cachedCurrentLicensePaymentPaid
	 * @property {ClubDto} club
	 * @property {boolean} isAthlete
	 * @property {string} userStatus
	 * @property {PersonAthleteTransferListDto} athlete
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & PersonTransferListDto} PersonTransferListDtoProps
	 */
	/**
	 *
	 * @param {PersonTransferListDtoProps} props
	 * @returns {PersonTransferListDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.cachedLicenseNumber = props.cachedLicenseNumber;
		this.cachedCurrentLicensePaymentPaid = props.cachedCurrentLicensePaymentPaid;
		this.club = props.club != null ? new ClubDto().setup(props.club) : null;
		this.isAthlete = props.isAthlete;
		this.userStatus = props.userStatus;
		this.athlete = props.athlete != null ? new PersonAthleteTransferListDto().setup(props.athlete) : null;
		return this;
	}
}
export default PersonTransferListDto;
