import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormDateTimeField from 'modules/ui/editform/fields/EditFormDateTimeField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import LicenseCostEditService from '../service/LicenseCostEditService';

const config = () => {
	return {
		key: 'licenseCostEdit',
		text: 'Díjak',
		textKey: 'licenseCost',
		service: LicenseCostEditService,
		fields: [
			{
				key: 'costType',
				text: 'Díj típusa',
				textKey: 'costType',
				component: EditFormSelectField,
				type: 'enum',
				items: () => LicenseCostEditService.getCostTypeSelection()
			},
			{
				key: 'name',
				text: 'Megnevezés',
				textKey: 'name',
				component: EditFormTextField,
				required: true
			},
			{
				key: 'feeNet',
				text: 'Nettó fizetendő',
				textKey: 'feeNet',
				component: EditFormTextField,
				type: 'number',
				required: true
			},
			{
				key: 'feeGross',
				text: 'Bruttó fizetendő',
				textKey: 'feeGross',
				component: EditFormTextField,
				type: 'number',
				required: true
			},
			{
				key: 'feeVat',
				text: 'Áfa tartalom',
				textKey: 'feeVat',
				component: EditFormTextField,
				type: 'number',
				required: true
			},
			{
				key: 'autoAllocationEnabled',
				text: 'Engedélyezve',
				textKey: 'autoAllocationEnabled',
				component: EditFormCheckboxField
			},
			{
				key: 'lastAllocationDate',
				text: 'Utolsó díj kiosztás',
				textKey: 'lastAllocationDate',
				component: EditFormDateTimeField,
				required: true
			},
			{
				key: 'nextAllocationDate',
				text: 'Következő kiosztás',
				textKey: 'nextAllocationDate',
				component: EditFormDateTimeField,
				required: true
			}
		]
	};
};

function LicenseCostEditForm() {
	const myConfig = config();
	return <EditFormPage config={myConfig} />;
}

export default LicenseCostEditForm;
