import nBossConnection from 'modules/base/service/nBossConnection';
import UserPaymentTableDto from '../dto/UserPaymentTableDto';

class PaymentService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'paymentControl';
	}

	/**
	 * @param {number} paymentId
	 * @return {Promise<string>}
	 */
	paymentStart(paymentId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/paymentStart`, { paymentId })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} paymentId
	 * @param {string} redirectUrl
	 * @return {Promise<string>}
	 */
	paymentStartWithUrl(paymentId, redirectUrl) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/paymentStart`, { paymentId, redirectUrl })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} paymentId
	 * @return {Promise<string>}
	 */
	findByBarionId(paymentId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/findByBarionId`, { paymentId })
				.then(response => resolve(new UserPaymentTableDto().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new PaymentService();
