import _ from '@lodash';
import { useDispatch } from 'react-redux';
import { Tooltip, TableCell, Switch } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import UserPaymentTableService from '../service/UserPaymentTableService';

function ChangePaymentTableButton(props) {
	const [disabled, setDisabled] = useState(false);
	const [paid, setPaid] = useState(null);
	const [showConfirm, setShowConfirm] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setDisabled(props.item.paymentStatus === null || props.item.paymentStatus === 'Deleted');
		setPaid(props.item.paymentStatus === 'Paid');
	}, [props.item]);

	const handleChangePayment = () => {
		setDisabled(true);
		setShowConfirm(false);
		UserPaymentTableService.updatePaymentPaid(props.item.id, !paid).then(resp => {
			if (resp.successful) {
				dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
			} else {
				dispatch(showMessage({ message: 'Hiba történt a státuszváltás során.' }));
			}
		});
	};
	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={paid ? 'Fizetve' : 'Kiegyenlítetlen'}>
				<Switch checked={paid} disabled={disabled} onChange={() => setShowConfirm(true)} inputProps={{ 'aria-label': 'secondary checkbox' }} />
			</Tooltip>
			<ConfirmDialog
				show={showConfirm}
				title="Fizetve"
				description={paid ? 'Biztosan fizetetlenre állítod a kiválaszott tételt?' : 'Biztosan fizetettre állítod a kiválaszott tételt?'}
				onYes={() => handleChangePayment()}
				onClose={() => setShowConfirm(false)}
			/>
		</TableCell>
	);
}

export default ChangePaymentTableButton;
