import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import GenderType from 'app/admin/common/dto/GenderType';
import LicenseType from 'app/admin/common/dto/LicenseType';
import { format, isAfter } from 'date-fns';
import huLocale from 'date-fns/locale/hu';

const formatDate = 'yyyy MMMM d.';

function CalendarDialogRaceEntryList(props) {
	const { raceAvailableAthleteList, raceEntryIdList, onChangeRaceEntryIdList } = props;

	const toggleEntry = personId => {
		if (raceEntryIdList.indexOf(personId) > -1) {
			onChangeRaceEntryIdList(raceEntryIdList.filter(d => d !== personId));
		} else {
			onChangeRaceEntryIdList([...raceEntryIdList, personId]);
		}
	};

	const toggleAll = () => {
		if (raceEntryIdList.length < raceAvailableAthleteList.length) {
			onChangeRaceEntryIdList(raceAvailableAthleteList.map(d => d.id));
		} else {
			onChangeRaceEntryIdList([]);
		}
	};

	return (
		<>
			<div className="flex flex-col divide-y-1">
				{raceAvailableAthleteList ? (
					<div className="flex flex-col py-4 ">
						<Table className="w-full" aria-label="simple table">
							<TableHead onClick={() => toggleAll()}>
								<TableRow className="font-bold">
									<TableCell padding="checkbox">
										<Checkbox indeterminate={raceEntryIdList.length > 0 && raceEntryIdList.length < raceAvailableAthleteList.length} checked={raceEntryIdList.length === raceAvailableAthleteList.length} />
									</TableCell>
									<TableCell>Név</TableCell>
									<TableCell>Nem</TableCell>
									<TableCell>Születési dátum</TableCell>
									<TableCell>Licensztípus</TableCell>
									<TableCell>Licenszszám</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{raceAvailableAthleteList.map((person, idx) => (
									<TableRow key={idx} onClick={() => toggleEntry(person.id)}>
										<TableCell padding="checkbox">
											<Checkbox checked={raceEntryIdList.indexOf(person.id) > -1} />
										</TableCell>
										<TableCell scope="row">{person.fullName}</TableCell>
										<TableCell scope="row">{GenderType[person.gender]}</TableCell>
										<TableCell scope="row">{format(person.birthDate, formatDate, { locale: huLocale })}</TableCell>
										<TableCell scope="row">{LicenseType[person.athlete.licenseType]}</TableCell>
										<TableCell scope="row">{person.athlete.licenseNumber}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				) : null}
			</div>
		</>
	);
}

export default CalendarDialogRaceEntryList;
