import { useFormContext } from 'react-hook-form';

function CompetitionDistanceNameField(props) {
	const methods = useFormContext();
	const { value, onChange } = props.field;
	const { formData } = props.fieldConfig;

	const content = methods.watch(props.fieldPrefix);
	const distance = content.distance != null ? formData.distances.find(d => d.id === content.distance) : null;
	const result = [];
	if (distance != null) {
		result.push(distance.name);
	}
	const resultStr = result.length > 0 ? result.join(' - ') : 'Új elem';
	if (value !== resultStr) onChange(resultStr);
	return <></>;
}

export default CompetitionDistanceNameField;
