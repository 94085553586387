import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';

const config = data => {
	return {
		key: data.formKey,
		text: data.title,
		service: data.service,
		fields: [
			{
				key: 'name',
				text: 'Név',
				textKey: 'name',
				component: EditFormTextField,
				required: true
			},
			{
				key: 'orderIndex',
				text: 'Sorrend',
				textKey: 'orderIndex',
				component: EditFormTextField,
				type: 'number'
			}
		]
	};
};

function OrderableValueSetEditForm(props) {
	const myConfig = config(props);
	return <EditFormPage config={myConfig} />;
}

export default OrderableValueSetEditForm;
