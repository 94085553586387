import { generateKey } from 'modules/base/utils/GenericUtils';
import EditFormSubComponent from 'modules/ui/editform/subForm/EditFormSubComponent';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import CompetitionPartNameField from './CompetitionPartNameField';

function CompetitionPartEditForm(data) {
	return {
		key: 'competitionPartList',
		text: 'Versenyek',
		textKey: 'competitionPartList',
		tab: 'competitionPart',
		component: EditFormSubComponent,
		fixedLengthList: data.isTechnicalCommittee,
		defaultValue: () => {
			return {
				id: null,
				key: generateKey(100),
				name: null,
				sportCategory: null,
				qualification: null,
				distance: null,
				isTeamCompetition: false
			};
		},
		fields: [
			{
				key: 'name',
				component: CompetitionPartNameField,
				formData: data.formData
			},
			{
				key: 'sportCategory',
				text: 'Szakág kiválasztása',
				textKey: 'sportCategory',
				component: EditFormSelectField,
				required: true,
				items: data.formData.sportCategories
			},
			{
				key: 'qualification',
				text: 'Verseny minősítésének kiválasztása',
				textKey: 'qualification',
				component: EditFormSelectField,
				required: true,
				items: data.formData.competitionPartQualifications
			},
			{
				key: 'distance',
				text: 'Táv kiválasztása',
				textKey: 'distance',
				component: EditFormSelectField,
				required: true,
				items: data.formData.distances
			},
			{
				key: 'isTeamCompetition',
				text: 'Csapatverseny',
				textKey: 'isTeamCompetition',
				component: EditFormCheckboxField
			}
		]
	};
}
export default CompetitionPartEditForm;
