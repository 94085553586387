import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { showMessage } from 'app/store/fuse/messageSlice';
import CompetitionEntryTableService from '../service/CompetitionEntryTableService';

function CompetitionEntryBulkPaymentButton(props) {
	const dispatch = useDispatch();
	const [showConfirm, setShowConfirm] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	const allSelected = useSelector(rootState => rootState[props.config.key].tableBrowserData.allSelected);
	const selected = useSelector(rootState => rootState[props.config.key].tableBrowserData.selected);

	useEffect(() => {
		setDisabled(!allSelected && selected.length === 0);
	}, [allSelected, selected]);
	const handlePayment = () => {
		setDisabled(true);
		setShowConfirm(false);
		CompetitionEntryTableService.bulkUpdatePaid(sessionName, props.bulkAction.paid).then(resp => {
			if (resp != null && resp.successful) {
				dispatch(showMessage({ message: `Módosítások rögzítve.` }));
				dispatch({ type: 'competitionEntryTable/event/setNeedDataReload' });
			} else {
				dispatch(showMessage({ message: `Nem történt változás a tételek között.` }));
			}
		});
	};

	return (
		<div>
			<Button
				className="whitespace-nowrap mr-12"
				variant="contained"
				color="secondary"
				onClick={() => setShowConfirm(true)}
				disabled={disabled}
				startIcon={<Icon className="hidden sm:flex">{props.bulkAction.paid ? 'payment' : 'shopping_cart'}</Icon>}
			>
				{props.bulkAction.paid ? 'Fizetve' : 'Fizetésre vár'}
			</Button>
			<ConfirmDialog
				show={showConfirm}
				title="Fizetés"
				description={props.bulkAction.paid ? 'Biztosan fizetettre állítod a kiválasztott tételeket?' : 'Biztosan fizetetlenre állítod a kiválasztott tételeket?'}
				onYes={() => handlePayment()}
				onClose={() => setShowConfirm(false)}
			/>
		</div>
	);
}

export default CompetitionEntryBulkPaymentButton;
