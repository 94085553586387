import { Link } from 'react-router-dom';

function AthleteTransferAwaitingPaymentNotification(props) {
	return (
		<div className="pt-4 pl-32">
			<Link to="/user-payment"> Megnyit</Link>
		</div>
	);
}

export default AthleteTransferAwaitingPaymentNotification;
