import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import CompetitionEntryTableDto from '../dto/CompetitionEntryTableDto';

class CompetitionEntryTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'competitionEntryTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<CompetitionEntryTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, CompetitionEntryTableDto)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {boolean} paid
	 * @return {Promise<EntityValidationResult>}
	 */
	updatePaid(id, paid) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/updatePaid`, { id, paid })
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {boolean} paid
	 * @return {Promise<EntityValidationResult>}
	 */
	bulkUpdatePaid(session, paid) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/bulkUpdatePaid`, { session, paid })
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}
}

export default new CompetitionEntryTableService();
