import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import AthleteLicenceStatus from 'app/admin/common/dto/AthleteLicenseStatus';
import LicenceType from 'app/admin/common/dto/LicenseType';

export class AthleteTableDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.licenseType = null;
		/** @type {string} */
		this.licenseNumber = null;
		/** @type {string} */
		this.chipNumber = null;
		/** @type {Date} */
		this.doctorDate = null;
		/** @type {string} */
		this.licenseStatus = null;
	}

	/**
	 * @typedef {Object} _AthleteTableDtoProps
	 * @property {string} licenseType
	 * @property {string} licenseNumber
	 * @property {string} chipNumber
	 * @property {Date} doctorDate
	 * @property {string} licenceStatus
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _AthleteTableDtoProps} AthleteTableDtoProps
	 */
	/**
	 *
	 * @param {AthleteTableDtoProps} props
	 * @returns {AthleteTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.licenseType = props.licenseType;
		this.licenseNumber = props.licenseNumber;
		this.chipNumber = props.chipNumber;
		this.doctorDate = props.doctorDate != null ? new Date(props.doctorDate) : null;
		this.licenseStatus = props.licenseStatus;
		return this;
	}

	/**
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getAsString(key) {
		if (key === 'licenseStatus') return this.licenseStatus != null ? AthleteLicenceStatus[this.licenseStatus] : null;
		if (key === 'licenceType') return this.licenceType != null ? LicenceType[this.licenceType] : null;
		return super.getAsString(key);
	}
}

export default AthleteTableDto;
