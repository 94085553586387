import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import AgeGroupEditService from '../service/AgeGroupEditService';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';

const config = {
	key: 'ageGroupEditForm',
	text: 'Korcsoport',
	textKey: 'ageGroup',
	service: AgeGroupEditService,
	fields: [
		{
			key: 'name',
			text: 'Név',
			textKey: 'name',
			component: EditFormTextField,
			required: true
		},
		{
			key: 'parent',
			text: 'Szülő',
			textKey: 'parent',
			component: EditFormSelectField,
			items: () => AgeGroupEditService.getParentListSelection()
		},
		{
			key: 'orderIndex',
			text: 'Sorrend',
			textKey: 'orderIndex',
			component: EditFormTextField,
			type: 'number'
		},
		{
			key: 'ageAgeGroup',
			component: EditFormGroupFields,
			className: blockClassName,
			fields: [
				{
					key: 'ageAgeGroupInner',
					component: EditFormGroupFields,
					className: 'w-full flex flex-col sm:flex-row',
					fields: [
						{
							key: 'child',
							text: 'Gyermek',
							textKey: 'child',
							component: EditFormCheckboxField
						},
						{
							key: 'ageMin',
							text: 'Nyitó életkor',
							textKey: 'orderIndex',
							component: EditFormTextField,
							type: 'number',
							className: 'sm:mr-6'
						},
						{
							key: 'ageMax',
							text: 'Záró életkor',
							textKey: 'ageMay',
							component: EditFormTextField,
							type: 'number',
							className: 'sm:ml-6'
						}
					]
				}
			]
		}
	]
};

function AgeGroupEditForm() {
	return <EditFormPage config={config} />;
}

export default AgeGroupEditForm;
