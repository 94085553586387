import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import AgeGroupService from 'app/admin/ageGroup/service/AgeGroupService';
import DistanceEditService from '../service/DistanceEditService';

const config = data => {
	return {
		key: 'distanceEditForm',
		text: 'Táv',
		service: DistanceEditService,
		backLink: () => '/distance',
		fields: [
			{
				key: 'name',
				text: 'Név',
				textKey: 'name',
				component: EditFormTextField,
				required: true
			},
			{
				key: 'orderIndex',
				text: 'Sorrend',
				textKey: 'orderIndex',
				component: EditFormTextField,
				type: 'number'
			},
			{
				key: 'ageGroupList',
				text: 'Korcsoportok',
				textKey: 'ageGroupList',
				component: EditFormSelectField,
				multiple: true,
				items: () => AgeGroupService.findAll()
			}
		]
	};
};

function DistanceEditForm(props) {
	const myConfig = config(props);
	return <EditFormPage config={myConfig} />;
}

export default DistanceEditForm;
