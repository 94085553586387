import NamedDto from 'modules/base/dto/NamedDto';
import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class ClubDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.org = null;
	}

	/**
	 * @typedef {Object} _ClubDtoProps
	 * @property {NamedDto} org
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _ClubDtoProps} ClubDtoProps
	 */
	/**
	 *
	 * @param {ClubDtoProps} props
	 * @returns {ClubDto}
	 */
	setup(props) {
		super.setup(props);
		this.org = props.org != null ? new NamedDto().setup(props.org) : null;
		return this;
	}
}

export default ClubDto;
