import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	filterList: [],
	filterSelection: null,
	querySourceList: []
};

const tableBrowserFilterSlice = key =>
	createSlice({
		name: `${key}/filter`,
		initialState,
		reducers: {
			addFilter: (state, action) => {
				if (state.filterList.findIndex(el => el.propertyName === action.payload.propertyName) > -1) {
					state.filterList = state.filterList.map(el => (el.propertyName === action.payload.propertyName ? action.payload : el));
				} else {
					state.filterList = [action.payload, ...state.filterList];
				}
			},
			removeFilter: (state, action) => {
				if (state.filterList.findIndex(el => el.propertyName === action.payload) > -1) {
					state.filterList = state.filterList.filter(el => el.propertyName !== action.payload);
				}
			},
			setFilterList: (state, action) => {
				state.filterList = action.payload;
			},
			clearFilterList: state => {
				state.filterList = [];
			},
			setFilterSelection: (state, action) => {
				state.filterSelection = action.payload;
			},
			setQuerySourceList: (state, action) => {
				state.querySourceList = action.payload;
			},
			cleanTableBrowserFilterSlice: state => {
				state = initialState;
			}
		}
	});

export default tableBrowserFilterSlice;
