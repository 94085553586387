import nBossConnection from 'modules/base/service/nBossConnection';
import _ from '@lodash';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import { useParams } from 'react-router';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import CompetitionEntryTableService from '../service/CompetitionEntryTableService';
import CompetitionService from '../service/CompetitionService';
import CompetitionEntryBulkPaymentButton from '../component/CompetitionEntryBulkPaymentButton';
import ChangePaymentTableButton from '../component/ChangePaymentTableButton';

const config = data => {
	return {
		key: 'competitionEntryTable',
		text: `${data.competition.name} nevezései`,
		textKey: 'competitionEntry',
		service: CompetitionEntryTableService,
		editFormState: item => `/competition-entry/edit/${data.competition.id}/${item ? item.id : 'new'}`,
		toolbarAction: ['search', 'refresh', 'export', 'exportPdf', 'new'],
		rowAction: ['delete'],
		backLink: () => '/competition',
		exportList: sessionName => `${nBossConnection.basePath}competitionEntryTableControl/exportXlsx?session=${sessionName}`,
		exportPdf: sessionName => `${nBossConnection.basePath}competitionEntryTableControl/exportPdf?session=${sessionName}`,
		bulkSelection: data.user.isSuperAdmin || data.user.data.id === data.competition.createdOrg.id,
		bulkActionList:
			data.user.isSuperAdmin || data.user.data.id === data.competition.createdOrg.id
				? [
						{
							key: 'paid',
							component: CompetitionEntryBulkPaymentButton,
							paid: true
						},
						{
							key: 'unpaid',
							component: CompetitionEntryBulkPaymentButton,
							paid: false
						}
				  ]
				: [],
		rowButtonList:
			data.user.isSuperAdmin || data.user.data.id === data.competition.createdOrg.id
				? [
						{
							key: 'changePayment',
							component: ChangePaymentTableButton
						}
				  ]
				: [],
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'competition',
						type: 'EQ',
						value: data.competition.id,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		columns: [
			{
				key: 'cachedPersonFullName',
				text: 'Név',
				textKey: 'cachedPersonFullName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'race.name',
				text: 'Futam',
				textKey: 'raceName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonBirthDate',
				text: 'Születési év',
				textKey: 'cachedPersonBirthDate',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonGender',
				text: 'Neme',
				textKey: 'cachedPersonGender',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'org.name',
				text: 'Egyesület',
				textKey: 'orgName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'teamName',
				text: 'Csapatnév',
				textKey: 'teamName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonLicenseNumber',
				text: 'Licensz szám',
				textKey: 'cachedPersonLicenseNumber',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonLicenseType',
				text: 'Licensz típus',
				textKey: 'cachedPersonLicenseType',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonAgeGroup.name',
				text: 'Korcsoport',
				textKey: 'cachedPersonAgeGroup',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonChipNumber',
				text: 'Chip szám',
				textKey: 'cachedPersonChipNumber',
				type: 'text',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

function CompetitionEntryTable() {
	const { competitionId } = useParams();
	const [competition, setCompetition] = useState(null);
	const user = useSelector(({ auth }) => auth.user);

	if (competition == null) {
		CompetitionService.getInfo(Number.parseInt(competitionId, 10)).then(resp => {
			setCompetition(resp);
		});
		return <></>;
	}
	console.log(user);
	const myConfig = config({ user, competition });

	return <TableBrowser config={myConfig} />;
}

export default withReducer('competitionEntryTable', reducer('competitionEntryTable'))(CompetitionEntryTable);
