import NamedDto from 'modules/base/dto/NamedDto';
import UserStatusType from 'app/admin/common/dto/UserStatusType';
import AthleteTableDto from './AthleteTableDto';
import CompetitorTableDto from './CompetitorTableDto';

export class PersonTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.fullName = null;
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.cachedAddress = null;
		/** @type {Date} */
		this.birthDate = null;
		/** @type {string} */
		this.gender = null;
		/** @type {AthleteTableDto} */
		this.athlete = null;
		/** @type {CompetitorTableDto} */
		this.competitor = null;
		/** @type {string} */
		this.userStatus = null;
		/** @type {string} */
		this.cachedClubShortName = null;
		/** @type {boolean} */
		this.cachedPaymentPaid = null;
		/** @type {boolean} */
		this.cachedLicensePaymentPaid = null;
		/** @type {boolean} */
		this.cachedCurrentLicensePaymentPaid = null;
		/** @type {string} */
		this.nsrSyncStatus = null;
		/** @type {Date} */
		this.nsrSyncDate = null;
		/** @type {string} */
		this.nsrErrorCode = null;
		/** @type {string} */
		this.nsrMessage = null;
	}

	/**
	 * @typedef {Object} PersonTableDto
	 * @property {string} fullName
	 * @property {string} email
	 * @property {string} cachedAddress
	 * @property {Date} birthDate
	 * @property {string} gender
	 * @property {AthleteTableDto} athlete
	 * @property {CompetitorTableDto} competitor
	 * @property {string} userStatus
	 * @property {string} cachedClubShortName
	 * @property {boolean} cachedPaymentPaid
	 * @property {boolean} cachedLicensePaymentPaid
	 * @property {boolean} cachedCurrentLicensePaymentPaid
	 * @property {string} nsrSyncStatus
	 * @property {Date} nsrSyncDate
	 * @property {string} nsrErrorCode
	 * @property {string} nsrMessage
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & PersonTableDto} PersonTableDtoProps
	 */
	/**
	 *
	 * @param {PersonTableDtoProps} props
	 * @returns {PersonTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.fullName = props.fullName;
		this.email = props.email;
		this.cachedAddress = props.cachedAddress;
		this.birthDate = props.birthDate != null ? new Date(props.birthDate) : null;
		this.gender = props.gender;
		this.athlete = props.athlete != null ? new AthleteTableDto().setup(props.athlete) : null;
		this.competitor = props.competitor != null ? new CompetitorTableDto().setup(props.competitor) : null;
		this.userStatus = props.userStatus;
		this.cachedClubShortName = props.cachedClubShortName;
		this.cachedPaymentPaid = props.cachedPaymentPaid;
		this.cachedLicensePaymentPaid = props.cachedLicensePaymentPaid;
		this.cachedCurrentLicensePaymentPaid = props.cachedCurrentLicensePaymentPaid;
		this.nsrSyncStatus = props.nsrSyncStatus;
		this.nsrSyncDate = props.nsrSyncDate !== null ? new Date(props.nsrSyncDate) : null;
		this.nsrErrorCode = props.nsrErrorCode;
		this.nsrMessage = props.nsrMessage;
		return this;
	}

	/**
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getAsString(key) {
		if (key === 'userStatus') {
			return this.userStatus != null ? UserStatusType[this.userStatus] : '';
		}
		if (key === 'cachedPaymentPaid' && this.cachedPaymentPaid !== null) {
			if (this.cachedPaymentPaid) return 'Befizetve';
			return 'Nem fizetve';
		}
		if (key === 'cachedLicensePaymentPaid' && this.cachedLicensePaymentPaid !== null) {
			if (this.cachedLicensePaymentPaid) return 'Befizetve';
			return 'Nem fizetve';
		}
		if (key === 'cachedCurrentLicensePaymentPaid' && this.cachedCurrentLicensePaymentPaid !== null) {
			if (this.cachedCurrentLicensePaymentPaid) return 'Befizetve';
			return 'Nem fizetve';
		}
		return super.getAsString(key);
	}
}
export default PersonTableDto;
