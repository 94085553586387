import * as yup from 'yup';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useHistory, useParams } from 'react-router';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import { Button, Icon, Typography } from '@material-ui/core';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useTranslation } from 'react-i18next';
import CompetitionEntryEditService from '../service/CompetitionEntryEditService';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';

export default function CompetitionEntryAddForm() {
	const dispatch = useDispatch();
	const routeParams = useParams();
	const history = useHistory();
	const { t } = useTranslation('editform');
	const [loading, setLoading] = useState(false);
	const [raceList, setRaceList] = useState([]);
	const [personMessage, setPersonMessage] = useState(null);
	const competitionId = Number.parseInt(routeParams.competitionId, 10);

	const schema = yup.object().shape({
		cachedPersonBirthDate: yup.date().nullable().required(t('validation:FIELD_REQUIRED')),
		cachedPersonLicenseNumber: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		race: yup.number().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const formMethods = useForm({
		defaultValues: { id: null, cachedPersonBirthDate: null, cachedPersonLicenseNumber: null, competition: competitionId, race: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const licenseNumber = useWatch({
		control: formMethods.control,
		name: 'cachedPersonLicenseNumber'
	});

	const birthDate = useWatch({
		control: formMethods.control,
		name: 'cachedPersonBirthDate'
	});

	const handleSearch = () => {
		setLoading(true);
		setPersonMessage(null);
		formMethods.setValue('race', null);
		CompetitionEntryEditService.searchAthleteRace(competitionId, licenseNumber, birthDate).then(resp => {
			if (resp == null || resp.length === 0) {
				setPersonMessage('Ismeretlen sportoló, vagy nem nevezhet semelyik futamra.');
			} else if (resp.length === 1) {
				formMethods.setValue('race', resp[0].id);
			}
			setRaceList(resp || []);
			setLoading(false);
		});
	};

	const handleSave = values => {
		setLoading(true);
		CompetitionEntryEditService.save(values)
			.then(resp => {
				if (resp.successful) {
					dispatch(showMessage({ message: 'Nevezés rögzítve.' }));
					history.push(`/competition-entry/${competitionId}`);
				} else {
					dispatch(showMessage({ message: 'Hiba történt a nevezés rögzítése során.' }));
				}
				setLoading(false);
			})
			.catch(e => {
				dispatch(showMessage({ message: 'Hiba történt a nevezés rögzítése során.' }));
				setLoading(false);
			});
	};

	return (
		<FormProvider {...formMethods}>
			<FusePageCarded
				classes={{
					toolbar: 'p-0',
					header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
				}}
				header={
					<div className="flex flex-1 w-full items-center justify-between">
						<div className="flex flex-col items-start max-w-full min-w-0">
							<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
								<Typography className="flex items-center sm:mb-12" component={Button} role="button" onClick={() => history.goBack(history)} color="inherit">
									<Icon className="text-20">arrow_back</Icon>
									<span className="hidden sm:flex mx-4 font-medium">Vissza</span>
								</Typography>
							</motion.div>
							<div className="flex items-center max-w-full">
								<div className="flex flex-col min-w-0 mx-8 sm:mc-16 gap-4">
									<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
										<Typography className="text-16 sm:text-20 truncate font-semibold">Új nevezés</Typography>
									</motion.div>
								</div>
							</div>
						</div>
						<motion.div className="flex" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
							<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={loading} onClick={() => formMethods.handleSubmit(handleSave)()} startIcon={<Icon className="hidden sm:flex">save</Icon>}>
								Mentés
							</Button>
						</motion.div>
					</div>
				}
				content={
					<div className="p-16 sm:p-24">
						<div className={`${blockClassName} mt-8 mb-16`}>
							<div className="pb-16 py-8">
								<div className="flex items-center">
									<Icon color="action">person</Icon>
									<Typography className="h2 mx-10 font-medium" color="textSecondary">
										Versenyző adatai
									</Typography>
								</div>
							</div>
							<div className="flex flex-row">
								<Controller
									control={formMethods.control}
									name="cachedPersonLicenseNumber"
									render={({ field, fieldState }) => (
										<EditFormTextField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'cachedPersonLicenseNumber',
												text: 'Licensz szám',
												className: 'mr-8'
											}}
										/>
									)}
								/>
								<Controller
									control={formMethods.control}
									name="cachedPersonBirthDate"
									render={({ field, fieldState }) => (
										<EditFormDateField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'cachedPersonBirthDate',
												text: 'Születési dátum',
												className: 'ml-8 mr-8'
											}}
										/>
									)}
								/>
								<div className="mt-8 mb-16 mx-4 flex content-center">
									<Button
										className="whitespace-nowrap"
										variant="contained"
										color="secondary"
										onClick={() => handleSearch()}
										disabled={licenseNumber == null || birthDate == null || loading}
										startIcon={<Icon className="hidden sm:flex">call_made</Icon>}
									>
										Ellenőrzés
									</Button>
								</div>
							</div>
						</div>
						{personMessage != null && <div className="text-red mx-16">{personMessage}</div>}
						{raceList.length > 0 && (
							<div className={`${blockClassName} mt-8 mb-16`}>
								<div className="pb-16 py-8">
									<div className="flex items-center">
										<Icon color="action">emoji_events</Icon>
										<Typography className="h2 mx-10 font-medium" color="textSecondary">
											Kiválasztott futam
										</Typography>
									</div>
								</div>
								<Controller
									control={formMethods.control}
									name="race"
									render={({ field, fieldState }) => (
										<EditFormSelectField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'race',
												text: 'Futam',
												required: true,
												items: raceList
											}}
										/>
									)}
								/>
							</div>
						)}
					</div>
				}
			/>
		</FormProvider>
	);
}
