import IdentifiableDto from './IdentifiableDto';

export class KeyedDto extends IdentifiableDto {
	/**
	 * @module KeyedDto
	 */
	constructor() {
		super();
		/** @type {string} */
		this.key = null;
	}

	/**
	 * @typedef {Object} _KeyedDtoProps
	 * @property {string} key
	 *
	 * @typedef {import("./IdentifiableDto").IdentifiableDtoProps & _KeyedDtoProps} KeyedDtoProps
	 */
	/**
	 *
	 * @param {KeyedDtoProps} props
	 * @returns {KeyedDto}
	 */
	setup(props) {
		super.setup(props);
		this.key = props.key;
		return this;
	}
}

export default KeyedDto;
