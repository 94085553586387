import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import OrganisationRegistrationForm from './OrganisationRegistrationForm';
import PersonRegistrationForm from './PersonRegistrationForm';
import RegistrationTypePage from './RegistrationTypePage';
import AthleteRegistrationForm from './AthleteRegistrationForm';

i18next.addResourceBundle('en', 'reg', en);
i18next.addResourceBundle('hu', 'reg', hu);

export default {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/registration/athlete-license',
			component: () => <AthleteRegistrationForm type="license" />
		},

		{
			path: '/registration/athlete',
			component: () => <AthleteRegistrationForm type="reg_hu" />
		},
		{
			path: '/registration/organization',
			component: OrganisationRegistrationForm
		},
		{
			path: '/registration/person',
			component: PersonRegistrationForm
		},
		{
			path: '/registration',
			component: RegistrationTypePage
		}
	]
};
