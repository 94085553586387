import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import PersonTableDto from '../dto/PersonTableDto';

class PersonTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'personTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<PersonTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, PersonTableDto)))
				.catch(reject);
		});
	}
}

export default new PersonTableService();
