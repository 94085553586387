import NamedDto from 'modules/base/dto/NamedDto';
import ClubRegistrationDto from './ClubRegistrationDto';
import ContactRegistrationDto from './ContactRegistrationDto';
import OrganizerRegistrationDto from './OrganizerRegistrationDto';
import AthleteRegistrationDto from './AthleteRegistrationDto';
import CompetitorRegistrationDto from './CompetitorRegistrationDto';
import RefereeRegistrationDto from './RefereeRegistrationDto';
import TrainerRegistrationDto from './TrainerRegistrationDto';

export class PersonRegistrationDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.password = null;
		/** @type {string} */
		this.passwordAgain = null;
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.firstName = null;
		/** @type {string} */
		this.lastName = null;
		/** @type {string} */
		this.birthPlace = null;
		/** @type {Date} */
		this.birthDate = null;
		/** @type {string} */
		this.motherFirstName = null;
		/** @type {string} */
		this.motherLastName = null;
		/** @type {string} */
		this.phoneNumber = null;
		/** @type {string} */
		this.gender = null;
		/** @type {string} */
		this.profilePicture = null;
		/** @type {string} */
		this.addressZipcode = null;
		/** @type {string} */
		this.addressCity = null;
		/** @type {number} */
		this.addressCounty = null;
		/** @type {string} */
		this.addressStreet = null;
		/** @type {number} */
		this.addressStreetType = null;
		/** @type {string} */
		this.addressNumber = null;
		/** @type {string} */
		this.addressFloor = null;
		/** @type {string} */
		this.addressDoor = null;
		/** @type {number} */
		this.nationality = null;
		/** @type {string} */
		this.nationalityOther = null;
		/** @type {boolean} */
		this.isAthlete = null;
		/** @type {AthleteRegistrationDto} */
		this.athlete = null;
		/** @type {boolean} */
		this.isCompetitor = null;
		/** @type {CompetitorRegistrationDto} */
		this.competitor = null;
		/** @type {boolean} */
		this.isReferee = null;
		/** @type {RefereeRegistrationDto} */
		this.referee = null;
		/** @type {boolean} */
		this.isTrainer = null;
		/** @type {TrainerRegistrationDto} */
		this.trainer = null;
		/** @type {string} */
		this.parentalPermission = null;
		/** @type {number} */
		this.club = null;
		/** @type {boolean} */
		this.accept = null;
		/** @type {string} */
		this.athleteRegType = null;
	}

	/**
	 * @typedef {Object} _PersonRegistrationDtoProps
	 * @property {string} password
	 * @property {string} passwordAgain
	 * @property {string} email
	 * @property {string} firstName
	 * @property {string} lastName
	 * @property {string} birthPlace
	 * @property {Date} birthDate
	 * @property {string} motherFirstName
	 * @property {string} motherLastName
	 * @property {string} phoneNumber
	 * @property {string} gender
	 * @property {string} profilePicture
	 * @property {string} addressZipcode
	 * @property {string} addressCity
	 * @property {number} addressCounty
	 * @property {string} addressStreet
	 * @property {number} addressStreetType
	 * @property {string} addressNumber
	 * @property {string} addressFloor
	 * @property {string} addressDoor
	 * @property {number} nationality
	 * @property {string} nationalityOther
	 * @property {boolean} isAthlete
	 * @property {AthleteRegistrationDto} athlete
	 * @property {boolean} isCompetitor
	 * @property {CompetitorRegistrationDto} competitor
	 * @property {boolean} isReferee
	 * @property {RefereeRegistrationDto} referee
	 * @property {boolean} isTrainer
	 * @property {TrainerRegistrationDto} trainer
	 * @property {string} parentalPermission
	 * @property {number} club
	 * @property {boolean} accept
	 * @property {string} athleteRegType
	 * @typedef {import("../NamedDto").NamedDtoProps & _PersonRegistrationDtoProps} PersonRegistrationDtoProps
	 */
	/**
	 *
	 * @param {PersonRegistrationDtoProps} props
	 * @returns {PersonRegistrationDto}
	 */
	setup(props) {
		super.setup(props);
		this.password = props.password;
		this.passwordAgain = props.passwordAgain;
		this.email = props.email;
		this.firstName = props.firstName;
		this.lastName = props.lastName;
		this.birthPlace = props.birthPlace;
		this.birthDate = props.birthDate ? new Date(props.birthDate) : null;
		this.motherFirstName = props.motherFirstName;
		this.motherLastName = props.motherLastName;
		this.phoneNumber = props.phoneNumber;
		this.gender = props.gender;
		this.profilePicture = props.profilePicture;
		this.addressZipcode = props.addressZipcode;
		this.addressCity = props.addressCity;
		this.addressCounty = props.addressCounty;
		this.addressStreet = props.addressStreet;
		this.addressStreetType = props.addressStreetType;
		this.addressNumber = props.addressNumber;
		this.addressFloor = props.addressFloor;
		this.addressDoor = props.addressDoor;
		this.nationality = props.nationality;
		this.nationalityOther = props.nationalityOther;
		this.isAthlete = props.isAthlete;
		this.athlete = props.athlete != null ? new AthleteRegistrationDto().setup(props.athlete) : null;
		this.isCompetitor = props.isCompetitor;
		this.competitor = props.competitor != null ? new CompetitorRegistrationDto().setup(props.competitor) : null;
		this.isReferee = props.isReferee;
		this.referee = props.referee != null ? new RefereeRegistrationDto().setup(props.referee) : null;
		this.isTrainer = props.isTrainer;
		this.trainer = props.trainer != null ? new TrainerRegistrationDto().setup(props.trainer) : null;
		this.parentalPermission = props.parentalPermission;
		this.club = props.club;
		this.accept = props.accept;
		this.athleteRegType = props.athleteRegType;
		return this;
	}
}

export default PersonRegistrationDto;
