import nBossConnection from 'modules/base/service/nBossConnection';
import { useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import PersonTableService from '../service/PersonTableService';

const config = data => {
	return {
		key: 'technicalCommitteeTable',
		text: 'Technikai bizottság',
		textKey: 'technicalCommittee',
		service: PersonTableService,
		icon: 'person',
		editFormState: item => `/technicalCommittee/edit/${item ? item.id : 'new'}`,
		exportList: sessionName => `${nBossConnection.basePath}personTableControl/exportXlsx?session=${sessionName}`,
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'isTechnicalCommittee',
						type: 'EQ',
						value: true,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		toolbarAction: ['search', 'refresh', 'export', 'new'],
		rowAction: data.isSuperAdmin ? ['modify', 'delete'] : ['modify'],
		columns: [
			{
				key: 'fullName',
				text: 'Név',
				textKey: 'fullName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'email',
				text: 'E-mail',
				textKey: 'email',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'birthDate',
				text: 'Születési dátum',
				textKey: 'birthDate',
				type: 'date',
				sortable: true,
				disablePadding: false
			}
		]
	};
};
function TechnicalCommitteeTable() {
	const user = useSelector(({ auth }) => auth.user);
	const myConfig = config({ isSuperAdmin: user.isSuperAdmin, accessType: user.accessType });
	return <TableBrowser config={myConfig} />;
}

export default withReducer('technicalCommitteeTable', reducer('technicalCommitteeTable'))(TechnicalCommitteeTable);
