import _ from '@lodash';
import FuseLoading from '@fuse/core/FuseLoading';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { showMessage } from 'app/store/fuse/messageSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import CompetitionEntryService from '../service/CompetitionEntryService';

const defaultValues = {
	teamName: null,
	members: []
};

function CalendarDialogClubValtoEntry(props) {
	const { onYes, onClose, entryInfo, qualificationSpecialType } = props;
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [deletable, setDeletable] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const dispatch = useDispatch();
	const [raceAvailableAthleteList, setRaceAvailableAthleteList] = useState(null);
	const memberMinLength = qualificationSpecialType === 'OB_MIX_VALTO' ? 4 : qualificationSpecialType === 'OB_VALTO' ? 3 : 2;
	const title = qualificationSpecialType === 'OB_MIX_VALTO' ? 'OB Mix váltóra' : qualificationSpecialType === 'OB_VALTO' ? 'OB váltóra' : 'váltóra';

	const schema = yup.object().shape({
		teamName: yup.string().nullable().required('Mező kötelező.'),
		members: yup
			.array()
			.of(
				yup.object().shape({
					person: yup.number().nullable().required('Mező kötelező.')
				})
			)
			.test({
				name: 'genderCheck',
				exclusive: true,
				params: null,
				message: 'OB Mix váltó esetén 2 női és 2 férfi versenyzőt kell megadni',
				test: value => {
					if (qualificationSpecialType === 'OB_MIX_VALTO' && _.isArray(value) && raceAvailableAthleteList != null) {
						const maleList = value.filter(d => d.person != null && raceAvailableAthleteList.find(dd => dd.id === d.person).gender === 'MALE');
						const femaleList = value.filter(d => d.person != null && raceAvailableAthleteList.find(dd => dd.id === d.person).gender === 'FEMALE');
						const maleCount = maleList.length;
						const femaleCount = femaleList.length;
						if (maleCount + femaleCount >= 4 && (maleList.length < 2 || femaleList.length < 2)) return false;
					}
					return true;
				}
			})
	});

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { fields, append, remove } = useFieldArray({
		control: methods.control,
		name: 'members',
		keyName: '_id'
	});

	const handleSave = dto => {
		setSubmitting(true);
		CompetitionEntryService.clubValtoEntry(
			entryInfo.competition,
			entryInfo.race,
			dto.teamName,
			dto.members.map(d => d.person)
		)
			.then(resp => {
				setSubmitting(false);
				if (resp) {
					dispatch(showMessage({ message: 'Sikeres rögzítés.' }));
					onClose();
				} else {
					dispatch(showMessage({ message: 'Hiba történt a nevezés rögzítése során' }));
				}
			})
			.catch(err => {
				setSubmitting(false);
				dispatch(showMessage({ message: 'Hiba történt a nevezés rögzítése során' }));
			});
		onClose();
	};

	const handleDelete = () => {
		CompetitionEntryService.clubValtoEntryDelete(entryInfo.competition, entryInfo.race).then(deleteResp => {
			if (deleteResp) {
				dispatch(showMessage({ message: 'Sikeres visszavonás.' }));
				onClose();
			} else {
				dispatch(showMessage({ message: 'Hiba történt a nevezés törlése során' }));
			}
		});
	};

	useEffect(() => {
		CompetitionEntryService.getRaceAvailableAthleteList(entryInfo.competition, entryInfo.race).then(_raceAvailableAthleteList => {
			setRaceAvailableAthleteList(_raceAvailableAthleteList);
			CompetitionEntryService.getClubValtoEntry(entryInfo.competition, entryInfo.race).then(currentEntry => {
				if (currentEntry.length > 0) {
					const dto = {
						teamName: currentEntry[0].teamName,
						members: currentEntry.map(d => {
							return { person: d.person };
						})
					};
					methods.reset(dto);
					setDeletable(true);
				} else {
					const dto = {
						teamName: null,
						members: Array(memberMinLength)
							.fill(null)
							.map((d, i) => {
								return { person: null };
							})
					};
					methods.reset(dto);
				}
				setLoading(false);
			});
		});
	}, []);

	const membersValue = useWatch({
		control: methods.control,
		name: 'members'
	});

	const alreadyAddedPersonIdList = membersValue.filter(d => d.person != null).map(d => d.person);

	return (
		<FormProvider {...methods}>
			<Dialog open fullWidth>
				<DialogTitle disableTypography className="border-b">
					{`nevezés ${title}`}
				</DialogTitle>
				<DialogContent className="p-20">
					{loading ? (
						<FuseLoading />
					) : (
						<>
							<Controller
								control={methods.control}
								name="teamName"
								render={({ field, fieldState }) => (
									<EditFormTextField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'entry' }}
										fieldConfig={{
											key: 'teamName',
											text: 'Csapatnév'
										}}
									/>
								)}
							/>
							{fields.map((_field, idx) => (
								<div className="flex flex-row" key={_field._id}>
									<Controller
										control={methods.control}
										name={`members.${idx}.person`}
										render={({ field, fieldState }) => (
											<EditFormSelectField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'entry' }}
												fieldConfig={{
													key: `members.${idx}.person`,
													text: `${idx + 1}. csapattag neve`,
													items: raceAvailableAthleteList
														.filter(d => alreadyAddedPersonIdList.indexOf(d.id) === -1 || (membersValue[idx].person != null && membersValue[idx].person === d.id))
														.map(d => {
															return { name: d.fullName, id: d.id };
														})
												}}
											/>
										)}
									/>
									{qualificationSpecialType === 'VALTO' && (
										<div className="mt-8 mb-16">
											<Tooltip title="Sor törlése">
												<span>
													<IconButton
														aria-label="delete"
														onClick={e => {
															remove(idx);
															e.preventDefault();
														}}
														disabled={idx < 2}
													>
														<DeleteIcon fontSize="small" />
													</IconButton>
												</span>
											</Tooltip>
										</div>
									)}
								</div>
							))}
							<Controller control={methods.control} name="members.root" render={({ field, fieldState }) => <ErrorMessage field={field} fieldState={fieldState} />} />
							{qualificationSpecialType === 'VALTO' && (
								<Button
									variant="contained"
									color="secondary"
									disabled={fields.length >= 8}
									onClick={() => {
										append({ person: null });
									}}
								>
									Új versenyző
								</Button>
							)}
						</>
					)}

					<ConfirmDialog
						show={showConfirm}
						title="Nevezés"
						description="Biztosan törlöd a nevezésed a versenyre?"
						onYes={() => {
							handleDelete();
						}}
						onClose={() => setShowConfirm(false)}
					/>
				</DialogContent>
				<DialogActions className="justify-between border-t p-20">
					<Button
						onClick={() => {
							onClose();
						}}
						disabled={submitting}
					>
						Mégse
					</Button>
					<Button onClick={methods.handleSubmit(handleSave)} disabled={loading || submitting}>
						Nevezés
					</Button>
					<Button onClick={() => setShowConfirm(true)} disabled={loading || submitting || !deletable}>
						Nevezés törlése
					</Button>
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
}

function ErrorMessage(props) {
	const { error } = props.fieldState;
	return (
		<div {...props.field} className={_.isUndefined(error) ? 'hidden' : 'text-red'}>
			{error?.message}
		</div>
	);
}
export default CalendarDialogClubValtoEntry;
