import NamedDto from 'modules/base/dto/NamedDto';

export class RefereeQueryDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.phoneNumber = null;
	}

	/**
	 * @typedef {Object} _RefereeQueryDtoProps
	 * @property {string} email
	 * @property {string} phoneNumber
	 * @typedef {import("../NamedDto").NamedDtoProps & _RefereeQueryDtoProps} RefereeQueryDtoProps
	 */
	/**
	 *
	 * @param {RefereeQueryDtoProps} props
	 * @returns {RefereeQueryDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.phoneNumber = props.phoneNumber;
		return this;
	}
}

export default RefereeQueryDto;
