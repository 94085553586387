import nBossConnection from 'modules/base/service/nBossConnection';
import { useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import UserStatusType from 'app/admin/common/dto/UserStatusType';
import PersonTableService from '../service/PersonTableService';

const config = data => {
	return {
		key: 'competitorTable',
		text: 'Egyéni versenyző',
		textKey: 'competitor',
		service: PersonTableService,
		icon: 'person',
		editFormState: item => `/competitor/edit/${item ? item.id : 'new'}`,
		exportList: sessionName => `${nBossConnection.basePath}personTableControl/exportXlsx?session=${sessionName}`,
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'isCompetitor',
						type: 'EQ',
						value: true,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		toolbarAction: ['filter', 'search', 'refresh', 'export', 'new'],
		filterFields: [
			{
				text: 'Státusz',
				propertyName: 'userStatus',
				propertyType: 'String',
				type: 'IN',
				items: Object.keys(UserStatusType).map(key => {
					return { label: UserStatusType[key], value: key };
				})
			},
			{
				text: 'Fizetve?',
				propertyName: 'cachedCurrentLicensePaymentPaid',
				propertyType: 'Boolean',
				type: 'EQ',
				trueLabel: 'Fizetve',
				falseLabel: 'Nem fizetve'
			},
			{
				text: 'Archivált',
				propertyName: 'archived',
				propertyType: 'Boolean',
				type: 'EQ'
			}
		],
		rowAction: data.isSuperAdmin ? ['modify', 'delete'] : ['modify'],
		columns: [
			{
				key: 'fullName',
				text: 'Név',
				textKey: 'fullName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'birthDate',
				text: 'Születési dátum',
				textKey: 'birthDate',
				type: 'date',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'competitor.licenseNumber',
				text: 'Licensz szám',
				textKey: 'competitor.licenseNumber',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'userStatus',
				text: 'Státusz',
				textKey: 'userStatus',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'competitor.doctorDate',
				text: 'Sportorvosi',
				textKey: 'doctorDate',
				type: 'date',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedCurrentLicensePaymentPaid',
				text: 'Fizetve?',
				textKey: 'cachedCurrentLicensePaymentPaid',
				type: 'text',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

function CompetitorTable() {
	const user = useSelector(({ auth }) => auth.user);
	const myConfig = config({ isSuperAdmin: user.isSuperAdmin, accessType: user.accessType });
	return <TableBrowser config={myConfig} />;
}

export default withReducer('competitorTable', reducer('competitorTable'))(CompetitorTable);
