import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import ContestEditService from '../service/ContestEditService';

const config = {
	key: 'contestEditForm',
	text: 'Kiírt esemény',
	service: ContestEditService,
	fields: [
		{
			key: 'name',
			text: 'Név',
			textKey: 'name',
			component: EditFormTextField,
			required: true
		}
	]
};

function ContestEditForm() {
	return <EditFormPage config={config} />;
}

export default ContestEditForm;
