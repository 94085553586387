import NamedDto from 'modules/base/dto/NamedDto';

export class CompetitionPartEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.key = null;
		/** @type {number} */
		this.sportCategory = null;
		/** @type {number} */
		this.qualification = null;
		/** @type {number} */
		this.distance = null;
		/** @type {boolean} */
		this.isTeamCompetition = null;
	}

	/**
	 * @typedef {Object} _CompetitionPartEditDtoProps
	 * @property {string} key
	 * @property {number} sportCategory
	 * @property {number} qualification
	 * @property {number} distance
	 * @property {boolean} isTeamCompetition
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _CompetitionPartEditDtoProps} CompetitionPartEditDtoProps
	 */
	/**
	 *
	 * @param {CompetitionPartEditDtoProps} props
	 * @returns {CompetitionPartEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.sportCategory = props.sportCategory;
		this.qualification = props.qualification;
		this.distance = props.distance;
		this.isTeamCompetition = props.isTeamCompetition != null ? props.isTeamCompetition : false;
		this.key = props.key;
		return this;
	}
}

export default CompetitionPartEditDto;
