import nBossConnection from 'modules/base/service/nBossConnection';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BulkUserPaymentTableService from '../service/BulkUserPaymentTableService';
import ChangePaymentTableButton from '../components/ChangePaymentTableButton';
import PaymentBulkActionButton from '../components/PaymentBulkActionButton';
import DownloadInvoiceTableButton from '../components/DownloadInvoiceTableButton';
import PaymentStatusType from '../dto/PaymentStatusType';

const config = data => {
	return {
		key: 'userPaymentTable',
		text: 'Csoportos befizetések',
		textKey: 'userPaymentTable',
		service: BulkUserPaymentTableService,
		editFormState: item => `/user-payment/edit/${item ? item.id : 'new'}`,
		toolbarAction: [...['filter', 'search', 'refresh', 'export'], ...(data.isSuperAdmin ? ['new'] : [])],
		rowAction: ['modify', 'delete'],
		bulkSelection: true,
		bulkIsCheckable: item => item.paymentStatus === 'Unpaid',
		bulkActionList: [
			{
				key: 'payment',
				component: PaymentBulkActionButton,
				service: BulkUserPaymentTableService
			}
		],
		rowButtonList: data.isSuperAdmin
			? [
					{
						key: 'changePayment',
						component: ChangePaymentTableButton
					},
					{
						key: 'downloadInvoice',
						component: DownloadInvoiceTableButton
					}
			  ]
			: [
					{
						key: 'downloadInvoice',
						component: DownloadInvoiceTableButton
					}
			  ],
		filterFields: [
			{
				text: 'Befizetés dátuma',
				propertyName: 'paymentDate',
				propertyType: 'Date',
				type: 'INTERVAL'
			},
			{
				text: 'Státusz',
				propertyName: 'paymentStatus',
				propertyType: 'String',
				type: 'IN',
				items: Object.keys(PaymentStatusType).map(k => {
					return { value: k, label: PaymentStatusType[k] };
				})
			}
		],
		exportList: sessionName => `${nBossConnection.basePath}userPaymentTableControl/exportXlsx?session=${sessionName}`,
		columns: [
			{
				key: 'billingDate',
				text: 'Számlázás dátuma',
				textKey: 'billingDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'user.name',
				text: 'Felhasználó',
				textKey: 'user.name',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'description',
				text: 'Megnevezés',
				textKey: 'description',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payableNet',
				text: 'Nettó fizetendő',
				textKey: 'payableNet',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payableGross',
				text: 'Bruttó fizetendő',
				textKey: 'payableGross',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'paymentStatus',
				text: 'Státusz',
				textKey: 'paymentStatus',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'paymentDate',
				text: 'Befizetés dátuma',
				textKey: 'paymentDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

function UserPaymentTable() {
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector(({ auth }) => auth.user);
	const myConfig = config({ dispatch, location, isSuperAdmin: user.isSuperAdmin, accessType: user.accessType });
	return <TableBrowser config={myConfig} />;
}

export default withReducer('userPaymentTable', reducer('userPaymentTable'))(UserPaymentTable);
