import nBossConnection from 'modules/base/service/nBossConnection';
import _ from '@lodash';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import CompetitionEntryClubTableService from '../service/CompetitionEntryClubTableService';
import TableAmountSumComponent from '../component/TableAmountSumComponent';

const config = data => {
	return {
		key: 'competitionEntryClubTable',
		text: `Nevezett versenyzők`,
		textKey: 'competitionEntry',
		service: CompetitionEntryClubTableService,
		editFormState: item => `/competition-entry-club/edit/${item ? item.id : 'new'}`,
		toolbarAction: ['filter', 'search', 'refresh', 'export'],

		rowAction: [],
		exportList: sessionName => `${nBossConnection.basePath}competitionEntryClubTableControl/exportXlsx?session=${sessionName}`,
		toolbarSearchFieldKey: 'cachedPersonFullName',
		filterFields: [
			{
				text: 'Esemény',
				propertyName: 'competition',
				propertyType: 'Long',
				type: 'EQ'
			}
		],
		bulkActionList: [
			{
				key: 'amountSum',
				component: TableAmountSumComponent
			}
		],
		columns: [
			{
				key: 'cachedPersonFullName',
				text: 'Név',
				textKey: 'cachedPersonFullName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'competition.name',
				text: 'Esemény',
				textKey: 'competitionName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'race.name',
				text: 'Futam',
				textKey: 'raceName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonBirthDate',
				text: 'Születési év',
				textKey: 'cachedPersonBirthDate',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonGender',
				text: 'Neme',
				textKey: 'cachedPersonGender',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'teamName',
				text: 'Csapatnév',
				textKey: 'teamName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonLicenseNumber',
				text: 'Licensz szám',
				textKey: 'cachedPersonLicenseNumber',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonLicenseType',
				text: 'Licensz típus',
				textKey: 'cachedPersonLicenseType',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonAgeGroup.name',
				text: 'Korcsoport',
				textKey: 'cachedPersonAgeGroup',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedPersonChipNumber',
				text: 'Chip szám',
				textKey: 'cachedPersonChipNumber',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'amount',
				text: 'Nevezési díj',
				textKey: 'amount',
				type: 'text',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

function CompetitionEntryClubTable() {
	const myConfig = config({});

	return <TableBrowser config={myConfig} />;
}

export default withReducer('competitionEntryClubTable', reducer('competitionEntryClubTable'))(CompetitionEntryClubTable);
