import _ from 'lodash';
import FuseLoading from '@fuse/core/FuseLoading';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import { format } from 'date-fns';
import CalendarHeader from './CalendarHeader';
import CompetitionService from '../service/CompetitionService';
import CalendarDetailsDialog from '../component/CalendarDetailsDialog';

const useStyles = makeStyles(theme => ({
	root: {
		'& a': {
			color: theme.palette.text.primary,
			textDecoration: 'normal!important'
		},
		'&  .fc-media-screen': {
			minHeight: '100%'
		},
		'& .fc-scrollgrid, & .fc-theme-standard td, & .fc-theme-standard th': {
			borderColor: `${theme.palette.divider}!important`
		},
		'&  .fc-scrollgrid-section > td': {
			border: 0
		},
		'& .fc-daygrid-day': {
			'&:last-child': {
				borderRight: 0
			}
		},
		'& .fc-col-header-cell': {
			borderWidth: '0 0 1px 0',
			padding: '16px 0',
			'& .fc-col-header-cell-cushion': {
				color: theme.palette.text.secondary,
				fontWeight: 500
			}
		},
		'& .fc-view ': {
			borderRadius: 20,
			overflow: 'hidden',
			border: `1px solid ${theme.palette.divider}`,
			'& > .fc-scrollgrid': {
				border: 0
			}
		},
		'& .fc-daygrid-day-number': {
			color: theme.palette.text.secondary,
			fontWeight: 500
		},
		'& .fc-event': {
			backgroundColor: `${theme.palette.primary.dark}!important`,
			color: `${theme.palette.primary.contrastText}!important`,
			border: 0,
			padding: '0 6px',
			borderRadius: '16px!important'
		}
	},
	addButton: {
		position: 'absolute',
		right: 12,
		top: 172,
		zIndex: 99
	}
}));

function CalendarPage(props) {
	const user = useSelector(({ auth }) => auth.user);
	const [currentDate, setCurrentDate] = useState();
	const [events, setEvents] = useState(null);
	const [dialogId, setDialogId] = useState(null /*12*/);
	const calendarRef = useRef();

	const classes = useStyles(props);

	useEffect(() => {
		getEvents();
	}, []);

	const getEvents = () => {
		CompetitionService.getCalendarList().then(resp => {
			setEvents(
				resp.map(d => {
					//const url = `#${generatePath('/calendar/view/:id', { id: d.id })}`;
					return { id: d.id, title: d.name, start: format(d.date, 'yyyy-MM-dd'), end: d.endDate ? format(new Date(d.endDate.getTime() + 24 * 60 * 60 * 1000), 'yyyy-MM-dd') : null };
					//return { id: d.id, title: d.name, start: d.date, end: d.endDate, allDay: false };
				})
			);
		});
	};

	const handleDates = rangeInfo => {
		const diff = rangeInfo.end.getTime() - rangeInfo.start.getTime();
		localStorage.setItem('calendarInitialDate', rangeInfo.start.getTime() + diff / 2);
		setCurrentDate(rangeInfo);
	};

	return events !== null ? (
		<div className={clsx(classes.root, 'flex flex-col flex-auto relative')}>
			<CalendarHeader calendarRef={calendarRef} currentDate={currentDate} />
			<div className="flex flex-1 p-24 container">
				<motion.div className="w-full" initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}>
					<FullCalendar
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						headerToolbar={false}
						initialView="dayGridMonth"
						initialDate={localStorage.getItem('calenadrInitialDate') ? Number.parseFloat(localStorage.getItem('calendarInitialDate')) : undefined}
						selectMirror
						dayMaxEvents
						weekends
						datesSet={handleDates}
						events={events}
						eventContent={renderEventContent}
						ref={calendarRef}
						locale={huLocale}
						firstDay={1}
						eventClick={e => setDialogId(e.event.id)}
					/>
				</motion.div>
				{_.isArray(user.role) && user.role.includes('NewCompetition') ? (
					<motion.div className={classes.addButton} initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.4 } }}>
						<Fab color="secondary" aria-label="add" component={Link} to="/competition/edit/new">
							<Icon>add</Icon>
						</Fab>
					</motion.div>
				) : null}
			</div>
			<CalendarDetailsDialog itemId={dialogId} onClose={() => setDialogId(null)} />
		</div>
	) : (
		<FuseLoading />
	);
}

function renderEventContent(eventInfo) {
	return (
		<div className="flex items-center cursor-pointer">
			<Typography className="text-white text-12 font-semibold">{eventInfo.timeText}</Typography>
			<Typography className="text-white text-12 px-4 truncate">{eventInfo.event.title}</Typography>
		</div>
	);
}

export default CalendarPage;
