import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { determineEditableAccess } from '../EditFormUtils';

function EditFormAutoCompleteField(props) {
	const { control, getValues } = useFormContext();
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [autocompleteValue, setAutocompleteValue] = useState(null);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const queryTimeout = useRef(null);
	const valueKey = props.fieldConfig && props.fieldConfig.type && props.fieldConfig.type === 'enum' ? 'value' : 'id';
	const labelKey = valueKey === 'value' ? 'label' : 'name';
	const queryCharacter = valueKey === 'id' ? '#' : '';

	const parseFilterKey = () => {
		if (props.fieldConfig.filterKey) {
			if (_.isArray(props.fieldConfig.filterKey)) {
				return props.fieldConfig.filterKey.map(f => (!f.startsWith('^') ? (props.fieldPrefix || '') + f : f.substring(1)));
			}
			return !props.fieldConfig.filterKey.startsWith('^') ? (props.fieldPrefix || '') + props.fieldConfig.filterKey : props.fieldConfig.filterKey.substring(1);
		}
		return null;
	};

	const filterValue = useWatch({
		control,
		name: parseFilterKey()
		//defaultValue: null TODO: gondot okoz ha benne van
	});

	const getQuery = () => {
		if (inputValue && inputValue === queryCharacter) {
			setOptions([]);
			setOpen(false);
		} else {
			setLoading(true);
			props.fieldConfig.query(inputValue || '', props.fieldConfig.limit || 100, props.fieldConfig.itemsParameterIsDto ? getValues() : filterValue).then(resp => {
				setLoading(false);
				if (resp && resp.length > 0) {
					setOptions(resp);
				} else {
					setOptions([]);
					setOpen(false);
				}
			});
		}
	};

	const updateByCurrentValue = () => {
		props.fieldConfig.query(`${queryCharacter}${value}`, 1, props.fieldConfig.itemsParameterIsDto ? getValues() : filterValue).then(resp => {
			if (resp && resp.length > 0 && resp[0][valueKey] === value) {
				setAutocompleteValue(resp[0]);
			} else {
				setAutocompleteValue(null);
				onChange(null);
			}
		});
	};

	useEffect(() => {
		if (open) {
			if (queryTimeout && queryTimeout.current) clearTimeout(queryTimeout.current);
			queryTimeout.current = setTimeout(() => {
				if (open) {
					getQuery();
				}
			}, 500);
		}
	}, [inputValue]);

	/*
	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);
    */

	const handleAutoCompleteOnChange = (e, v, r) => {
		if (r === 'select-option') {
			onChange(v[valueKey]);
			setAutocompleteValue(v);
			setOptions([v]);
		} else if (r === 'clear') {
			onChange(null);
			setAutocompleteValue(null);
		}
	};

	useEffect(() => {
		if (!open && value && autocompleteValue === null) {
			updateByCurrentValue();
		}
	}, [value]);

	useEffect(() => {
		if (value) updateByCurrentValue();
	}, [props.fieldConfig, filterValue]);

	return (
		<Autocomplete
			className={clsx('w-full mt-8 mb-16', props.fieldConfig.className)}
			open={open}
			onOpen={() => {
				if (!readOnly) {
					setOpen(true);
					if (!inputValue || inputValue.length === 0) getQuery();
				}
			}}
			onClose={() => {
				setOpen(false);
			}}
			inputValue={inputValue}
			onInputChange={(e, v) => setInputValue(v)}
			getOptionSelected={(option, val) => option[valueKey] === val[valueKey]}
			getOptionLabel={option => option[labelKey]}
			defaultValue={null}
			value={autocompleteValue}
			onChange={(e, v, r) => handleAutoCompleteOnChange(e, v, r)}
			options={options}
			loading={loading}
			filterOptions={opt => opt}
			noOptionsText="Nincs találat"
			renderInput={params => (
				<TextField
					{...params}
					variant="outlined"
					error={error}
					required={props.fieldConfig.required}
					helperText={error ? error.message : props.fieldConfig.helperText}
					label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
					id={props.field.name}
					fullWidth
					InputProps={{
						...params.InputProps,
						readOnly,
						endAdornment: readOnly ? (
							<InputAdornment position="end">
								<Lock fontSize="small" color="disabled" />
							</InputAdornment>
						) : (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
				/>
			)}
		/>
	);
}

export default EditFormAutoCompleteField;
