import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { showMessage } from 'app/store/fuse/messageSlice';

function PaymentBulkActionButton(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const [showConfirm, setShowConfirm] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	const allSelected = useSelector(rootState => rootState[props.config.key].tableBrowserData.allSelected);
	const selected = useSelector(rootState => rootState[props.config.key].tableBrowserData.selected);

	useEffect(() => {
		setDisabled(!allSelected && selected.length === 0);
	}, [allSelected, selected]);

	const handlePayment = () => {
		setDisabled(true);
		setShowConfirm(false);
		props.config.service.paymentStart(sessionName, `${process.env.REACT_APP_PUBLIC_URL}/?path=${location.pathname}`).then(resp => {
			if (resp !== null && resp.length > 0) {
				window.open(resp, '_self');
			} else {
				dispatch(showMessage({ message: `Nincs a kiválaszott elemek között fizetendő tétel.` }));
			}
		});
	};

	return (
		<div>
			<Button className="whitespace-nowrap mr-12" variant="contained" color="secondary" onClick={() => setShowConfirm(true)} disabled={disabled} startIcon={<Icon className="hidden sm:flex">shopping_cart</Icon>}>
				Fizetés
			</Button>
			<ConfirmDialog show={showConfirm} title="Fizetés" description="Biztosan befizeted a kiválasztott tételeket?" onYes={() => handlePayment()} onClose={() => setShowConfirm(false)} />
		</div>
	);
}

export default PaymentBulkActionButton;
