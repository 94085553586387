import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';
import NamedDto from 'modules/base/dto/NamedDto';
import AgeGroupEditDto from '../dto/AgrGroupEditDto';

class AgeGroupEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'ageGroupEditControl';
	}

	/**
	 * @return {Promise<AgeGroupEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new AgeGroupEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<AgeGroupEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new AgeGroupEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {AgeGroupEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {AgeGroupEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getRoleListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getRoleListSelection`)
				.then(response => resolve(response.data != null ? response.data.map(d => new NamedDto().setup(d)) : []))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<NamedDto>}
	 */
	getParentListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getParentListSelection`)
				.then(response => resolve(response.data != null ? response.data.map(d => new NamedDto().setup(d)) : null))
				.catch(reject);
		});
	}
}

export default new AgeGroupEditService();
