import { authRoles } from 'app/auth';
import EmailTemplateEditForm from './editform/EmailTemplateEditForm';
import EmailTemplateTable from './table/EmailTemplateTable';

export default [
	{
		path: '/email-template/edit/:id',
		component: EmailTemplateEditForm,
		auth: authRoles.emailTemplate
	},
	{
		path: '/email-template/:sessionNameParam?',
		component: EmailTemplateTable,
		auth: authRoles.emailTemplate
	}
];
