import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import detailsIcon from '@ckeditor/ckeditor5-core/theme/icons/three-vertical-dots.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import HtmlDataProcessor from '@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor';

export default class InsertDetails extends Plugin {
	init() {
		const { editor } = this;
		//const schema = editor.model.schema;
		/*schema.register('details', {
			allowIn: '$root'
		});

		schema.register('summary', {
			allowIn: 'details',
			allowChildren: '$text'
		});
        editor.model.change(writer => {
            const detailsElement = writer.createElement('details');
            writer.appendElement(
                'summary',
                {
                    text: 'Részletek elem címe'
                },
                detailsElement
            );
            writer.appendElement(
                'paragraph',
                {
                    text: 'Részletek elem leírása'
                },
                detailsElement
            );
            editor.model.insertContent(detailsElement, editor.model.document.selection);
        });*/
		editor.ui.componentFactory.add('insertDetails', locale => {
			const view = new ButtonView(locale);

			view.set({
				label: 'Részletek elem',
				icon: detailsIcon,
				tooltip: true
			});

			view.on('execute', () => {
				const { viewDocument } = editor.data;
				const htmlDP = new HtmlDataProcessor(viewDocument);
				const viewFragment = htmlDP.toView('<details><summary>Részletek elem címe</summary><p>Részletek elem tartalma</p></details>');

				const modelFragment = editor.data.toModel(viewFragment);

				editor.model.insertContent(modelFragment);
			});

			return view;
		});
	}
}
