import { useFormContext } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormSubComponent from 'modules/ui/editform/subForm/EditFormSubComponent';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormTimeField from 'modules/ui/editform/fields/EditFormTimeField';
import RaceNameField from './RaceNameField';
import EditFormAgeGroupField from '../component/EditFormAgeGroupSelect';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
const blockTitleClassName = 'py-8';

function RaceEditForm(data) {
	return {
		key: 'raceList',
		text: 'Futamok',
		textKey: 'raceList',
		tab: 'raceList',
		component: EditFormSubComponent,
		fixedLengthList: data.isTechnicalCommittee,
		defaultValue: {
			id: null,
			name: null,
			competitionPartKeyList: [],
			date: null,
			startTime: null,
			depotIn: null,
			depotOut: null,
			isMale: false,
			isFemale: false,
			ageGroupList: [],
			licenseTypeList: [],
			teamScoreCalcList: []
		},
		fields: [
			{
				key: 'name',
				component: RaceNameField,
				formData: data.formData
			},
			{
				key: 'competitionPartKeyList',
				text: 'Versenyek kiválasztása',
				textKey: 'competitionPartKeyList',
				component: CompetitionPartSelect,
				type: 'enum',
				multiple: true,
				required: true
			},
			{
				key: 'timeGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col sm:flex-row',
				fields: [
					{
						key: 'date',
						text: 'Dátum',
						textKey: 'date',
						className: 'sm:mr-6',
						component: EditFormDateField,
						required: true
					},
					{
						key: 'startTime',
						text: 'Kezdés',
						textKey: 'levelTimeByCycling',
						className: 'sm:ml-6 sm:mr-6',
						component: EditFormTimeField,
						required: true
					},
					{
						key: 'depotIn',
						text: 'Depo be',
						textKey: 'depotIn',
						className: 'sm:ml-6 sm:mr-6',
						component: EditFormTimeField,
						required: true
					},
					{
						key: 'depotOut',
						text: 'Depo ki',
						textKey: 'depotOut',
						className: 'sm:ml-6',
						component: EditFormTimeField,
						required: true
					}
				]
			},
			{
				key: 'otherGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col sm:flex-row',
				fields: [
					{
						key: 'isMale',
						text: 'Fiú/Férfi',
						textKey: 'isMale',
						className: 'sm:mr-6 mt-16 sm:w-1/5',
						component: EditFormCheckboxField
					},
					{
						key: 'isFemale',
						text: 'Lány/Nő',
						textKey: 'isFemale',
						className: 'sm:mr-6 mt-16 sm:w-1/5',
						component: EditFormCheckboxField
					},
					{
						key: 'ageGroupList',
						text: 'Korcsoport/Csapateredmény számítás',
						textKey: 'ageGroupList',
						className: 'sm:ml-6  sm:w-2/5',
						component: EditFormAgeGroupField,
						formData: data.formData,
						multiple: true,
						required: true
					},
					{
						key: 'licenseTypeList',
						text: 'Licensz típusok',
						textKey: 'licenseTypeList',
						className: 'sm:ml-6 sm:w-1/5',
						component: EditFormSelectField,
						type: 'enum',
						items: data.formData.licenseTypes,
						multiple: true,
						required: true
					}
				]
			}
		]
	};
}

export function CompetitionPartSelect(props) {
	const methods = useFormContext();

	const availableItems = [];
	const usedKeys = [];
	const formPath = props.fieldPrefix.split('.');
	const currentIndex = Number.parseInt(formPath[formPath.length - 2], 10);
	const competitionPartList = methods.watch('competitionPartList');
	const raceList = methods.watch('raceList');
	raceList.forEach((race, i) => {
		if (i !== currentIndex && race.competitionPartKeyList != null && race.competitionPartKeyList.length > 0) {
			race.competitionPartKeyList.forEach(pKey => {
				usedKeys.push(pKey);
			});
		}
	});
	competitionPartList.forEach(part => {
		if (part.name != null && usedKeys.indexOf(part.key) === -1) availableItems.push({ label: part.name, value: part.key });
	});
	return <EditFormSelectField {...props} fieldConfig={{ ...props.fieldConfig, items: availableItems }} />;
}

export default RaceEditForm;
