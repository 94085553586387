import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import PersonTransferListDto from './PersonTransferListDto';
import OrgTransferListDto from './OrgTransferListDto';
import AthleteTransferStatusType from './AthleteTransferStatusType';

export default class AthleteTransferTableDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {PersonTransferListDto} */
		this.athlete = null;
		/** @type {boolean} */
		this.isAdult = null;
		/** @type {OrgTransferListDto} */
		this.srcClub = null;
		/** @type {OrgTransferListDto} */
		this.targetClub = null;
		/** @type {string} */
		this.status = null;
		/** @type {boolean} */
		this.targetClubCheck1 = null;
		/** @type {boolean} */
		this.targetClubCheck2 = null;
		/** @type {boolean} */
		this.athleteCheck1 = null;
		/** @type {boolean} */
		this.athleteCheck2 = null;
		/** @type {boolean} */
		this.athleteAccepted = null;
		/** @type {string} */
		this.athleteDescription = null;
		/** @type {boolean} */
		this.srcClubCheck = null;
		/** @type {boolean} */
		this.srcClubAccepted = null;
		/** @type {string} */
		this.srcClubDescription = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
		/** @type {Date} */
		this.athleteAnswerTime = null;
		/** @type {Date} */
		this.srcClubAnswerTime = null;
		/** @type {Date} */
		this.paymentTime = null;
		/** @type {boolean} */
		this.closed = null;
		/** @type {string} */
		this.parentalPermission = null;
		/** @type {string} */
		this.adminDescription = null;
		/** @type {string} */
		this.paymentMode = null;
	}

	/**
	 * @typedef {Object} _AthleteTransferTableDtoProps
	 * @property {PersonTransferListDto} athlete
	 * @property {boolean} isAdult
	 * @property {OrgTransferListDto} srcClub
	 * @property {OrgTransferListDto} targetClub
	 * @property {string} status
	 * @property {boolean} targetClubCheck1
	 * @property {boolean} targetClubCheck2
	 * @property {boolean} athleteCheck1
	 * @property {boolean} athleteCheck2
	 * @property {boolean} athleteAccepted
	 * @property {string} athleteDescription
	 * @property {boolean} srcClubCheck1
	 * @property {boolean} srcClubAccepted
	 * @property {string} srcClubDescription
	 * @property {Date} created
	 * @property {Date} updated
	 * @property {Date} athleteAnswerTime
	 * @property {Date} srcClubAnswerTime
	 * @property {Date} paymentTime
	 * @property {boolean} closed
	 * @property {string} parentalPermission
	 * @property {string} adminDescription
	 * @property {string} paymentMode
	 *
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _AthleteTransferTableDtoProps} AthleteTransferTableDtoProps
	 */
	/**
	 *
	 * @param {AthleteTransferTableDtoProps} props
	 * @returns {AthleteTransferTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.athlete = props.athlete != null ? new PersonTransferListDto().setup(props.athlete) : null;
		this.isAdult = props.isAdult;
		this.srcClub = props.srcClub != null ? new OrgTransferListDto().setup(props.srcClub) : null;
		this.targetClub = props.targetClub != null ? new OrgTransferListDto().setup(props.targetClub) : null;
		this.status = props.status;
		this.targetClubCheck1 = props.targetClubCheck1;
		this.targetClubCheck2 = props.targetClubCheck2;
		this.athleteCheck1 = props.athleteCheck1;
		this.athleteCheck2 = props.athleteCheck2;
		this.athleteAccepted = props.athleteAccepted;
		this.athleteDescription = props.athleteDescription;
		this.srcClubCheck1 = props.srcClubCheck1;
		this.srcClubAccepted = props.srcClubAccepted;
		this.srcClubDescription = props.srcClubDescription;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		this.athleteAnswerTime = props.athleteAnswerTime != null ? new Date(props.athleteAnswerTime) : null;
		this.srcClubAnswerTime = props.srcClubAnswerTime != null ? new Date(props.srcClubAnswerTime) : null;
		this.paymentTime = props.paymentTime != null ? new Date(props.paymentTime) : null;
		this.closed = props.closed;
		this.parentalPermission = props.parentalPermission;
		this.adminDescription = props.adminDescription;
		this.paymentMode = props.paymentMode;
		return this;
	}

	getAsString(key) {
		if (key === 'status') {
			return this.status != null ? AthleteTransferStatusType[this.status] : '';
		}
		return super.getAsString(key);
	}
}
