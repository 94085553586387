import _ from '@lodash';
import nBossConnection from 'modules/base/service/nBossConnection';
import { useState } from 'react';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormAutoCompleteField from 'modules/ui/editform/fields/EditFormAutoCompleteField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormImageInputField from 'modules/ui/editform/fields/EditFormImageInputField';
import { add, sub, isAfter } from 'date-fns';
import RegistrationFormPage from './RegistrationFormPage';
import PersonRegistrationService from './service/PersonRegistrationService';
import CountryService from '../payment/service/CountryService';

const uncheckAnotherAthleteField = (field, methods) => {
	const { getValues, setValue } = methods;
	const values = getValues();
	if (field.name === 'isAthlete' && values.isCompetitor) {
		setValue('isCompetitor', false);
	} else if (field.name === 'isCompetitor' && values.isAthlete) {
		setValue('isAthlete', false);
	}
};

const isUnderAge = birthDate => {
	if (_.isDate(birthDate)) {
		const result = sub(new Date(), { years: 18 });
		if (isAfter(birthDate, result)) return true;
	}
	return false;
};

const config = data => {
	const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
	const blockTitleClassName = 'py-8';

	return {
		key: 'PersonRegistrationForm',
		text: 'Személyi regisztráció',
		textKey: 'PersonRegistrationForm',
		service: PersonRegistrationService,
		fields: [
			{
				key: 'typeGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col md:flex-row rounded-8 border px-16 pt-10 pb-4 mb-16',
				fields: [
					/*{
						key: 'isAthlete',
						text: 'Egyesületi versenyző vagyok',
						textKey: 'isAthlete',
						component: EditFormCheckboxField,
						onChange: (value, field, methods) => {
							if (value) uncheckAnotherAthleteField(field, methods);
						}
					},*/
					{
						key: 'isReferee',
						text: 'Versenybíró vagyok',
						textKey: 'isReferee',
						component: EditFormCheckboxField
					},
					{
						key: 'isTrainer',
						text: 'Edző vagyok',
						textKey: 'isTrainer',
						component: EditFormCheckboxField
					}
					/*					{
						key: 'isCompetitor',
						text: 'Egyéni versenyző vagyok',
						textKey: 'isCompetitor',
						component: EditFormCheckboxField,
						onChange: (value, field, methods) => {
							if (value) uncheckAnotherAthleteField(field, methods);
						}
					}*/
				]
			},
			{
				key: 'club',
				text: 'Klub',
				textKey: 'club',
				component: EditFormSelectField,
				items: data.formData.clubs,
				required: true,
				dependsOn: ['isCompetitor', 'isAthlete', 'isReferee', 'isTrainer'],
				visibleIf: watchers => !watchers.isCompetitor && !(!watchers.isAthlete && watchers.isReferee && !watchers.isTrainer)
			},
			{
				key: 'club',
				text: 'Klub',
				textKey: 'club',
				component: EditFormSelectField,
				items: data.formData.clubs,
				dependsOn: ['isCompetitor', 'isAthlete', 'isReferee', 'isTrainer'],
				visibleIf: watchers => !watchers.isAthlete && watchers.isReferee && !watchers.isTrainer && !watchers.isCompetitor
			},
			{
				key: 'nameGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col sm:flex-row',
				fields: [
					{
						key: 'lastName',
						text: 'Vezetéknév',
						textKey: 'lastName',
						className: 'sm:mr-6',
						component: EditFormTextField,
						maxLength: 50,
						required: true,
						autoCapitalize: 'capitalize'
					},
					{
						key: 'firstName',
						text: 'Keresztnév',
						textKey: 'firstName',
						className: 'sm:ml-6',
						component: EditFormTextField,
						maxLength: 50,
						required: true,
						autoCapitalize: 'capitalize'
					}
				]
			},
			{
				key: 'birthGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col sm:flex-row',
				fields: [
					{
						key: 'birthPlace',
						text: 'Születési hely',
						textKey: 'birthPlace',
						className: 'sm:mr-6',
						component: EditFormTextField,
						maxLength: 40,
						required: true
					},
					{
						key: 'birthDate',
						text: 'Születési idő',
						textKey: 'birthDate',
						className: 'sm:ml-6',
						component: EditFormDateField,
						maxDate: new Date(),
						openTo: 'year',
						required: true
					}
				]
			},
			{
				key: 'parentalPermission',
				text: 'Szülői engedély',
				textKey: 'parentalPermission',
				component: EditFormFileInputField,
				required: false,
				dependsOn: 'birthDate',
				visibleIf: birthDate => isUnderAge(birthDate)
			},
			{
				key: 'gender',
				text: 'Nem',
				textKey: 'gender',
				component: EditFormSelectField,
				items: data.formData.genders,
				type: 'enum',
				required: true
			},
			{
				key: 'email',
				text: 'E-mail cím',
				textKey: 'email',
				component: EditFormTextField,
				maxLength: 100,
				required: true
			},
			{
				key: 'phoneNumber',
				text: 'Telefonszám',
				textKey: 'phoneNumber',
				mask: {
					mask: '+0000000000000'
				},
				component: EditFormTextField,
				maxLength: 50,
				required: true
			},
			{
				key: 'nationality',
				text: 'Állampolgárság',
				textKey: 'nationality',
				component: EditFormSelectField,
				items: data.formData.nationalities,
				required: true
			},
			{
				key: 'nationalityOther',
				text: 'Állampolgárság egyéb',
				textKey: 'nationalityOther',
				component: EditFormTextField,
				maxLength: 200,
				required: false
			},
			{
				key: 'fullMotherNameGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepMothersName',
						text: 'Anyja neve',
						textKey: 'sepMothersName',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						icon: 'pregnant_woman',
						isControlledInput: false
					},
					{
						key: 'motherNameGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col sm:flex-row',
						fields: [
							{
								key: 'motherLastName',
								text: 'Vezetéknév',
								textKey: 'motherLastName',
								className: 'sm:mr-6',
								component: EditFormTextField,
								maxLength: 50,
								required: true,
								autoCapitalize: 'capitalize'
							},
							{
								key: 'motherFirstName',
								text: 'Keresztnév',
								textKey: 'motherFirstName',
								className: 'sm:ml-6',
								component: EditFormTextField,
								maxLength: 50,
								required: true,
								autoCapitalize: 'capitalize'
							}
						]
					}
				]
			},
			{
				key: 'fullPasswordGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepMothersName',
						text: 'Jelszó',
						textKey: 'sepMothersName',
						component: EditFormSeparatorField,
						icon: 'lock',
						className: blockTitleClassName,
						isControlledInput: false
					},
					{
						key: 'passwordGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col sm:flex-row',
						fields: [
							{
								key: 'password',
								text: 'Jelszó',
								textKey: 'password',
								className: 'sm:mr-6',
								component: EditFormTextField,
								required: true,
								type: 'password',
								autoComplete: 'new-password',
								maxLength: 50
							},
							{
								key: 'passwordAgain',
								text: 'Jelszó mégegyszer',
								textKey: 'passwordAgain',
								className: 'sm:ml-6',
								component: EditFormTextField,
								required: true,
								type: 'password',
								autoComplete: 'new-password',
								maxLength: 50
							}
						]
					}
				]
			},
			{
				key: 'fullAddressGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepAddress',
						text: 'Cím',
						textKey: 'sepAddress',
						component: EditFormSeparatorField,
						icon: 'place',
						className: blockTitleClassName,
						isControlledInput: false
					},
					{
						key: 'addressGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col md:flex-row',
						fields: [
							{
								key: 'addressZipcode',
								text: 'Irányítószám',
								textKey: 'addressZipcode',
								component: EditFormTextField,
								maxLength: 16,
								className: 'w-full md:w-1/5 md:mr-12',
								required: true
							},
							{
								key: 'addressCity',
								text: 'Város',
								textKey: 'addressCity',
								component: EditFormTextField,
								maxLength: 40,
								className: 'w-full md:w-2/5 md:mr-12',
								required: true
							},
							{
								key: 'addressCounty',
								text: 'Megye',
								textKey: 'addressCounty',
								component: EditFormSelectField,
								items: data.formData.counties,
								className: 'w-full md:w-2/5',
								required: true
							}
						]
					},
					{
						key: 'addressStreetGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col md:flex-row',
						fields: [
							{
								key: 'addressStreet',
								text: 'Utca',
								textKey: 'addressStreet',
								component: EditFormTextField,
								maxLength: 100,
								className: 'w-full md:w-4/5 md:mr-12',
								required: true
							},
							{
								key: 'addressStreetType',
								text: 'Típus',
								textKey: 'addressStreetType',
								component: EditFormSelectField,
								items: data.formData.publicPlaceTypes,
								className: 'w-full md:w-1/5',
								required: true
							}
						]
					},
					{
						key: 'addressNumberGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-row',
						fields: [
							{
								key: 'addressNumber',
								text: 'Házszám',
								textKey: 'addressNumber',
								component: EditFormTextField,
								maxLength: 50,
								className: 'w-1/3 mr-12',
								required: true
							},
							{
								key: 'addressFloor',
								text: 'Emelet',
								textKey: 'addressFloor',
								component: EditFormTextField,
								maxLength: 10,
								className: 'w-1/3 mr-12'
							},
							{
								key: 'addressDoor',
								text: 'Ajtó',
								textKey: 'addressDoor',
								component: EditFormTextField,
								maxLength: 10,
								className: 'w-1/3'
							}
						]
					}
				]
			},
			{
				key: 'profilePicture',
				text: 'Profilkép',
				textKey: 'profilePicture',
				component: EditFormImageInputField,
				imageUrl: file => `${nBossConnection.baseUrl}fileRepository/getFile?key=${file.key}`,
				required: true
			},
			{
				key: 'fullAthleteGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				dependsOn: 'isAthlete',
				visibleIf: isAthlete => isAthlete,
				fields: [
					{
						key: 'sepAthleteGroup',
						text: 'Egyesületi versenyző adatok',
						textKey: 'sepAthleteGroup',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						icon: 'person',
						isControlledInput: false
					},
					{
						key: 'athleteGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col',
						fields: [
							{
								key: 'athlete.taj',
								text: 'TAJ szám',
								textKey: 'athlete.taj',
								component: EditFormTextField,
								maxLength: 11,
								mask: {
									mask: '000 000 000'
								},
								required: true
							},
							{
								key: 'athlete.idCardNumber',
								text: 'Személyi igazolvány szám',
								textKey: 'athlete.idCardNumber',
								component: EditFormTextField,
								maxLength: 50,
								required: false
							},
							{
								key: 'athlete.passportNumber',
								text: 'Útlevélszám',
								textKey: 'athlete.passportNumber',
								component: EditFormTextField,
								maxLength: 50,
								required: false
							},
							{
								key: 'athleteIdGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-col sm:flex-row',
								fields: [
									{
										key: 'athlete.licenseType',
										text: 'Licensz típus',
										textKey: 'athlete.licenseType',
										className: 'mr-0 sm:mr-12',
										component: EditFormSelectField,
										items: data.formData.licenseTypes,
										type: 'enum',
										required: true
									},
									{
										key: 'athlete.chipNumber',
										text: 'Chip szám',
										textKey: 'athlete.chipNumber',
										component: EditFormTextField,
										maxLength: 100,
										required: false
									}
								]
							},
							{
								key: 'athlete.doctorDate',
								text: 'Sportorvosi lejárati dátum',
								textKey: 'athlete.doctorDate',
								component: EditFormDateField,
								required: true,
								dependsOn: 'birthDate',
								visibleIf: birthDate => isUnderAge(birthDate),
								minDate: new Date(),
								maxDate: add(new Date(), { months: 6 })
							},
							{
								key: 'athlete.doctorDate',
								text: 'Sportorvosi lejárati dátum',
								textKey: 'athlete.doctorDate',
								component: EditFormDateField,
								required: true,
								dependsOn: 'birthDate',
								visibleIf: birthDate => !isUnderAge(birthDate),
								minDate: new Date(),
								maxDate: add(new Date(), { months: 12 })
							},
							{
								key: 'athlete.doctorDocument',
								text: 'Sportorvosi dokumentum',
								textKey: 'athlete.doctorDocument',
								component: EditFormFileInputField,
								required: false
							}
						]
					}
				]
			},
			{
				key: 'fullTrainerGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				dependsOn: 'isTrainer',
				visibleIf: isTrainer => isTrainer,
				fields: [
					{
						key: 'sepTrainerGroup',
						text: 'Edző adatok',
						textKey: 'sepTrainerGroup',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						icon: 'person',
						isControlledInput: false
					},
					{
						key: 'trainerGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col',
						fields: [
							{
								key: 'trainerQualificationsGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-col sm:flex-row',
								fields: [
									{
										key: 'trainer.qualification',
										text: 'Végzettség',
										textKey: 'trainer.qualification',
										className: 'sm:mr-6',
										component: EditFormSelectField,
										items: data.formData.qualifications,
										required: false
									},
									{
										key: 'trainer.qualificationOther',
										text: 'Végzettség egyéb',
										textKey: 'trainer.qualificationOther',
										className: 'sm:ml-6',
										component: EditFormTextField,
										maxLength: 200,
										required: false
									}
								]
							},
							{
								key: 'trainer.qualificationSport',
								text: 'Sportág',
								textKey: 'trainer.qualificationSport',
								component: EditFormTextField,
								maxLength: 100,
								required: true,
								dependsOn: 'trainer.qualification',
								visibleIf: trainerQualification => trainerQualification && data.formData.qualifications.find(item => item.id === trainerQualification).sportRequired
							},
							{
								key: 'trainer.certificate',
								text: 'Végzettséget igazoló dokumentum',
								textKey: 'trainer.certificate',
								component: EditFormFileInputField,
								required: true
							},
							{
								key: 'trainer.acceptSelfRecommendation',
								text: 'Hozzájárulok, hogy a Magyar Triatlon Szövetség, a honlapján edzői tevékenységemet kiajánlja és elérhetőségeimet megadja.',
								textKey: 'trainer.acceptSelfRecommendation',
								component: EditFormCheckboxField
							}
						]
					}
				]
			},
			{
				key: 'fullRefereeGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				dependsOn: 'isReferee',
				visibleIf: isReferee => isReferee,
				fields: [
					{
						key: 'sepRefereeGroup',
						text: 'Versenybíró adatok',
						textKey: 'sepRefereeGroup',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						icon: 'person',
						isControlledInput: false
					},
					{
						key: 'refereeGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col',
						fields: [
							{
								key: 'referee.shirtSize',
								text: 'Póló méret',
								textKey: 'referee.shirtSize',
								component: EditFormSelectField,
								items: data.formData.shirtSizes,
								type: 'enum',
								required: true
							},
							{
								key: 'refereeCarGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-col sm:flex-row',
								fields: [
									{
										key: 'referee.hasDrivingLicense',
										text: 'Rendelkezik-e jogosítvánnyal?',
										textKey: 'referee.hasDrivingLicense',
										className: 'sm:mr-6',
										component: EditFormSelectField,
										items: data.booleanList,
										required: true
									},
									{
										key: 'referee.hasCar',
										text: 'Rendelkezik-e saját autóval?',
										textKey: 'referee.hasCar',
										className: 'sm:ml-6',
										component: EditFormSelectField,
										items: data.booleanList,
										required: true
									}
								]
							},
							{
								key: 'referee.languages',
								text: 'Nyelvtudás',
								textKey: 'referee.languages',
								component: EditFormTextField,
								maxLength: 200,
								required: true
							},
							/*{
								key: 'referee.licenseExpirationDate',
								text: 'Engedély lejárati dátum',
								textKey: 'referee.licenseExpirationDate',
								component: EditFormDateField,
								required: true
							},*/
							{
								key: 'refereeQualificationsGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-col sm:flex-row',
								fields: [
									{
										key: 'referee.refQualifications',
										text: 'Versenybírói minősítés',
										textKey: 'referee.refQualifications',
										className: 'sm:mr-6',
										component: EditFormSelectField,
										multiple: true,
										items: data.formData.refQualifications,
										required: true
									},
									{
										key: 'referee.examDate',
										text: 'Vizsga éve',
										textKey: 'referee.examDate',
										className: 'sm:ml-6',
										component: EditFormDateField,
										views: ['year'],
										localeFormatMap: {
											en: 'yyyy',
											hu: 'yyyy'
										},
										required: true
									}
								]
							}
						]
					},
					{
						key: 'fullLocationGroup',
						component: EditFormGroupFields,
						className: blockClassName,
						fields: [
							{
								key: 'sepLocation',
								text: 'Tartózkodási hely',
								textKey: 'sepLocation',
								component: EditFormSeparatorField,
								icon: 'place',
								className: blockTitleClassName,
								isControlledInput: false
							},
							{
								key: 'referee.locationCountry',
								text: 'Ország',
								textKey: 'referee.locationCountry',
								component: EditFormAutoCompleteField,
								query: (query, limit) => CountryService.getCountrySelection(query, limit),
								required: false
							},
							{
								key: 'locationGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-col md:flex-row',
								fields: [
									{
										key: 'referee.locationZipcode',
										text: 'Irányítószám',
										textKey: 'referee.locationZipcode',
										component: EditFormTextField,
										maxLength: 16,
										className: 'w-full md:w-1/5 md:mr-12',
										required: false
									},
									{
										key: 'referee.locationCity',
										text: 'Város',
										textKey: 'referee.locationCity',
										component: EditFormTextField,
										maxLength: 40,
										className: 'w-full md:w-2/5 md:mr-12',
										required: false
									},
									{
										key: 'referee.locationCounty',
										text: 'Megye',
										textKey: 'referee.locationCounty',
										component: EditFormSelectField,
										items: data.formData.counties,
										className: 'w-full md:w-2/5',
										required: false
									}
								]
							},
							{
								key: 'locationStreetGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-col md:flex-row',
								fields: [
									{
										key: 'referee.locationStreet',
										text: 'Utca',
										textKey: 'referee.locationStreet',
										component: EditFormTextField,
										maxLength: 100,
										className: 'w-full md:w-4/5 md:mr-12',
										required: false
									},
									{
										key: 'referee.locationStreetType',
										text: 'Típus',
										textKey: 'referee.locationStreetType',
										component: EditFormSelectField,
										items: data.formData.publicPlaceTypes,
										className: 'w-full md:w-1/5',
										required: false
									}
								]
							},
							{
								key: 'locationNumberGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-row',
								fields: [
									{
										key: 'referee.locationNumber',
										text: 'Házszám',
										textKey: 'referee.locationNumber',
										component: EditFormTextField,
										maxLength: 50,
										className: 'w-1/3 mr-12',
										required: false
									},
									{
										key: 'referee.locationFloor',
										text: 'Emelet',
										textKey: 'referee.locationFloor',
										component: EditFormTextField,
										maxLength: 10,
										className: 'w-1/3 mr-12'
									},
									{
										key: 'referee.locationDoor',
										text: 'Ajtó',
										textKey: 'referee.locationDoor',
										component: EditFormTextField,
										maxLength: 10,
										className: 'w-1/3'
									}
								]
							}
						]
					}
				]
			},
			{
				key: 'fullCompetitorGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				dependsOn: 'isCompetitor',
				visibleIf: isCompetitor => isCompetitor,
				fields: [
					{
						key: 'sepCompetitorGroup',
						text: 'Egyéni versenyző adatok',
						textKey: 'sepCompetitorGroup',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						icon: 'person',
						isControlledInput: false
					},
					{
						key: 'competitorGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col',
						fields: [
							{
								key: 'competitor.chipNumber',
								text: 'Chip szám',
								textKey: 'competitor.chipNumber',
								component: EditFormTextField,
								maxLength: 100,
								required: false
							},
							{
								key: 'competitor.doctorDate',
								text: 'Sportorvosi lejárati dátum',
								textKey: 'competitor.doctorDate',
								component: EditFormDateField,
								required: true,
								dependsOn: 'birthDate',
								visibleIf: birthDate => isUnderAge(birthDate),
								maxDate: add(new Date(), { months: 6 })
							},
							{
								key: 'competitor.doctorDate',
								text: 'Sportorvosi lejárati dátum',
								textKey: 'competitor.doctorDate',
								component: EditFormDateField,
								required: true,
								dependsOn: 'birthDate',
								visibleIf: birthDate => !isUnderAge(birthDate),
								maxDate: add(new Date(), { months: 12 })
							},
							{
								key: 'competitor.doctorDocument',
								text: 'Sportorvosi dokumentum',
								textKey: 'competitor.doctorDocument',
								component: EditFormFileInputField,
								required: false
							}
						]
					}
				]
			},
			{
				key: 'accept',
				text: 'A Magyar Triatlon Szövetség alapszabályzatát megismertem, tartalmát elfogadom, és magamra kötelező érvényűnek tekintem.',
				textKey: 'accept',
				component: EditFormCheckboxField,
				required: true
			}
		]
	};
};

function PersonRegistrationForm() {
	const [formData, setFormData] = useState(null);
	if (formData == null) {
		PersonRegistrationService.getFormData().then(result => setFormData(result));
		return <></>;
	}
	const booleanList = [
		{
			id: true,
			name: 'Igen'
		},
		{
			id: false,
			name: 'Nem'
		}
	];
	const myConfig = config({ formData, booleanList });
	return <RegistrationFormPage config={myConfig} />;
}

export default PersonRegistrationForm;
