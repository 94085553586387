import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import DashboardRoleTableService from '../service/DashboardRoleTableService';

const config = {
	key: 'dashboardRoleTable',
	text: 'Jogkör',
	textKey: 'dashboardRoleTable',
	service: DashboardRoleTableService,
	editFormState: item => `/dashboard-role/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'title',
			text: 'Név',
			textKey: 'title',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'adminDescription',
			text: 'Leírás',
			textKey: 'adminDescription',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'isEnabled',
			text: 'Engedélyezve',
			textKey: 'isEnabled',
			type: 'boolean',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'created',
			text: 'Létrehozva',
			textKey: 'created',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'updated',
			text: 'Módosítva',
			textKey: 'updated',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		}
	]
};

function DashboardRoleTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('dashboardRoleTable', reducer('dashboardRoleTable'))(DashboardRoleTable);
