import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';
import OrderableNamedDto from './OrderableNamedDto';

export default class OrderableNamedParentTableDto extends OrderableNamedDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.parent = null;
	}

	/**
	 * @typedef {Object} _OrderableNamedParentTableDtoProps
	 * @property {NamedDto} parent
	 *
	 * @typedef {import("./OrderableNamedDto").OrderableNamedDtoProps & _OrderableNamedParentTableDtoProps} OrderableNamedParentTableDtoProps
	 */
	/**
	 *
	 * @param {OrderableNamedParentTableDtoProps} props
	 * @returns {OrderableNamedParentTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.parent = props.parent != null ? new NamedDto().setup(props.parent) : null;
		return this;
	}
}
