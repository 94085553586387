import UserTableService from 'app/admin/user/service/UserTableService';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';

const config = {
	key: 'userTable',
	text: 'Hozzáférés',
	textKey: 'users',
	service: UserTableService,
	icon: 'person',
	editFormState: item => `/user/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['filter', 'search', 'refresh', 'new'],
	filterFields: [
		{
			text: 'E-mail',
			propertyName: 'email',
			propertyType: 'String',
			type: 'LIKE'
		},
		{
			text: 'Regisztráció ideje',
			propertyName: 'registrationTimestamp',
			propertyType: 'Date',
			type: 'INTERVAL'
		},
		{
			text: 'Utolsó belépés ideje',
			propertyName: 'lastLoginTimestamp',
			propertyType: 'Date',
			type: 'INTERVAL',
			withTime: true
		},
		{
			text: 'Státusz',
			propertyName: 'registrationStatus',
			propertyType: 'String',
			type: 'IN',
			items: [
				{
					value: 'NEW',
					label: 'Új'
				},
				{
					value: 'ACTIVE',
					label: 'Aktív'
				},
				{
					value: 'DELETED',
					label: 'Törölt'
				},
				{
					value: 'DISABLED',
					label: 'Letiltott'
				}
			]
		}
	],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name',
			text: 'Név',
			textKey: 'name',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'roleList',
			text: 'Jogkör',
			textKey: 'roleList',
			type: 'text',
			sortable: false,
			disablePadding: false
		},
		{
			key: 'email',
			text: 'E-mail',
			textKey: 'email',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'registrationTimestamp',
			text: 'Regisztráció ideje',
			textKey: 'registrationTime',
			type: 'date',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'lastLoginTimestamp',
			text: 'Utolsó belépés',
			textKey: 'lastLogin',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'registrationStatus',
			text: 'Státusz',
			textKey: 'registrationStatus',
			type: 'text',
			sortable: true,
			disablePadding: false
		}
	]
};

function UserTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('userTable', reducer('userTable'))(UserTable);
