import KeyedDto from 'modules/base/dto/KeyedDto';

export class UserPaymentEditDto extends KeyedDto {
	constructor() {
		super();
		/** @type {string} */
		this.description = null;
		/** @type {number} */
		this.payableNet = null;
		/** @type {number} */
		this.payableVat = null;
		/** @type {number} */
		this.payableGross = null;
		/** @type {Date} */
		this.billingDate = null;
		/** @type {number} */
		this.user = null;
		/** @type {string} */
		this.paymentStatus = null;
		/** @type {Date} */
		this.paymentDate = null;
		/** @type {string} */
		this.paymentSubject = null;
	}

	/**
	 * @typedef {Object} UserPaymentEditDto
	 * @property {string} description
	 * @property {number} payableNet
	 * @property {number} payableVat
	 * @property {number} payableGross
	 * @property {Date} billingDate
	 * @property {number} user
	 * @property {string} paymentStatus
	 * @property {Date} paymentDate
	 * @property {string} paymentSubject
	 * @typedef {import("modules/base/dto/KeyedDto").KeyedDtoProps & UserPaymentEditDto} UserPaymentEditDtoProps
	 */
	/**
	 *
	 * @param {UserPaymentEditDtoProps} props
	 * @returns {UserPaymentEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.description = props.description;
		this.payableNet = props.payableNet;
		this.payableVat = props.payableVat;
		this.payableGross = props.payableGross;
		this.billingDate = props.billingDate != null ? new Date(props.billingDate) : null;
		this.user = props.user;
		this.paymentStatus = props.paymentStatus;
		this.paymentDate = props.paymentDate != null ? new Date(props.paymentDate) : null;
		this.paymentSubject = props.paymentSubject;
		return this;
	}
}

export default UserPaymentEditDto;
