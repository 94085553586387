import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell, Button, Icon, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Switch } from '@material-ui/core';
import { useState } from 'react';
import PaymentIcon from '@material-ui/icons/Payment';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import AthleteTransferService from '../service/AthleteTransferService';

function AthleteTransferPaymentTableButton(props) {
	const dispatch = useDispatch();
	const { item } = props;
	const user = useSelector(({ auth }) => auth.user);
	const userId = user.data.id;
	const [showDialog, setShowDialog] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChangePayment = () => {
		setLoading(true);
		AthleteTransferService.paymentReceived(item.id).then(resp => {
			if (resp != null && resp.successful) {
				dispatch({ type: 'athleteTransferTable/event/setNeedDataReload' });
			} else {
				dispatch(showMessage({ message: 'Hiba történt a beállítás során.' }));
			}
			setLoading(false);
		});
	};

	if (user.isSuperAdmin && item.status === 'AWAITING_PAYMENT' && item.paymentMode === 'BANK_TRANSFER') {
		return (
			<TableCell className="w-auto text-center" padding="none">
				<Tooltip title="Fizetve?">
					<Switch checked={false} disabled={loading} onChange={() => setShowConfirm(true)} inputProps={{ 'aria-label': 'secondary checkbox' }} />
				</Tooltip>
				<ConfirmDialog show={showConfirm} title="Fizetve" description="Biztosan fizetettre állítod a kiválaszott átigazolást?" onYes={() => handleChangePayment()} onClose={() => setShowConfirm(false)} />
			</TableCell>
		);
	}
	if (user.isClub && userId === item.targetClub.id && item.status === 'AWAITING_PAYMENT' && item.paymentMode === 'BANK_TRANSFER') {
		return (
			<TableCell className="w-auto text-center" padding="none">
				<Tooltip title="Fizetési adatok">
					<span>
						<IconButton onClick={e => setShowDialog(true)}>
							<PaymentIcon fontSize="small" />
						</IconButton>
					</span>
				</Tooltip>
				{showDialog && (
					<Dialog open fullWidth>
						<DialogTitle disableTypography className="border-b">
							Fizetési adatok
						</DialogTitle>
						<DialogContent className="p-20">
							<p>Összeg: 5.000 Ft</p>
							<p>Közlemény: {item.athlete.name} 2024.évi átigazolási díj</p>
							<p>Bankszámlaszám (MTSZ): UniCredit Bank 10918001-00000050-55140008</p>
						</DialogContent>
						<DialogActions className="justify-between border-t p-20">
							<Button
								onClick={() => {
									setShowDialog(false);
								}}
							>
								Bezár
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</TableCell>
		);
	}
	return <TableCell className="p-4 md:p-16" scope="row" />;
}

export default AthleteTransferPaymentTableButton;
