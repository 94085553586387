import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import ContactRegistrationDto from './ContactRegistrationDto';

export class ClubRegistrationDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.billingName = null;
		/** @type {string} */
		this.bankName = null;
		/** @type {string} */
		this.bankAccountNumber = null;
		/** @type {string} */
		this.billingZipcode = null;
		/** @type {string} */
		this.billingCity = null;
		/** @type {string} */
		this.billingStreet = null;
		/** @type {number} */
		this.billingStreetType = null;
		/** @type {string} */
		this.billingNumber = null;
		/** @type {ContactRegistrationDto} */
		this.economicLeader = null;
	}

	/**
	 * @typedef {Object} _ClubRegistrationDtoProps
	 * @property {string} billingName
	 * @property {string} bankName
	 * @property {string} bankAccountNumber
	 * @property {string} billingZipcode
	 * @property {string} billingCity
	 * @property {string} billingStreet
	 * @property {number} billingStreetType
	 * @property {string} billingNumber
	 * @property {ContactRegistrationDto} economicLeader
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ClubRegistrationDtoProps} ClubRegistrationDtoProps
	 */
	/**
	 *
	 * @param {ClubRegistrationDtoProps} props
	 * @returns {ClubRegistrationDto}
	 */
	setup(props) {
		super.setup(props);
		this.billingName = props.billingName;
		this.bankName = props.bankName;
		this.bankAccountNumber = props.bankAccountNumber;
		this.billingZipcode = props.billingZipcode;
		this.billingCity = props.billingCity;
		this.billingStreet = props.billingStreet;
		this.billingStreetType = props.billingStreetType;
		this.billingNumber = props.billingNumber;
		this.economicLeader = props.economicLeader != null ? new ContactRegistrationDto().setup(props.economicLeader) : null;
		return this;
	}
}

export default ClubRegistrationDto;
