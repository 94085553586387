import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import CompetitionEntryTableDto from '../dto/CompetitionEntryTableDto';

class CompetitionEntryClubTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'competitionEntryClubTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<CompetitionEntryTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, CompetitionEntryTableDto)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competition
	 * @return {Promise<number>}
	 */
	getCompetititionAmountSum(competition) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getCompetititionAmountSum`, { competition })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new CompetitionEntryClubTableService();
