import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, LinearProgress, FormHelperText, FormControl, FormLabel, Link, IconButton } from '@material-ui/core';
import nBossConnection from 'modules/base/service/nBossConnection';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import FileRepositoryService from 'modules/base/service/file/FileRepositoryService';
import { formatBytes } from 'modules/base/utils/AmountUtils';
import DeleteIcon from '@material-ui/icons/Delete';
import { determineEditableAccess } from 'modules/ui/editform/EditFormUtils';

function EditFormImageInputField(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { value, onChange } = props.field;
	const { error } = props.fieldState;
	const [selectedFile, setSelectedFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadingFileName, setUploadingFileName] = useState(null);
	const [uploadingPercentage, setUploadingPercentage] = useState(0);
	const [fileInfo, setFileInfo] = useState(null);
	const service = _.isUndefined(props.fieldConfig.service) ? FileRepositoryService : props.fieldConfig.service;
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));

	const handleFileChange = e => {
		setSelectedFile(e.target.files[0]);
	};

	useEffect(() => {
		if (value != null) {
			service.getInfo(value).then(result => {
				setFileInfo(result);
			});
		} else {
			setFileInfo(null);
		}
	}, value);

	useEffect(() => {
		if (selectedFile != null) {
			const formData = new FormData();
			formData.append('file', selectedFile, selectedFile.name);
			setUploadingFileName(selectedFile.name);
			setUploadingPercentage(0);
			setIsUploading(true);

			nBossConnection.connection
				.post(`${service.CONTROL}/addFile`, formData, {
					onUploadProgress: progressEvent => {
						setUploadingPercentage((progressEvent.loaded / progressEvent.total) * 100);
					}
				})
				.then(response => {
					setIsUploading(false);
					setSelectedFile(null);
					if (response.data !== null) {
						onChange(response.data);
					} else {
						dispatch(showMessage({ message: t('FILE_UPLOADING_ERROR') }));
					}
				})
				.catch(e => {
					console.log(e);
					setIsUploading(false);
					setSelectedFile(null);
					dispatch(showMessage({ message: t('FILE_UPLOADING_ERROR') }));
				});
		}
	}, selectedFile);

	return (
		<FormControl className="w-full mt-8 mb-16" error={error}>
			<FormLabel className="mb-16">{!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}</FormLabel>
			{!isUploading && value !== null && fileInfo !== null ? (
				<Card className="w-full mt-8 mb-16">
					<div className="flex flex-row justify-left items-center p-12">
						<Link href={props.fieldConfig.imageUrl(fileInfo)} role="button" target="_blank" className="flex-1 hover:no-underline">
							<div className="mr-16 text-lg">{fileInfo.fileName}</div>
							<div>
								{t('SIZE')}: {formatBytes(fileInfo.fileSize)}
							</div>
						</Link>
						{!readOnly ? (
							<div>
								<IconButton color="secondary" component="span" onClick={() => onChange(null)}>
									<DeleteIcon />
								</IconButton>
							</div>
						) : null}
					</div>
					<div className="flex justify-center relative">
						{!readOnly ? <input type="file" onChange={e => handleFileChange(e)} className="absolute inset-0 w-full h-full" style={{ opacity: 0.0001 }} accept="image/*" /> : null}
						<img src={props.fieldConfig.imageUrl(fileInfo)} alt={fileInfo.fileName} className="h-216" />
					</div>
				</Card>
			) : null}
			{value === null && !isUploading ? (
				<Card className="w-full relative" style={{ height: '200px' }}>
					<div className="flex flex-row justify-center items-center h-full">
						<Typography className="h3 sm:h2 font-medium">{readOnly ? t('FILE_READONLY') : t('SELECT_FILE')}</Typography>
					</div>
					{!readOnly ? <input type="file" onChange={e => handleFileChange(e)} className="absolute inset-0 w-full h-full" style={{ opacity: 0.0001 }} accept="image/*" /> : null}
				</Card>
			) : null}
			{isUploading ? (
				<Card className="w-full relative" style={{ height: '200px' }}>
					<div className="flex flex-col justify-center items-center h-full">
						{uploadingPercentage < 100 ? (
							<div>
								<Typography className="h3 sm:h2 font-medium p-6">{t('UPLOADING_PROGRESS')}</Typography>
								<LinearProgress className="w-64 sm:w-320 max-w-full rounded-2" color="secondary" variant="determinate" value={uploadingPercentage} />
							</div>
						) : (
							<Typography className="h3 sm:h2 font-medium p-6">{t('UPLOADING_DONE')}</Typography>
						)}
						<Typography className="h3 sm:h2 font-medium p-6">{uploadingFileName}</Typography>
					</div>
				</Card>
			) : null}
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export default EditFormImageInputField;
