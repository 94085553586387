import { useFormContext } from 'react-hook-form';

function CompetitionPartNameField(props) {
	const methods = useFormContext();
	const { value, onChange } = props.field;
	const { formData } = props.fieldConfig;

	const content = methods.watch(props.fieldPrefix);
	const sportCategory = content.sportCategory != null ? formData.sportCategories.find(d => d.id === content.sportCategory) : null;
	const qualification = content.qualification != null ? formData.competitionPartQualifications.find(d => d.id === content.qualification) : null;
	const distance = content.distance != null ? formData.distances.find(d => d.id === content.distance) : null;
	const result = [];
	if (sportCategory != null) {
		result.push(sportCategory.name);
		if (qualification != null) {
			result.push(qualification.name);
			if (distance != null) {
				result.push(distance.name);
			}
		}
	}
	const resultStr = result.length > 0 ? result.join(' - ') : 'Új elem';
	if (value !== resultStr) onChange(resultStr);
	return <></>;
}

export default CompetitionPartNameField;
