import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openDialog, closeDialog } from 'app/store/fuse/dialogSlice';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

function ConfirmDialog(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('common');

	useEffect(() => {
		if (props.show) {
			dispatch(
				openDialog({
					children: (
						<>
							<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										props.onNo ? props.onNo() : null;
										dispatch(closeDialog());
										props.onClose();
									}}
									color="primary"
								>
									{t('CANCEL')}
								</Button>
								<Button
									onClick={() => {
										props.onYes ? props.onYes() : null;
										dispatch(closeDialog());
										props.onClose();
									}}
									color="primary"
									autoFocus
								>
									{t('YES')}
								</Button>
							</DialogActions>
						</>
					)
				})
			);
		}
	}, [props.show]);
	return <></>;
}

export default ConfirmDialog;
