export class CompetitionEntryInfoDto {
	constructor() {
		/** @type {number} */
		this.competition = null;
		/** @type {number} */
		this.race = null;
		/** @type {boolean} */
		this.alreadyEntry = null;
		/** @type {boolean} */
		this.canEntry = null;
	}

	setup(props) {
		this.competition = props.competition;
		this.race = props.race;
		this.alreadyEntry = props.alreadyEntry;
		this.canEntry = props.canEntry;
		return this;
	}
}

export default CompetitionEntryInfoDto;
