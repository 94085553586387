import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class ContactRegistrationDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.firstName = null;
		/** @type {string} */
		this.lastName = null;
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.phoneNumber = null;
	}

	/**
	 * @typedef {Object} _ContactRegistrationDtoProps
	 * @property {string} firstName
	 * @property {string} lastName
	 * @property {string} email
	 * @property {string} phoneNumber
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ContactRegistrationDtoProps} ContactRegistrationDtoProps
	 */
	/**
	 *
	 * @param {ContactRegistrationDtoProps} props
	 * @returns {ContactRegistrationDto}
	 */
	setup(props) {
		super.setup(props);
		this.firstName = props.firstName;
		this.lastName = props.lastName;
		this.email = props.email;
		this.phoneNumber = props.phoneNumber;
		return this;
	}
}

export default ContactRegistrationDto;
