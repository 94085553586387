import { Tooltip, TableCell } from '@material-ui/core';

function NsrStatusTableCell(props) {
	return (
		<TableCell className="p-4 md:p-16" scope="row">
			<Tooltip title={(props.item && props.item.nsrMessage) || 'Nincs info'}>
				<div>{props.item.getAsString(props.config.key)}</div>
			</Tooltip>
		</TableCell>
	);
}

export default NsrStatusTableCell;
