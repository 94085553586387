import UserTableService from 'app/admin/user/service/UserTableService';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import AgeGroupTableService from '../service/AgeGroupTableService';

const config = {
	key: 'ageGroupTable',
	text: 'Korcsoportok',
	textKey: 'ageGroup',
	service: AgeGroupTableService,
	editFormState: item => `/age-group/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify'],
	columns: [
		{
			key: 'name',
			text: 'Név',
			textKey: 'name',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'parent.name',
			text: 'Szülő',
			textKey: 'parent.name',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'child',
			text: 'Gyermek',
			textKey: 'child',
			type: 'boolean',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'ageMin',
			text: 'Nyitó életkor',
			textKey: 'ageMin',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'ageMax',
			text: 'Záró életkor',
			textKey: 'ageMay',
			type: 'text',
			sortable: true,
			disablePadding: false
		}
	]
};

function AgeGroupTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('ageGroupTable', reducer('ageGroupTable'))(AgeGroupTable);
