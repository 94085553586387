import _ from '@lodash';
import { FormControl, Checkbox, FormHelperText, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import clsx from 'clsx';
import { determineEditableAccess } from 'modules/ui/editform/EditFormUtils';

function EnumEditFormCheckboxesField(props) {
	const methods = useFormContext();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { value, onChange } = props.field;
	const { error } = props.fieldState;
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const { items } = props.fieldConfig;

	return _.isUndefined(value) ? (
		<></>
	) : (
		<FormControl error={error} className={clsx('w-full mt-8 mb-16', props.fieldConfig.className)}>
			{items.map(item => (
				<FormControlLabel
					key={item.value}
					control={
						<Checkbox
							checked={value === item.value}
							onChange={e => {
								if (e.target.checked) {
									onChange(item.value);
									!_.isUndefined(props.fieldConfig.onChange) ? props.fieldConfig.onChange(item.value, props.field, methods) : null;
								}
							}}
							inputProps={{ 'aria-label': 'primary checkbox', disabled: readOnly }}
							id={props.field.name}
						/>
					}
					label={item.label}
				/>
			))}

			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export default EnumEditFormCheckboxesField;
