import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';
import NamedDto from 'modules/base/dto/NamedDto';
import PersonRegistrationDto from '../dto/PersonRegistrationDto';

class PersonRegistrationService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'personSubmitControl';
	}

	/**
	 * @return {Promise<PersonRegistrationDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new PersonRegistrationDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} type
	 * @return {Promise<PersonRegistrationDto>}
	 */
	createAthleteReg(type) {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/createAthleteReg`)
				.then(response => {
					if (type === 'license') response.data.athleteRegType = 'LICENSE_REDEEM';
					resolve(new PersonRegistrationDto().setup(response.data));
				})
				.catch(reject);
		});
	}

	/**
	 * @param {PersonRegistrationDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Map<String, NamedDto[]}
	 */
	getFormData() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getFormData`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new PersonRegistrationService();
