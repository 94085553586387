import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputLabel, MenuItem, Select, ListItemText, Checkbox } from '@material-ui/core';

function TableFilterStringInField(props) {
	const { t } = useTranslation(props.config.topic ? ['common', props.config.topic] : 'common');
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const filterSelection = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterSelection);
	const [value, setValue] = useState([]);
	const [items, setItems] = useState([]);

	useEffect(() => {
		if (props.filterConfig.items) {
			setItems(props.filterConfig.items);
		} else if (typeof filterSelection.enumMap[props.filterConfig.propertyName] !== 'undefined') {
			setItems(filterSelection.enumMap[props.filterConfig.propertyName]);
		}
	}, [filterSelection]);

	useEffect(() => {
		const index = filterList.findIndex(el => el.propertyName === props.filterConfig.propertyName);
		if (index > -1) {
			setValue(filterList[index].value);
		} else {
			setValue([]);
		}
	}, [filterList]);

	useEffect(() => {
		if (_.isArray(value) && value.length > 0) {
			const filterValue = {
				propertyName: props.filterConfig.propertyName,
				type: props.filterConfig.type,
				value
			};
			props.onFilterChanged(filterValue);
		} else {
			props.onFilterRemoved(props.filterConfig.propertyName);
		}
	}, [value]);

	const renderValue = selected => {
		return items
			.filter(d => selected.indexOf(d.value) > -1)
			.map(d => d.label)
			.join(', ');
	};

	return (
		<div>
			<InputLabel className="mt-16 w-full">{!_.isUndefined(props.filterConfig.text) ? props.filterConfig.text : t(`${props.config.topic}:${props.filterConfig.propertyName}`)}</InputLabel>
			<Select value={value} onChange={e => setValue(e.target.value)} className="mt-16 w-full" multiple renderValue={selected => renderValue(selected)}>
				{items.map(i => {
					return (
						<MenuItem key={i.value} value={i.value}>
							<Checkbox checked={value.indexOf(i.value) > -1} />
							<ListItemText primary={i.label} />
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
}

export default TableFilterStringInField;
