import _ from 'lodash';
import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import MinositesType from './MinositesType';

export default class MedicalLicenseTableDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.oseiSportag = null;
		/** @type {string} */
		this.minosites = null;
		/** @type {Date} */
		this.lejaratiDatum = null;
		/** @type {Date} */
		this.utolsoFrissites = null;
		/** @type {string} */
		this.megjegyzes = null;
		/** @type {boolean} */
		this.archive = null;
		/** @type {boolean} */
		this.lejart = null;
		/** @type {boolean} */
		this.ervenytelenites = null;
		/** @type {Date} */
		this.elsolekerdezesDatum = null;
		/** @type {Date} */
		this.minositesKiadas = null;
	}

	/**
	 * @typedef {Object} _MedicalLicenseTableDtoProps
	 * @property {string} oseiSportag
	 * @property {string} minosites
	 * @property {Date} lejaratiDatum
	 * @property {Date} utolsoFrissites
	 * @property {string} megjegyzes
	 * @property {boolean} archive
	 * @property {boolean} lejart
	 * @property {boolean} ervenytelenites
	 * @property {Date} elsolekerdezesDatum
	 * @property {Date} minositesKiadas
	 *
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _MedicalLicenseTableDtoProps} MedicalLicenseTableDtoProps
	 */
	/**
	 *
	 * @param {MedicalLicenseTableDtoProps} props
	 * @returns {MedicalLicenseTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.oseiSportag = props.oseiSportag;
		this.minosites = props.minosites;
		this.lejaratiDatum = props.lejaratiDatum != null ? new Date(props.lejaratiDatum) : null;
		this.utolsoFrissites = props.utolsoFrissites != null ? new Date(props.utolsoFrissites) : null;
		this.megjegyzes = props.megjegyzes;
		this.archive = props.archive;
		this.lejart = props.lejart;
		this.ervenytelenites = props.ervenytelenites;
		this.elsolekerdezesDatum = props.elsolekerdezesDatum != null ? new Date(props.elsolekerdezesDatum) : null;
		this.minositesKiadas = props.minositesKiadas != null ? new Date(props.minositesKiadas) : null;
		return this;
	}

	getAsString(key) {
		if (key === 'minosites') {
			return this.minosites != null ? MinositesType[this.minosites] : '';
		}
		return super.getAsString(key);
	}
}
