import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import ContactRegistrationDto from './ContactRegistrationDto';

export class RefereeRegistrationDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.shirtSize = null;
		/** @type {boolean} */
		this.hasDrivingLicense = null;
		/** @type {boolean} */
		this.hasCar = null;
		/** @type {string} */
		this.languages = null;
		/** @type {string} */
		this.licenseNumber = null;
		/** @type {Date} */
		this.licenseExpirationDate = null;
		/** @type {number[]} */
		this.refQualifications = null;
		/** @type {Date} */
		this.examDate = null;
		/** @type {string} */
		this.locationZipcode = null;
		/** @type {string} */
		this.locationCity = null;
		/** @type {number} */
		this.locationCountry = null;
		/** @type {number} */
		this.locationCounty = null;
		/** @type {string} */
		this.locationStreet = null;
		/** @type {number} */
		this.locationStreetType = null;
		/** @type {string} */
		this.locationNumber = null;
		/** @type {string} */
		this.locationFloor = null;
		/** @type {string} */
		this.locationDoor = null;
	}

	/**
	 * @typedef {Object} _RefereeRegistrationDtoProps
	 * @property {string} shirtSize
	 * @property {boolean} hasDrivingLicense
	 * @property {boolean} hasCar
	 * @property {string} languages
	 * @property {string} licenseNumber
	 * @property {Date} licenseExpirationDate
	 * @property {number[]} refQualifications
	 * @property {Date} examDate
	 * @property {string} locationZipcode
	 * @property {string} locationCity
	 * @property {number} locationCountry
	 * @property {number} locationCounty
	 * @property {string} locationStreet
	 * @property {number} locationStreetType
	 * @property {string} locationNumber
	 * @property {string} locationFloor
	 * @property {string} locationDoor
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _RefereeRegistrationDtoProps} RefereeRegistrationDtoProps
	 */
	/**
	 *
	 * @param {RefereeRegistrationDtoProps} props
	 * @returns {RefereeRegistrationDto}
	 */
	setup(props) {
		super.setup(props);
		this.shirtSize = props.shirtSize;
		this.hasDrivingLicense = props.hasDrivingLicense;
		this.hasCar = props.hasCar;
		this.languages = props.languages;
		this.licenseNumber = props.licenseNumber;
		this.licenseExpirationDate = props.licenseExpirationDate != null ? new Date(props.licenseExpirationDate) : null;
		this.refQualifications = props.refQualifications;
		this.examDate = props.examDate != null ? new Date(props.examDate) : null;
		this.locationZipcode = props.locationZipcode;
		this.locationCity = props.locationCity;
		this.locationCountry = props.locationCountry;
		this.locationCounty = props.locationCounty;
		this.locationStreet = props.locationStreet;
		this.locationStreetType = props.locationStreetType;
		this.locationNumber = props.locationNumber;
		this.locationFloor = props.locationFloor;
		this.locationDoor = props.locationDoor;
		return this;
	}
}

export default RefereeRegistrationDto;
