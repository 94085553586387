import _ from '@lodash';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormRadioGroupField from 'modules/ui/editform/fields/EditFormRadioGroupField';
import EditFormSubComponent from 'modules/ui/editform/subForm/EditFormSubComponent';
import DashboardRoleEditService from '../service/DashboardRoleEditService';

const config = {
	key: 'dashboardRoleEditForm',
	text: 'Jogkör',
	textKey: 'dashboardRoleEditForm',
	service: DashboardRoleEditService,
	watchTitleKey: 'title',
	tabList: [
		{
			key: 'basic',
			text: 'Alapadatok',
			textKey: 'basic'
		},
		{
			key: 'servicePermissionList',
			text: 'Szolgáltatások',
			textKey: 'servicePermissionList'
		},
		{
			key: 'contentPermissionList',
			text: 'Tartalom hozzáférés',
			textKey: 'contentPermissionList'
		}
	],
	fields: [
		{
			key: 'title',
			text: 'Név',
			textKey: 'title',
			tab: 'basic',
			component: EditFormTextField,
			required: true
		},
		{
			key: 'key',
			text: 'Rendszer által használt név (opcionális)',
			textKey: 'key',
			tab: 'basic',
			component: EditFormTextField
		},
		{
			key: 'adminDescription',
			text: 'Leírás',
			textKey: 'adminDescription',
			tab: 'basic',
			component: EditFormTextField,
			multiline: true
		},
		{
			key: 'orderIndex',
			text: 'Pozíció',
			textKey: 'orderIndex',
			tab: 'basic',
			type: 'number',
			component: EditFormTextField
		},
		{
			key: 'isSuperAdmin',
			text: 'Szuperadmin',
			textKey: 'isSuperAdmin',
			tab: 'basic',
			component: EditFormCheckboxField
		},
		{
			key: 'isEnabled',
			text: 'Engedélyezve',
			textKey: 'isEnabled',
			tab: 'basic',
			component: EditFormCheckboxField
		},
		{
			key: 'servicePermissionList',
			text: 'Szolgáltatások',
			textKey: 'servicePermissionList',
			tab: 'servicePermissionList',
			component: EditFormSubComponent,
			defaultValue: {
				id: null,
				key: null,
				name: null,
				title: null,
				service: null,
				grantedLevel: null
			},
			fields: [
				{
					key: 'service',
					text: 'Szolgáltatás',
					textKey: 'service',
					component: EditFormSelectField,
					required: true,
					type: 'enum',
					items: () => DashboardRoleEditService.getServicePermissionListServiceSelection()
				},
				{
					key: 'grantedLevel',
					text: 'Jogosultság',
					textKey: 'grantedLevel',
					component: EditFormRadioGroupField,
					required: true,
					type: 'enum',
					items: [
						{
							label: 'Csak olvasható',
							value: 'ReadOnly'
						},
						{
							label: 'Írás-olvasás',
							value: 'WriteRead'
						},
						{
							label: 'Hozzáférés megtagadva',
							value: 'Deny'
						}
					]
				}
			]
		},
		{
			key: 'contentPermissionList',
			text: 'Tartalom hozzáférés',
			textKey: 'contentPermissionList',
			tab: 'contentPermissionList',
			component: EditFormSubComponent,
			watchTitleKey: 'title',
			defaultValue: {
				id: null,
				key: null,
				name: null,
				title: null,
				contentType: null,
				grantedLevel: null
			},
			fields: [
				{
					key: 'contentType',
					text: 'Tartalomtípus',
					textKey: 'contentType',
					component: EditFormSelectField,
					required: true,
					items: () => DashboardRoleEditService.getContentPermissionListContentTypeSelection()
				},
				{
					key: 'grantedLevel',
					text: 'Jogosultság',
					textKey: 'grantedLevel',
					component: EditFormRadioGroupField,
					required: true,
					type: 'enum',
					items: [
						{
							label: 'Csak olvasható',
							value: 'ReadOnly'
						},
						{
							label: 'Írás-olvasás',
							value: 'WriteRead'
						},
						{
							label: 'Hozzáférés megtagadva',
							value: 'Deny'
						}
					]
				}
			]
		}
	]
};

function DashboardRoleEditForm() {
	return <EditFormPage config={config} />;
}

export default DashboardRoleEditForm;
