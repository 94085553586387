import { useFormContext } from 'react-hook-form';

export function RaceNameGen(competitionPartList, competitionPartKeyList) {
	const result = [];
	competitionPartKeyList.forEach(partKey => {
		competitionPartList.forEach(part => {
			if (partKey === part.key) {
				result.push(part.name);
			}
		});
	});
	const resultStr = result.length > 0 ? result.join(', ') : 'Új elem';
	return resultStr;
}

function RaceNameField(props) {
	const methods = useFormContext();
	const { value, onChange } = props.field;
	const content = methods.watch(props.fieldPrefix);
	const competitionPartList = methods.watch('competitionPartList');
	const resultStr = RaceNameGen(competitionPartList, content.competitionPartKeyList);
	if (value !== resultStr) onChange(resultStr);
	return <></>;
}

export default RaceNameField;
