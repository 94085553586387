import { useParams } from 'react-router';
import { useState } from 'react';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import CompetitionEntryEditService from '../service/CompetitionEntryEditService';

const config = data => {
	return {
		key: 'competitionEntryEditForm',
		text: 'Nevezés',
		textKey: 'competitionEntry',
		service: CompetitionEntryEditService,
		fields: [
			{
				key: 'cachedPersonFullName',
				text: 'Név',
				textKey: 'cachedPersonFullName',
				component: EditFormTextField,
				required: true
			},
			{
				key: 'cachedPersonBirthDate',
				text: 'Születési dátum',
				textKey: 'cachedPersonBirthDate',
				component: EditFormDateField,
				required: true
			},
			{
				key: 'cachedPersonGender',
				text: 'Neme',
				textKey: 'cachedPersonGender',
				type: 'enum',
				component: EditFormSelectField,
				items: data.formData.genders,
				required: true
			},
			{
				key: 'cachedPersonAgeGroup',
				text: 'Korcsoport',
				textKey: 'cachedPersonAgeGroup',
				component: EditFormSelectField,
				items: data.formData.ageGroups,
				required: true
			},
			{
				key: 'cachedPersonLicenseType',
				text: 'Licensz típus',
				textKey: 'cachedPersonLicenseType',
				component: EditFormSelectField,
				type: 'enum',
				items: data.formData.licenseTypes,
				required: true
			},
			{
				key: 'cachedPersonLicenseNumber',
				text: 'Licensz száma',
				textKey: 'cachedPersonLicenseNumber',
				component: EditFormTextField,
				required: true
			},
			{
				key: 'cachedPersonChipNumber',
				text: 'Chip száma',
				textKey: 'cachedPersonChipNumber',
				component: EditFormTextField
			},
			{
				key: 'race',
				text: 'Futam',
				textKey: 'race',
				component: EditFormSelectField,
				items: data.formData.races,
				required: true
			}
		]
	};
};

function CompetitionEntryEditForm() {
	const params = useParams();
	const competitionId = Number.parseInt(params.competitionId, 10);
	const [formData, setFormData] = useState(null);
	if (formData == null) {
		CompetitionEntryEditService.getFormDataByCompetitionId(competitionId).then(result => setFormData(result));
		return <></>;
	}
	const myConfig = config({ competitionId, formData });
	return <EditFormPage config={myConfig} />;
}

export default CompetitionEntryEditForm;
