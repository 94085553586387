import _ from '@lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export class CompetitionEntryEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.cachedPersonFullName = null;
		/** @type {Date} */
		this.cachedPersonBirthDate = null;
		/** @type {string} */
		this.cachedPersonLicenseType = null;
		/** @type {number} */
		this.cachedPersonAgeGroup = null;
		/** @type {string} */
		this.cachedPersonLicenseNumber = null;
		/** @type {string} */
		this.cachedPersonChipNumber = null;
		/** @type {NamedDto} */
		this.race = null;
		/** @type {string} */
		this.cachedPersonGender;
		/** @type {string} */
		this.teamName;
		/** @type {string} */
		this.teamMember2;
		/** @type {string} */
		this.teamMember3;
		/** @type {string} */
		this.teamMember4;
	}

	/**
	 * @typedef {Object} _CompetitionEntryEditDtoProps
	 * @property {string} cachedPersonFullName
	 * @property {Date} cachedPersonBirthDate
	 * @property {string} cachedPersonLicenseType
	 * @property {string} cachedPersonLicenseNumber
	 * @property {string} cachedPersonChipNumber
	 * @property {number} cachedPersonAgeGroup
	 * @property {number} race
	 * @property {string} cachedPersonGender
	 * @property {string} teamName
	 * @property {string} teamMember2
	 * @property {string} teamMember3
	 * @property {string} teamMember4
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _CompetitionEntryEditDtoProps} CompetitionEntryEditDtoProps
	 */
	/**
	 *
	 * @param {CompetitionEntryEditDtoProps} props
	 * @returns {CompetitionEntryEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.cachedPersonFullName = props.cachedPersonFullName;
		this.cachedPersonBirthDate = props.cachedPersonBirthDate !== null ? new Date(props.cachedPersonBirthDate) : null;
		this.cachedPersonLicenseType = props.cachedPersonLicenseType;
		this.cachedPersonLicenseNumber = props.cachedPersonLicenseNumber;
		this.cachedPersonChipNumber = props.cachedPersonChipNumber;
		this.cachedPersonAgeGroup = props.cachedPersonAgeGroup != null ? props.cachedPersonAgeGroup : null;
		this.race = props.race != null ? props.race : null;
		this.cachedPersonGender = props.cachedPersonGender;
		this.org = props.org != null ? new NamedDto().setup(props.org) : null;
		this.teamName = props.teamName;
		this.teamMember2 = props.teamMember2;
		this.teamMember3 = props.teamMember3;
		this.teamMember4 = props.teamMember4;
		return this;
	}
}

export default CompetitionEntryEditDto;
