import _ from '@lodash';
import React, { useEffect, useState, useRef } from 'react';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Typography, FormControl, InputLabel, useMediaQuery, useTheme, TextField, InputAdornment, Icon, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles, fade } from '@material-ui/core/styles';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import TableOrderListDto from 'modules/base/dto/table/TableOrderListDto';
import FilePickerDirectoryItem from './filePicker/FilePickerDirectoryItem';
import FilePickerImageItem from './filePicker/FilePickerImageItem';
import FilePickerFileItem from './filePicker/FilePickerFileItem';

const useStyles = makeStyles(theme => ({
	filePicker: {
		'& a[target=_blank], button.file-item': {
			background: 'transparent',
			color: theme.palette.type === 'light' ? fade('#000', 0.54) : fade('#fff'),
			textDecoration: 'none',
			borderBottom: 0,
			'&:hover': {
				background: theme.palette.type === 'light' ? fade('#000', 0.04) : fade('#fff', 0.08),
				textDecoration: 'none'
			}
		}
	}
}));

const orderValueList = [
	{ key: 'nameAsc', propertyName: 'fileName', ascending: true },
	{ key: 'nameDesc', propertyName: 'fileName', ascending: false },
	{ key: 'dateAsc', propertyName: 'fileModified', ascending: true },
	{ key: 'dateDesc', propertyName: 'fileModified', ascending: false }
];

function FilePickerDialog(props) {
	const { t } = useTranslation('filePicker');
	const theme = useTheme();
	const classes = useStyles(props);
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [storageId, setStorageId] = useState(props.storageId || null);
	const [storageList, setStorageList] = useState(null);
	const [directoryId, setDirectoryId] = useState(props.directoryId || null);
	const [items, setItems] = useState([]);
	const [current, setCurrent] = useState(null);
	const [loading, setLoading] = useState(true);
	const [sessionName, setSessionName] = useState(getSessionString());
	const [initializationCompleted, setInitializationCompleted] = useState(false);
	const [order, setOrder] = useState(orderValueList[0].key);
	const [queryText, setQueryText] = useState('');
	const [isSearch, setIsSearch] = useState(false);
	const queryTimeout = useRef(null);
	const user = useSelector(({ auth }) => auth.user);
	const addNewAvailable = !(!_.isUndefined(user.serviceLevelMap) && user.serviceLevelMap.Storage === 'ReadOnly');

	const generateBrowserDefaultFilter = () => {
		const result = {
			list: [
				{
					propertyName: 'parentStorage',
					type: 'EQ',
					value: storageId,
					permanent: false
				},
				{
					propertyName: 'parentDirectory',
					type: directoryId != null ? 'EQ' : 'IS_NULL',
					value: directoryId != null ? directoryId : true,
					permanent: false
				}
			],
			activeCount: 2
		};
		if (props.fileCategory) {
			const fileCategoryValueList = [props.fileCategory];
			if (props.fileCategory !== 'Directory') fileCategoryValueList.push('Directory');
			result.list = [
				...result.list,
				...[
					{
						propertyName: 'fileCategory',
						type: 'IN',
						value: fileCategoryValueList,
						permanent: false
					}
				]
			];
			result.activeCount += 1;
		}
		return result;
	};

	const generateSearchDefaultFilter = () => {
		const result = {
			list: [
				{
					propertyName: 'parentStorage',
					type: 'EQ',
					value: storageId,
					permanent: false
				},
				{
					propertyName: 'fileName',
					type: 'LIKE',
					value: queryText,
					permanent: false
				}
			],
			activeCount: 2
		};
		if (props.fileCategory) {
			const fileCategoryValueList = [props.fileCategory];
			result.list = [
				...result.list,
				...[
					{
						propertyName: 'fileCategory',
						type: 'IN',
						value: fileCategoryValueList,
						permanent: false
					}
				]
			];
			result.activeCount += 1;
		}
		return result;
	};

	const generateOrderList = (propertyName, ascending) => {
		return new TableOrderListDto().setup({
			list: [
				{
					propertyName: 'isDirectory',
					ascending: false
				},
				{
					propertyName,
					ascending
				}
			]
		});
	};

	useEffect(() => {
		if (props.open && !initializationCompleted) {
			setLoading(true);
			props.storageService
				.getInstance(sessionName, true, null)
				.then(() => props.storageService.getList(sessionName, 0, 0))
				.then(resp => {
					setStorageList(resp.data);
					if (storageId === null) setStorageId(resp.data[0].id);
					return props.browserService.getInstance(sessionName, true, null);
				})
				.then(() => {
					setInitializationCompleted(true);
				});
		}
	}, [props.open]);

	useEffect(() => {
		if (props.open && initializationCompleted) {
			props.browserService.getCurrent(directoryId, storageId).then(c => setCurrent(c));
			updateFilters()
				.then(() => updateOrder())
				.then(() => updateItems())
				.then(() => setLoading(false));
		}
	}, [initializationCompleted]);

	useEffect(() => {
		if (props.open && initializationCompleted) {
			if (directoryId !== null) {
				setDirectoryId(null);
			} else {
				setLoading(true);
				props.browserService.getCurrent(directoryId, storageId).then(c => setCurrent(c));
				updateFilters()
					.then(() => updateItems())
					.then(() => setLoading(false));
			}
		}
	}, [storageId]);

	useEffect(() => {
		if (props.open && initializationCompleted) {
			setLoading(true);
			props.browserService.getCurrent(directoryId, storageId).then(c => setCurrent(c));
			updateFilters()
				.then(() => updateItems())
				.then(() => setLoading(false));
		}
	}, [directoryId]);

	const getFilePickerItem = item => {
		if (item.fileCategory === 'Directory')
			return (
				<FilePickerDirectoryItem
					key={item.id}
					item={item}
					onChangeDirectory={() => {
						if (isSearch) {
							setQueryText('');
							setIsSearch(false);
						}
						setDirectoryId(item.id);
					}}
					selectable={props.directorySelectable || false}
					onSelectDirectory={() => props.onSelectDirectory(item)}
				/>
			);
		if (item.fileCategory === 'Image') return <FilePickerImageItem key={item.id} item={item} onSelectImage={() => props.onSelectImage(item)} getUrl={props.getUrl} selectable={props.fileSelectable} />;
		return <FilePickerFileItem key={item.id} item={item} onSelectFile={() => props.onSelectFile(item)} getUrl={props.getUrl} selectable={props.fileSelectable} />;
	};

	useEffect(() => {
		if (props.open && initializationCompleted) {
			setLoading(true);
			updateOrder()
				.then(() => updateItems())
				.then(() => setLoading(false));
		}
	}, [order]);

	useEffect(() => {
		if (props.open && initializationCompleted) {
			if (queryTimeout && queryTimeout.current) clearTimeout(queryTimeout.current);
			queryTimeout.current = setTimeout(() => {
				setLoading(true);
				updateFilters()
					.then(() => updateItems())
					.then(() => setLoading(false));
			}, 500);
		}
	}, [queryText]);

	const updateFilters = () => {
		if (queryText.length > 0) {
			setIsSearch(true);
			return props.browserService.setFilter(sessionName, generateSearchDefaultFilter());
		}
		setIsSearch(false);
		return props.browserService.setFilter(sessionName, generateBrowserDefaultFilter());
	};

	const updateOrder = () => {
		const orderValue = orderValueList.find(d => d.key === order);
		return props.browserService.setOrderList(sessionName, generateOrderList(orderValue.propertyName, orderValue.ascending));
	};

	const updateItems = () => {
		return new Promise((resolve, reject) => {
			props.browserService.getList(sessionName, 0, 0).then(result => {
				setItems(result.data || []);
				resolve();
			});
		});
	};

	const handleRefresh = () => {
		setLoading(true);
		updateItems().then(() => setLoading(false));
	};

	return (
		<Dialog open={props.open} fullWidth maxWidth="lg" fullScreen={fullScreen} className={clsx(classes.filePicker)}>
			<DialogTitle disableTypography className="border-b">
				<div className="flex flex-row justify-between items-center">
					<div>
						<h2>Fájlböngésző</h2>
					</div>
					<div>
						{addNewAvailable ? (
							<Tooltip title="Feltöltés">
								<IconButton component="a" href={`#/fm-browser/edit/${directoryId || '0'}/${storageId}/new`} target="_blank" className="mr-12">
									<Icon className="text-20" color="action">
										add
									</Icon>
								</IconButton>
							</Tooltip>
						) : null}
						<Tooltip title="Frissítés">
							<IconButton onClick={() => handleRefresh()} className="mr-12">
								<Icon className="text-20" color="action">
									refresh
								</Icon>
							</IconButton>
						</Tooltip>
						<FormControl variant="outlined">
							<InputLabel id="file-picker-storage-label">Tároló</InputLabel>
							{storageList != null && storageId != null ? (
								<Select
									labelId="file-picker-storage-label"
									label="Tároló"
									value={storageId}
									defaultValue={null}
									onChange={e => {
										setDirectoryId(null);
										setStorageId(e.target.value);
									}}
									variant="outlined"
								>
									{storageList.map((item, i) => (
										<MenuItem key={item.id} value={item.id}>
											{item.fileName}
										</MenuItem>
									))}
								</Select>
							) : null}
						</FormControl>
					</div>
				</div>
				<div className="flex flex-row justify-between items-center pt-20">
					<TextField
						variant="outlined"
						label="Keresendő fájl..."
						className="flex-grow mr-16"
						value={queryText}
						onChange={e => setQueryText(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon className="text-20" color="action">
										search
									</Icon>
								</InputAdornment>
							),
							endAdornment:
								queryText.length > 0 ? (
									<InputAdornment position="end">
										<IconButton onClick={() => setQueryText('')}>
											<Icon className="text-20" color="action">
												clear
											</Icon>
										</IconButton>
									</InputAdornment>
								) : null
						}}
					/>
					<FormControl variant="outlined">
						<InputLabel id="file-picker-order-label">Rendezés</InputLabel>
						<Select labelId="file-picker-order-label" label="Rendezés" variant="outlined" value={order} onChange={e => setOrder(e.target.value)}>
							{orderValueList.map(orderItem => (
								<MenuItem key={orderItem.key} value={orderItem.key}>
									{t(orderItem.key)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DialogTitle>
			<DialogContent className="p-20">
				{!loading && items != null ? (
					<div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-16">
						{!isSearch && current && current.id ? <FilePickerDirectoryItem item={null} onChangeDirectory={() => setDirectoryId(current.parentDirectory ? current.parentDirectory.id : null)} /> : null}
						{items.map((item, ii) => getFilePickerItem(item))}
					</div>
				) : null}
				{!loading && (items == null || (items.length === 0 && directoryId === null)) ? (
					<div className="w-full flex justify-center">
						<Typography color="textSecondary">{isSearch ? t('NO_RESULTS') : t('NO_RESULTS_FILE')}</Typography>
					</div>
				) : null}
				{loading ? <FuseLoading /> : null}
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Typography>{current && current.parentStorage ? current.parentStorage.fileName + (isSearch ? ` - ${t('SEARCH_RESULTS')}` : current.fileName != null ? ` - ${current.fileName}` : ` - ${t('MAIN_FOLDER')}`) : ''}</Typography>
				<div>
					{!isSearch && props.directorySelectable && current && current.key ? (
						<Button onClick={() => props.onSelectDirectory(current)} color="secondary" variant="contained" className="sm:w-200 mr-20">
							{t('SELECT_FOLDER')}
						</Button>
					) : null}
					<Button onClick={() => props.onClose()} color="secondary" variant="contained" className="sm:w-200">
						Bezár
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

export default FilePickerDialog;
