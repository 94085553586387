import NamedDto from 'modules/base/dto/NamedDto';

export class CompetitionDistanceDetailsDto extends NamedDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.distance = null;
		/** @type {string} */
		this.levelTime = null;
		/** @type {boolean} */
		this.levelTimeByCycling = null;
		/** @type {string} */
		this.cyclingDistance = null;
		/** @type {string} */
		this.cyclingRound = null;
		/** @type {string} */
		this.cyclingLevelRise = null;
		/** @type {string} */
		this.swimmingDistance = null;
		/** @type {string} */
		this.swimmingRound = null;
		/** @type {string} */
		this.runningDistance = null;
		/** @type {string} */
		this.runningRound = null;
		/** @type {string} */
		this.runningLevelRise = null;
	}

	/**
	 * @typedef {Object} _CompetitionDistanceDetailsDtoProps
	 * @property {NamedDto} distance
	 * @property {string} levelTime
	 * @property {boolean} levelTimeByCycling
	 * @property {string} cyclingDistance
	 * @property {string} cyclingRound
	 * @property {string} cyclingLevelRise
	 * @property {string} swimmingDistance
	 * @property {string} swimmingRound
	 * @property {string} runningDistance
	 * @property {string} runningRound
	 * @property {string} runningLevelRise
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _CompetitionDistanceDetailsDtoProps} CompetitionDistanceDetailsDtoProps
	 */
	/**
	 *
	 * @param {CompetitionDistanceDetailsDtoProps} props
	 * @returns {CompetitionDistanceDetailsDto}
	 */
	setup(props) {
		super.setup(props);
		this.distance = props.distance != null ? new NamedDto().setup(props.distance) : null;
		this.levelTime = props.levelTime;
		this.levelTimeByCycling = props.levelTimeByCycling != null ? props.levelTimeByCycling : false;
		this.cyclingDistance = props.cyclingDistance;
		this.cyclingRound = props.cyclingRound;
		this.cyclingLevelRise = props.cyclingLevelRise;
		this.swimmingDistance = props.swimmingDistance;
		this.swimmingRound = props.swimmingRound;
		this.runningDistance = props.runningDistance;
		this.runningRound = props.runningRound;
		this.runningLevelRise = props.runningLevelRise;
		return this;
	}
}

export default CompetitionDistanceDetailsDto;
