import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import { showMessage } from 'app/store/fuse/messageSlice';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import EditFormTableBrowserField from 'modules/ui/editform/fields/EditFormTableBrowserField';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import userPaymentTableService from 'app/admin/payment/service/UserPaymentTableService';
import PaymentTableCell from 'app/admin/payment/components/PaymentTableCell';
import DownloadInvoiceTableButton from 'app/admin/payment/components/DownloadInvoiceTableButton';
import ChangePaymentTableButton from 'app/admin/payment/components/ChangePaymentTableButton';
import UserLogTableService from 'app/admin/user/service/UserLogTableService';
import OrganizationEditService from '../service/OrganizationEditService';

const userLogTableConfig = (data, dto) => {
	return {
		key: 'orgUserLogTable',
		text: 'Naplók',
		textKey: 'orgUserLogTable',
		withToolbar: true,
		hideTitle: true,
		service: UserLogTableService,
		toolbarAction: ['search', 'refresh'],
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'id',
						type: 'EQ',
						value: dto.id,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		columns: [
			{
				key: 'logDate',
				text: 'Dátum',
				textKey: 'logDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'modifierUserName',
				text: 'Módosító felhasználó',
				textKey: 'modifierUserName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'logCategory',
				text: 'Kategória',
				textKey: 'logCategory',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'message',
				text: 'Bejegyzés',
				textKey: 'message',
				type: 'text',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

const userPaymentTableConfig = (data, dto) => {
	return {
		key: 'orgUserPaymentTable',
		text: 'Befizetések',
		textKey: 'orgUserPaymentTable',
		withToolbar: true,
		hideTitle: true,
		service: userPaymentTableService,
		toolbarAction: ['search', 'refresh'],
		rowButtonList: [
			...(data.isSuperAdmin
				? [
						{
							key: 'changePayment',
							component: ChangePaymentTableButton
						}
				  ]
				: []),
			...[
				{
					key: 'downloadInvoice',
					component: DownloadInvoiceTableButton
				}
			]
		],
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'user.id',
						type: 'EQ',
						value: dto.id,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		columns: [
			{
				key: 'billingDate',
				text: 'Számlázás dátuma',
				textKey: 'billingDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'description',
				text: 'Megnevezés',
				textKey: 'description',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payableNet',
				text: 'Nettó fizetendő',
				textKey: 'payableNet',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payableGross',
				text: 'Bruttó fizetendő',
				textKey: 'payableGross',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'paymentStatus',
				text: 'Státusz',
				textKey: 'paymentStatus',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'paymentDate',
				text: 'Befizetés dátuma',
				textKey: 'paymentDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payment',
				text: 'Fizetés',
				component: PaymentTableCell,
				sortable: false,
				disablePadding: false
			}
		]
	};
};

const config = data => {
	const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
	const blockTitleClassName = 'py-8';

	return {
		key: 'organizationEditForm',
		text: data.titleText,
		service: OrganizationEditService,
		icon: 'person',
		watchTitleKey: 'shortName',
		editableAccess: data.formData.editableAccess,
		editableFields: data.formData.editableFields,
		noNavigationAfterSave: data.type === 'profile',
		hideBackLink: data.type === 'profile',
		backLink: () => `/${data.type}`,
		tabList: initData => {
			const tabList = [
				{
					key: 'basic',
					text: 'Adatok',
					textKey: 'basic'
				}
			];
			if (initData.id !== null) {
				tabList.push({
					key: 'userPayment',
					text: 'Befizetések',
					textKey: 'userPayment'
				});
			}
			if (data.isSuperAdmin) {
				tabList.push({
					key: 'userLog',
					text: 'Naplók',
					textKey: 'userLog'
				});
			}
			return tabList;
		},
		fields: initData => {
			const fields = [
				{
					key: 'typeGroup',
					component: EditFormGroupFields,
					className: 'w-full flex flex-col md:flex-row rounded-8 border px-16 pt-10 pb-4 mb-16',
					fields: [
						{
							key: 'isClub',
							text: 'Klub',
							textKey: 'isClub',
							component: EditFormCheckboxField
						},
						{
							key: 'isOrganizer',
							text: 'Versenyszervező',
							textKey: 'isOrganizer',
							component: EditFormCheckboxField
						}
					]
				},
				{
					key: 'adultSupplyGroup',
					component: EditFormGroupFields,
					className: 'w-full flex flex-col md:flex-row rounded-8 border px-16 pt-10 pb-4 mb-16',
					dependsOn: 'isClub',
					visibleIf: isClub => isClub,
					fields: [
						{
							key: 'isAdult',
							text: 'Felnőtt',
							textKey: 'isAdult',
							component: EditFormCheckboxField
						},
						{
							key: 'isSupply',
							text: 'Utánpótlás',
							textKey: 'isSupply',
							component: EditFormCheckboxField
						}
					]
				},
				{
					key: 'userStatus',
					text: 'Státusz',
					textKey: 'userStatus',
					component: EditFormSelectField,
					items: data.formData.userStatuses,
					type: 'enum',
					required: true,
					visibleIf: () => data.isSuperAdmin
				},
				{
					key: 'fullName',
					text: 'Egyesület neve',
					textKey: 'fullName',
					maxLength: 255,
					component: EditFormTextField,
					required: true
				},
				{
					key: 'shortName',
					text: 'Egyesület rövid neve',
					textKey: 'shortName',
					maxLength: 180,
					component: EditFormTextField,
					required: true
				},
				{
					key: 'loginGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepLogin',
							text: 'Belépési adatok',
							textKey: 'sepLoigin',
							component: EditFormSeparatorField,
							icon: 'lock',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'email',
							text: 'E-mail',
							textKey: 'email',
							component: EditFormTextField,
							maxLength: 150,
							required: true
						},
						{
							key: 'passwordGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col sm:flex-row',
							fields: [
								{
									key: 'password',
									text: 'Jelszó',
									textKey: 'password',
									className: 'sm:mr-6',
									component: EditFormTextField,
									required: false,
									type: 'password',
									autoComplete: 'new-password',
									maxLength: 50
								},
								{
									key: 'passwordAgain',
									text: 'Jelszó mégegyszer',
									textKey: 'passwordAgain',
									className: 'sm:ml-6',
									component: EditFormTextField,
									required: false,
									type: 'password',
									autoComplete: 'new-password',
									maxLength: 50
								}
							]
						}
					]
				},
				{
					key: 'fullAddressGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepAddress',
							text: 'Egyesület cím',
							textKey: 'sepAddress',
							component: EditFormSeparatorField,
							icon: 'place',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'addressGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col md:flex-row',
							fields: [
								{
									key: 'addressZipcode',
									text: 'Irányítószám',
									textKey: 'addressZipcode',
									component: EditFormTextField,
									className: 'w-full md:w-1/5 md:mr-12',
									maxLength: 16,
									required: true
								},
								{
									key: 'addressCity',
									text: 'Város',
									textKey: 'addressCity',
									component: EditFormTextField,
									className: 'w-full md:w-2/5 md:mr-12',
									maxLength: 40,
									required: true
								},
								{
									key: 'addressCounty',
									text: 'Megye',
									textKey: 'addressCounty',
									component: EditFormSelectField,
									items: data.formData.counties,
									className: 'w-full md:w-2/5',
									required: true
								}
							]
						},
						{
							key: 'addressStreetGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col md:flex-row',
							fields: [
								{
									key: 'addressStreet',
									text: 'Utca',
									textKey: 'addressStreet',
									component: EditFormTextField,
									className: 'w-full md:w-4/5 md:mr-12',
									maxLength: 100,
									required: true
								},
								{
									key: 'addressStreetType',
									text: 'Típus',
									textKey: 'addressStreetType',
									component: EditFormSelectField,
									items: data.formData.publicPlaceTypes,
									className: 'w-full md:w-1/5',
									required: true
								}
							]
						},
						{
							key: 'addressNumberGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-row',
							fields: [
								{
									key: 'addressNumber',
									text: 'Házszám',
									textKey: 'addressNumber',
									component: EditFormTextField,
									className: 'w-1/3 mr-12',
									maxLength: 50,
									required: true
								},
								{
									key: 'addressFloor',
									text: 'Emelet',
									textKey: 'addressFloor',
									component: EditFormTextField,
									maxLength: 10,
									className: 'w-1/3 mr-12'
								},
								{
									key: 'addressDoor',
									text: 'Ajtó',
									textKey: 'addressDoor',
									component: EditFormTextField,
									maxLength: 10,
									className: 'w-1/3'
								}
							]
						}
					]
				},
				{
					key: 'fullPresidentGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepPresident',
							text: 'Elnök adatai',
							textKey: 'sepPresitent',
							component: EditFormSeparatorField,
							icon: 'person',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'presidentGroup',
							component: EditFormGroupFields,
							fields: [
								{
									key: 'presidentNameGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'president.lastName',
											text: 'Vezetéknév',
											textKey: 'contact.lastName',
											className: 'sm:mr-6',
											maxLength: 50,
											component: EditFormTextField,
											required: true,
											autoCapitalize: 'capitalize'
										},
										{
											key: 'president.firstName',
											text: 'Keresztnév',
											textKey: 'contact.firstName',
											className: 'sm:ml-6',
											maxLength: 50,
											component: EditFormTextField,
											required: true,
											autoCapitalize: 'capitalize'
										}
									]
								},
								{
									key: 'president.phoneNumber',
									text: 'Telefonszám',
									textKey: 'president.phoneNumber',
									component: EditFormTextField,
									mask: {
										mask: '+0000000000000'
									},
									required: true
								},
								{
									key: 'president.email',
									text: 'E-mail',
									textKey: 'president.email',
									component: EditFormTextField,
									maxLength: 150,
									required: true
								}
							]
						}
					]
				},
				{
					key: 'fullContactGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepContact',
							text: 'Kapcsolattartó adatai',
							textKey: 'sepContact',
							component: EditFormSeparatorField,
							icon: 'phone',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'contactGroup',
							component: EditFormGroupFields,
							fields: [
								{
									key: 'contactNameGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'contact.lastName',
											text: 'Vezetéknév',
											textKey: 'contact.lastName',
											className: 'sm:mr-6',
											component: EditFormTextField,
											required: true,
											autoCapitalize: 'capitalize'
										},
										{
											key: 'contact.firstName',
											text: 'Keresztnév',
											textKey: 'contact.firstName',
											className: 'sm:ml-6',
											component: EditFormTextField,
											required: true,
											autoCapitalize: 'capitalize'
										}
									]
								},
								{
									key: 'contact.email',
									text: 'E-mail',
									textKey: 'contact.email',
									component: EditFormTextField,
									required: true
								},
								{
									key: 'contact.phoneNumber',
									text: 'Telefonszám',
									textKey: 'contact.phoneNumber',
									mask: {
										mask: '+0000000000000'
									},
									component: EditFormTextField,
									required: true
								}
							]
						}
					]
				},
				{
					key: 'fullBillingGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepBilling',
							text: 'Számlázási adatok',
							textKey: 'sepContact',
							component: EditFormSeparatorField,
							icon: 'receipt',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'billingGroup',
							component: EditFormGroupFields,
							fields: [
								{
									key: 'taxNumber',
									text: 'Adószám',
									textKey: 'taxNumber',
									component: EditFormTextField,
									mask: {
										mask: '00000000-0-00'
									},
									maxLength: 15,
									required: true
								},
								{
									key: 'bankAccountNumber',
									text: 'Egyesületi bankszámla',
									textKey: 'bankAccountNumber',
									mask: {
										mask: '00000000-00000000-00000000'
									},
									maxLength: 30,
									component: EditFormTextField,
									required: true
								},
								{
									key: 'billingName',
									text: 'Számlázási név',
									textKey: 'billingName',
									component: EditFormTextField,
									maxLength: 150,
									required: true
								},
								{
									key: 'bankName',
									text: 'Számlavezető bank',
									textKey: 'bankName',
									maxLength: 150,
									component: EditFormTextField,
									required: true
								},
								{
									key: 'billingAccountNumber',
									text: 'Számlázási számlaszám',
									textKey: 'billingAccountNumber',
									mask: {
										mask: '00000000-00000000-00000000'
									},
									component: EditFormTextField,
									required: true
								},
								{
									key: 'sepClubBillingAddress',
									text: 'Számlázási cím',
									textKey: 'sepClubBillingAddress',
									component: EditFormSeparatorField,
									icon: 'place',
									className: blockTitleClassName,
									isControlledInput: false
								},
								{
									key: 'billingAddressGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col md:flex-row',
									fields: [
										{
											key: 'billingZipcode',
											text: 'Ir.szám',
											textKey: 'billingZipcode',
											component: EditFormTextField,
											maxLength: 16,
											className: 'w-full md:w-1/5 md:mr-12',
											required: true
										},
										{
											key: 'billingCity',
											text: 'Település',
											textKey: 'billingCity',
											component: EditFormTextField,
											maxLength: 40,
											className: 'w-full md:w-2/5 md:mr-12',
											required: true
										},
										{
											key: 'billingCounty',
											text: 'Megye',
											textKey: 'billingCounty',
											component: EditFormSelectField,
											items: data.formData.counties,
											className: 'w-full md:w-2/5',
											required: true
										}
									]
								},
								{
									key: 'billingAddressStreetGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col md:flex-row',
									fields: [
										{
											key: 'billingStreet',
											text: 'Közterület megnevezése',
											textKey: 'billingStreet',
											component: EditFormTextField,
											maxLength: 100,
											className: 'w-full md:w-4/5 md:mr-12',
											required: true
										},
										{
											key: 'billingStreetType',
											text: 'Közterület típusa',
											textKey: 'billingStreetType',
											component: EditFormSelectField,
											items: data.formData.publicPlaceTypes,
											className: 'w-full md:w-1/5',
											required: true
										}
									]
								},
								{
									key: 'billingAddressNumberGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-row',
									fields: [
										{
											key: 'billingNumber',
											text: 'Házszám',
											textKey: 'billingNumber',
											component: EditFormTextField,
											maxLength: 50,
											className: 'w-1/3 mr-12',
											required: true
										},
										{
											key: 'billingFloor',
											text: 'Emelet',
											textKey: 'billingFloor',
											component: EditFormTextField,
											maxLength: 10,
											className: 'w-1/3 mr-12'
										},
										{
											key: 'billingDoor',
											text: 'Ajtó',
											textKey: 'billingDoor',
											component: EditFormTextField,
											className: 'w-1/3'
										}
									]
								}
							]
						}
					]
				},
				{
					key: 'fullEconomicLeaderGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepEconomicLeader',
							text: 'Gazdasági vezető adatai',
							textKey: 'sepEconomicLeader',
							component: EditFormSeparatorField,
							icon: 'account_balance_wallet',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'economicLeaderGroup',
							component: EditFormGroupFields,
							fields: [
								{
									key: 'economicLeaderNameGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'economicLeader.lastName',
											text: 'Vezetéknév',
											textKey: 'economicLeader.lastName',
											className: 'sm:mr-6',
											maxLength: 50,
											component: EditFormTextField,
											required: true,
											autoCapitalize: 'capitalize'
										},
										{
											key: 'economicLeader.firstName',
											text: 'Keresztnév',
											textKey: 'economicLeader.firstName',
											className: 'sm:ml-6',
											maxLength: 50,
											component: EditFormTextField,
											required: true,
											autoCapitalize: 'capitalize'
										}
									]
								},
								{
									key: 'economicLeader.email',
									text: 'E-mail cím',
									textKey: 'economicLeader.email',
									component: EditFormTextField,
									maxLength: 150,
									required: true
								},
								{
									key: 'economicLeader.phoneNumber',
									text: 'Telefonszám',
									textKey: 'economicLeader.phoneNumber',
									component: EditFormTextField,
									maxLength: 50,
									mask: {
										mask: '+0000000000000'
									},
									required: true
								}
							]
						}
					]
				},
				{
					key: 'dataSheetDocument',
					text: 'Tagszervezeti adatlap',
					textKey: 'dataSheetDocument',
					component: EditFormFileInputField,
					required: false
				},
				{
					key: 'socialMediaGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepSocialMedia',
							text: 'Közösségi média',
							textKey: 'sepSocialMedia',
							component: EditFormSeparatorField,
							icon: 'people',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'socialMediaGroupInner',
							component: EditFormGroupFields,
							fields: [
								{
									key: 'economicLeaderNameGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'fbLink',
											text: 'Facebook',
											textKey: 'fbLink',
											className: 'sm:mr-6',
											maxLength: 1000,
											component: EditFormTextField
										},
										{
											key: 'instaLink',
											text: 'Instagram',
											textKey: 'instaLink',
											className: 'sm:ml-6',
											maxLength: 1000,
											component: EditFormTextField
										}
									]
								}
							]
						}
					]
				},
				{
					key: 'accept',
					text: 'A Magyar Triatlon Szövetség alapszabályzatát megismertem, tartalmát elfogadom, és magamra kötelező érvényűnek tekintem.',
					textKey: 'accept',
					component: EditFormCheckboxField,
					required: true
				}
			];
			if (initData.id !== null) {
				fields.push({
					key: getSessionString(),
					text: 'Befizetések',
					textKey: 'userPayment',
					tab: 'userPayment',
					component: EditFormTableBrowserField,
					config: v => userPaymentTableConfig(data, initData),
					isControlledInput: false
				});
			}
			if (data.isSuperAdmin) {
				fields.push({
					key: getSessionString(),
					text: 'Naplók',
					textKey: 'userLog',
					tab: 'userLog',
					component: EditFormTableBrowserField,
					config: v => userLogTableConfig(data, initData),
					isControlledInput: false
				});
			}
			return fields;
		}
	};
};

function OrganizationEditForm(props) {
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(null);
	const [showProfileValidationProblem, setShowProfileValidationProblem] = useState(false);
	const { id } = useParams();
	const user = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		if (showProfileValidationProblem) dispatch(showMessage({ message: 'A profilodon hiányosságok vannak. Kérlek pótold azokat.' }));
	}, [showProfileValidationProblem]);

	if (formData == null) {
		if (id !== 'new') {
			OrganizationEditService.getFormDataById(id).then(result => setFormData(result));
		} else {
			OrganizationEditService.getFormData().then(result => setFormData(result));
		}
		return <></>;
	}
	let titleText = '';
	if (props.type === 'organizer') titleText = 'Versenyszervező';
	else if (props.type === 'club') titleText = 'Klub';
	else if (props.type === 'profile') titleText = 'Profilom';

	const myConfig = config({ formData, type: props.type, isSuperAdmin: user.isSuperAdmin, titleText });

	const handleDataChange = (data, methods) => {
		if (props.type === 'profile') {
			OrganizationEditService.validate(data).then(response => {
				if (!response.successful) {
					setShowProfileValidationProblem(true);
					Object.keys(response.properties).forEach(key => {
						if (response.properties[key].length > 0) {
							methods.setError(key, {
								type: 'manual',
								message: response.properties[key][0].message
							});
						}
					});
				}
			});
		}
	};

	return <EditFormPage config={myConfig} onDataChanged={(data, methods) => handleDataChange(data, methods)} />;
}

export default withRouter(withReducer('orgUserPaymentTable', reducer('orgUserPaymentTable'))(withReducer('orgUserLogTable', reducer('orgUserLogTable'))(OrganizationEditForm)));
