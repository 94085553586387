import _ from '@lodash';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Button, Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EditFormSubItem from './EditFormSubItem';
import { determineEditableAccess } from '../EditFormUtils';

function EditFormSubComponent(props) {
	const { control, setValue, getValues } = useFormContext();
	const { t } = useTranslation('editform');
	const dtoPathWithKey = (!_.isUndefined(props.fieldPrefix) ? props.fieldPrefix : '') + props.fieldConfig.key;
	const { fields, append, remove } = useFieldArray({
		control,
		name: dtoPathWithKey,
		keyName: '_id'
	});
	const SubItemComponent = props.fieldConfig.subItemComponent || EditFormSubItem;
	const [selectedToMoveBase, setSelectedToMoveBase] = useState(null);
	const [selectedToMoveTarget, setSelectedToMoveTarget] = useState(null);
	const [addReference, setAddReference] = useState(null);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const fixedLengthList = props.fieldConfig.fixedLengthList || false;

	const checkListOrderOk = () => {
		for (let pos = 0; pos < fields.length; pos += 1) {
			if (fields[pos].orderIndex === null || fields[pos].orderIndex !== pos) {
				return false;
			}
		}
		return true;
	};

	const reorderList = () => {
		const _fields = fields.sort((firstField, secondField) => {
			if (firstField.orderIndex !== null && secondField.orderIndex !== null) {
				if (firstField.orderIndex > secondField.orderIndex) return 1;
				if (firstField.orderIndex < secondField.orderIndex) return -1;
				return 0;
			}
			return 0;
		});
		for (let pos = 0; pos < _fields.length; pos += 1) {
			_fields[pos].orderIndex = pos;
		}
		setValue(dtoPathWithKey, _fields);
	};

	useEffect(() => {
		if (props.fieldConfig.orderable) {
			if (!checkListOrderOk()) reorderList();
		}
	}, [fields]);

	useEffect(() => {
		if (addReference != null) {
			if (selectedToMoveBase != null) setSelectedToMoveBase(null);
			if (selectedToMoveTarget != null) setSelectedToMoveTarget(null);
		}
	}, [addReference]);

	useEffect(() => {
		if (selectedToMoveBase != null && addReference != null) setAddReference(null);
	}, [selectedToMoveBase]);

	const handleSelectedToMove = _id => {
		if (selectedToMoveBase == null) {
			setSelectedToMoveBase(_id);
		} else if (_id === selectedToMoveBase) {
			setSelectedToMoveBase(null);
			setSelectedToMoveTarget(null);
		} else if (_id === selectedToMoveTarget) {
			setSelectedToMoveTarget(null);
		} else {
			setSelectedToMoveTarget(_id);
		}
	};

	const move = direction => {
		const positions = [];
		let movedFieldPos = null;
		let targetFieldPos = null;
		const _fields = getValues(dtoPathWithKey).map((f, i) => {
			f._id = fields[i]._id;
			return f;
		});
		_fields.forEach(f => {
			positions[f.orderIndex] = f._id;
			if (f._id === selectedToMoveBase) movedFieldPos = f.orderIndex;
			if (f._id === selectedToMoveTarget) targetFieldPos = f.orderIndex;
		});
		if (!((direction > 0 && targetFieldPos === movedFieldPos - 1) || (direction < 0 && movedFieldPos === targetFieldPos - 1))) {
			positions.splice(movedFieldPos, 1);
			const toIndex = direction > 0 ? positions.indexOf(selectedToMoveTarget) + 1 : positions.indexOf(selectedToMoveTarget);
			positions.splice(toIndex, 0, selectedToMoveBase);
			setValue(
				dtoPathWithKey,
				_fields.map(f => {
					f.orderIndex = positions.indexOf(f._id);
					return f;
				})
			);
		}
		moveFinished();
	};

	const handleSelectedToAdd = _id => {
		setAddReference(addReference !== null && addReference === _id ? null : _id);
	};

	const add = (_id, direction) => {
		const _fields = getValues(dtoPathWithKey).map((f, i) => {
			f._id = fields[i]._id;
			return f;
		});

		const pos = _fields.findIndex(field => field._id === _id);
		const newValue = _.isFunction(props.fieldConfig.defaultValue) ? props.fieldConfig.defaultValue() : props.fieldConfig.defaultValue;
		const newPos = direction < 0 ? pos : pos + 1;
		newValue.orderIndex = newPos;
		setValue(
			dtoPathWithKey,
			_fields.map(f => {
				f.orderIndex = f.orderIndex >= newPos ? f.orderIndex + 1 : f.orderIndex;
				return f;
			})
		);
		append(newValue);
		if (addReference != null) setAddReference(null);
	};

	const moveFinished = () => {
		if (selectedToMoveBase != null) setSelectedToMoveBase(null);
		if (selectedToMoveTarget != null) setSelectedToMoveTarget(null);
		if (addReference != null) setAddReference(null);
	};

	return (
		<motion.div className="flex-1 overflow-y-auto">
			{props.fieldConfig.showTitle || false ? <div className="mt-8 mb-16 font-medium">{props.fieldConfig.text}</div> : null}
			{fields
				? fields.map((f, i) => (
						<SubItemComponent
							key={f._id}
							index={i}
							config={props.config}
							fieldConfig={props.fieldConfig}
							field={f}
							fieldPrefix={`${!_.isUndefined(props.fieldPrefix) ? props.fieldPrefix : ''}${props.fieldConfig.key}.${i}.`}
							onDelete={() => remove(i)}
							selectedToMoveBase={selectedToMoveBase && selectedToMoveBase === f._id}
							selectedToMoveTarget={selectedToMoveTarget && selectedToMoveTarget === f._id}
							addReference={f._id === addReference}
							onSelectToMove={() => handleSelectedToMove(f._id)}
							onSelectToAdd={() => handleSelectedToAdd(f._id)}
							onMove={direction => move(direction)}
							onAdd={direction => add(f._id, direction)}
							readOnly={readOnly}
							fixedLengthList={fixedLengthList}
						/>
				  ))
				: null}

			{!readOnly && !fixedLengthList ? (
				<div className="py-24 flex justify-end">
					<Button
						className="whitespace-nowrap mx-4"
						variant="contained"
						color="secondary"
						onClick={() => {
							if (props.fieldConfig.orderable) {
								setAddReference(null);
							}
							append(_.isFunction(props.fieldConfig.defaultValue) ? props.fieldConfig.defaultValue() : props.fieldConfig.defaultValue);
						}}
						startIcon={<Icon className="hidden sm:flex">add</Icon>}
					>
						{props.fieldConfig.newButtonText || t('SUB_ADD_NEW')}
					</Button>
				</div>
			) : null}
		</motion.div>
	);
}

export default EditFormSubComponent;
