import { authRoles } from 'app/auth';
import OrganizerTable from './table/OrganizerTable';
import ClubTable from './table/ClubTable';
import OrganizationEditForm from './editform/OrganizationEditForm';

export default [
	{
		path: '/organizer/edit/:id',
		component: () => <OrganizationEditForm type="organizer" />,
		auth: authRoles.organizer
	},
	{
		path: '/organizer/:sessionNameParam?',
		component: OrganizerTable,
		auth: authRoles.organizer
	},
	{
		path: '/club/edit/:id',
		component: () => <OrganizationEditForm type="club" />,
		auth: authRoles.club
	},
	{
		path: '/club/:sessionNameParam?',
		component: ClubTable,
		auth: authRoles.club
	}
];
