import OrderableNamedParentEditDto from 'app/admin/valueset/dto/OrderableNamedParentEditDto';

class AgeGroupEditDto extends OrderableNamedParentEditDto {
	constructor() {
		super();
		/** @type {number} */
		this.ageMin = null;
		/** @type {number} */
		this.ageMax = null;
		/** @type {boolean} */
		this.child = null;
	}

	setup(props) {
		super.setup(props);
		this.ageMin = props.ageMin;
		this.ageMax = props.ageMax;
		this.child = props.child != null ? props.child : false;
		return this;
	}
}

export default AgeGroupEditDto;
