import _ from '@lodash';
import { FormControl, Checkbox, FormHelperText, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import clsx from 'clsx';
import { determineEditableAccess } from '../EditFormUtils';

function EditFormCheckboxField(props) {
	const methods = useFormContext();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { value, onChange } = props.field;
	const { error } = props.fieldState;
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));

	return _.isUndefined(value) ? (
		<></>
	) : (
		<FormControl error={error} className={clsx('w-full mt-8 mb-16', props.fieldConfig.className)}>
			<FormControlLabel
				control={
					<Checkbox
						checked={value}
						onChange={e => {
							onChange(e.target.checked);
							!_.isUndefined(props.fieldConfig.onChange) ? props.fieldConfig.onChange(e.target.checked, props.field, methods) : null;
						}}
						inputProps={{ 'aria-label': 'primary checkbox', disabled: readOnly }}
						id={props.field.name}
					/>
				}
				label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
			/>
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export default EditFormCheckboxField;
