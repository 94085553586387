import { authRoles } from 'app/auth';
import ContestEditForm from './editform/ContestEditForm';
import ContestTable from './table/ContestTable';

export default [
	{
		path: '/contest/edit/:id',
		component: ContestEditForm,
		auth: authRoles.contest
	},
	{
		path: '/contest/:sessionNameParam?',
		component: ContestTable,
		auth: authRoles.contest
	}
];
