import AccessConfigRoleDto from './AccessConfigRoleDto';
import AccessConfigUserDto from './AccessConfigUserDto';

export default class AccessConfigDto {
	constructor() {
		/** @type {AccessConfigUserDto} */
		this.user = null;
		/** @type {AccessConfigRoleDto[]} */
		this.roleList = null;
		/** @type {string[]} */
		this.serviceList = null;
		/** @type {Object} */
		this.settingMap = null;
		/** @type {boolean} */
		this.isSuperAdmin = null;
		/** @type {string} */
		this.accessType = null;
		/** @type {boolean} */
		this.isAthlete = null;
		/** @type {boolean} */
		this.isCompetitor = null;
		/** @type {boolean} */
		this.isReferee = null;
		/** @type {boolean} */
		this.isTrainer = null;
		/** @type {boolean} */
		this.isClub = null;
		/** @type {boolean} */
		this.isOrganizer = null;
		/** @type {boolean} */
		this.isTechnicalCommittee = null;
		/** @type {string} */
		this.athleteRegType = null;
	}

	/**
	 * @typedef {Object} _AccessConfigDtoProps
	 * @property {AccessConfigUserDto} user
	 * @property {AccessConfigRoleDto[]} roleList
	 * @property {string[]} serviceList
	 * @property {Object} settingMap
	 * @property {boolean} isSuperAdmin
	 * @property {string} accessType
	 * @property {boolean} isAthlete
	 * @property {boolean} isCompetitor
	 * @property {boolean} isReferee
	 * @property {boolean} isTrainer
	 * @property {boolean} isClub
	 * @property {boolean} isOrganizer
	 * @property {boolean} isTechnicalCommittee
	 * @property {string} athleteRegType
	 *
	 * @typedef {_AccessConfigDtoProps} AccessConfigDtoProps
	 */
	/**
	 *
	 * @param {AccessConfigDtoProps} props
	 * @returns {AccessConfigDto}
	 */
	setup(props) {
		if (props === null) return null;
		this.user = props.user !== null ? new AccessConfigUserDto().setup(props.user) : null;
		this.roleList = props.roleList !== null ? props.roleList.map(d => new AccessConfigRoleDto().setup(d)) : [];
		this.serviceList = props.serviceList ? props.serviceList : [];
		this.settingMap = props.settingMap;
		this.isSuperAdmin = props.isSuperAdmin;
		this.accessType = props.accessType;
		this.isAthlete = props.isAthlete;
		this.isCompetitor = props.isCompetitor;
		this.isReferee = props.isReferee;
		this.isClub = props.isClub;
		this.isOrganizer = props.isOrganizer;
		this.isTechnicalCommittee = props.isTechnicalCommittee;
		this.athleteRegType = props.athleteRegType;
		return this;
	}
}
