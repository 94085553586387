import TechnicalCommitteeTable from 'app/admin/person/table/TechnicalCommitteeTable';
import NamedDto from 'modules/base/dto/NamedDto';
import ApprovalType from './ApprovalType';
import TechnicalApprovalType from './TechnicalApprovalType';

export class CompetitionTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {Date} */
		this.date = null;
		/** @type {string} */
		this.orgName = null;
		/** @type {string} */
		this.adminApproval = null;
		/** @type {string} */
		this.technicalCommitteeApproval = null;
		/** @type {boolean} */
		this.submitted = null;
		/** @type {boolean} */
		this.approved = null;
	}

	/**
	 * @typedef {Object} _CompetitionEditDtoProps
	 * @property {Date} date
	 * @property {string} orgName
	 * @property {string} adminApproval
	 * @property {string} technicalCommitteeApproval
	 * @property {boolean} submitted
	 * @property {boolean} approved
	 * @typedef {import("../NamedDto").NamedDtoProps & _CompetitionEditDtoProps} CompetitionEditDtoProps
	 */
	/**
	 *
	 * @param {CompetitionEditDtoProps} props
	 * @returns {CompetitionEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.date = props.date !== null ? new Date(props.date) : null;
		this.orgName = props.orgName;
		this.adminApproval = props.adminApproval;
		this.technicalCommitteeApproval = props.technicalCommitteeApproval;
		this.submitted = props.submitted != null ? props.submitted : false;
		this.approved = props.approved != null ? props.approved : false;
		return this;
	}

	getAsString(key) {
		if (key === 'adminApproval') {
			return this.adminApproval != null ? ApprovalType[this.adminApproval] : '';
		}
		if (key === 'technicalCommitteeApproval') {
			return this.technicalCommitteeApproval != null ? TechnicalApprovalType[this.technicalCommitteeApproval] : '';
		}
		return super.getAsString(key);
	}
}

export default CompetitionTableDto;
