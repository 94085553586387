import nBossConnection from 'modules/base/service/nBossConnection';
import CompetitionCalendarDetailsDto from '../dto/CompetitionCalendarDetailsDto';
import CompetitionCalendarListDto from '../dto/CompetitionCalendarListDto';
import CompetitionDetailsDto from '../dto/CompetitionDetailsDto';
import CompetitionEntryInfoDto from '../dto/CompetitionEntryInfoDto';

class CompetitionService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'competitionControl';
	}

	/**
	 * @return {Promise<CompetitionCalendarListDto[]>}
	 */
	getCalendarList() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getCalendarList`)
				.then(response => resolve(response.data !== null ? response.data.map(d => new CompetitionCalendarListDto().setup(d)) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<CompetitionCalendarDetailsDto>}
	 */
	getCalendarDetails(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getCalendarDetails`, { id })
				.then(response => resolve(response.data !== null ? new CompetitionCalendarDetailsDto().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<CompetitionDetailsDto>}
	 */
	getDetails(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getDetails`, {
					id
				})
				.then(response => resolve(new CompetitionDetailsDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competitionId
	 * @return {Promise<CompetitionEntryInfoDto[]>}
	 */
	getCompetitionEntryInfoList(competitionId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getCompetitionEntryInfoList`, {
					competitionId
				})
				.then(response => resolve(response.data != null ? response.data.map(d => new CompetitionEntryInfoDto().setup(d)) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<CompetitionCalendarListDto>}
	 */
	getInfo(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getInfo`, { id })
				.then(response => resolve(response.data != null ? new CompetitionCalendarListDto().setup(response.data) : null))
				.catch(reject);
		});
	}
}

export default new CompetitionService();
