import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';
import NamedDto from 'modules/base/dto/NamedDto';
import PersonEditDto from '../dto/PersonEditDto';
import PersonStatusDto from '../dto/PersonStatusDto';

class PersonEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'personEditControl';
	}

	/**
	 * @return {Promise<PersonEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new PersonEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<PersonEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new PersonEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {PersonEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {PersonEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Map<String, NamedDto[]}
	 */
	getFormData() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getFormData`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Map<String, NamedDto[]}
	 */
	getFormDataById(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getFormData`, { id })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {PersonEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	validate(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/validate`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<PersonStatusDto>}
	 */
	getStatus(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getStatus`, {
					id
				})
				.then(response => resolve(new PersonStatusDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<boolean>}
	 */
	checkPaymentIsPossible(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/checkPaymentIsPossible`, {
					id
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new PersonEditService();
