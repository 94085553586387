import { changeLanguage } from 'app/store/i18nSlice';
import { useDispatch } from 'react-redux';
import _ from '@lodash';
import nBossConnection from 'modules/base/service/nBossConnection';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormImageInputField from 'modules/ui/editform/fields/EditFormImageInputField';
import RegistrationFormPage from './RegistrationFormPage';
import PersonRegistrationService from './service/PersonRegistrationService';
import EnumEditFormCheckboxesField from './component/EnumEditFormCheckboxesField';

const config = data => {
	const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
	const blockTitleClassName = 'py-8';

	return {
		key: 'PersonRegistrationForm',
		text: data.t('athleteRegTitle'),
		textKey: 'PersonRegistrationForm',
		topic: 'reg',
		service: PersonRegistrationService,
		defaultValue: () => PersonRegistrationService.createAthleteReg(data.type),
		fields: [
			...(data.type !== 'reg_hu'
				? [
						{
							key: 'athleteRegTypeGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col md:flex-row rounded-8 border px-16 pt-10 pb-4 mb-16',
							fields: [
								{
									key: 'athleteRegType',
									component: EnumEditFormCheckboxesField,
									className: ' flex flex-row justify-between',
									onChange: v => {
										data.dispatch(changeLanguage(v != null && v === 'REG_EN' ? 'en' : 'hu'));
									},

									items: [
										{ label: 'Licensz kiváltás', value: 'LICENSE_REDEEM' },
										{ label: 'Registration', value: 'REG_EN' }
									]
								}
							]
						}
				  ]
				: []),
			{
				key: 'nameGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col sm:flex-row',
				fields: [
					{
						key: 'lastName',
						textKey: 'lastName',
						className: 'sm:mr-6',
						component: EditFormTextField,
						maxLength: 50,
						required: true,
						autoCapitalize: 'capitalize'
					},
					{
						key: 'firstName',
						textKey: 'firstName',
						className: 'sm:ml-6',
						component: EditFormTextField,
						maxLength: 50,
						required: true,
						autoCapitalize: 'capitalize'
					}
				]
			},
			{
				key: 'birthGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col sm:flex-row',
				fields: [
					{
						key: 'birthPlace',
						textKey: 'birthPlace',
						className: 'sm:mr-6',
						component: EditFormTextField,
						maxLength: 40,
						required: true
					},
					{
						key: 'birthDate',
						textKey: 'birthDate',
						className: 'sm:ml-6',
						component: EditFormDateField,
						maxDate: new Date(),
						openTo: 'year',
						required: true
					}
				]
			},
			{
				key: 'gender',
				textKey: 'gender',
				component: EditFormSelectField,
				items: data.formData.genders,
				type: 'enum',
				required: true
			},
			{
				key: 'email',
				textKey: 'email',
				component: EditFormTextField,
				maxLength: 100,
				required: true
			},
			{
				key: 'phoneNumber',
				textKey: 'phoneNumber',
				mask: {
					mask: '+0000000000000'
				},
				component: EditFormTextField,
				maxLength: 50,
				required: true
			},
			{
				key: 'nationality',
				textKey: 'nationality',
				component: EditFormSelectField,
				items: data.formData.nationalities,
				required: true
			},
			{
				key: 'nationalityOther',
				textKey: 'nationalityOther',
				component: EditFormTextField,
				maxLength: 200,
				required: false
			},
			{
				key: 'fullMotherNameGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepMothersName',
						textKey: 'sepMothersName',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						icon: 'pregnant_woman',
						isControlledInput: false
					},
					{
						key: 'motherNameGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col sm:flex-row',
						fields: [
							{
								key: 'motherLastName',
								textKey: 'motherLastName',
								className: 'sm:mr-6',
								component: EditFormTextField,
								maxLength: 50,
								required: true,
								autoCapitalize: 'capitalize'
							},
							{
								key: 'motherFirstName',
								textKey: 'motherFirstName',
								className: 'sm:ml-6',
								component: EditFormTextField,
								maxLength: 50,
								required: true,
								autoCapitalize: 'capitalize'
							}
						]
					}
				]
			},
			{
				key: 'fullPasswordGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepPassword',
						textKey: 'sepPassword',
						component: EditFormSeparatorField,
						icon: 'lock',
						className: blockTitleClassName,
						isControlledInput: false
					},
					{
						key: 'passwordGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col sm:flex-row',
						fields: [
							{
								key: 'password',
								textKey: 'password',
								className: 'sm:mr-6',
								component: EditFormTextField,
								required: true,
								type: 'password',
								autoComplete: 'new-password',
								maxLength: 50
							},
							{
								key: 'passwordAgain',
								textKey: 'passwordAgain',
								className: 'sm:ml-6',
								component: EditFormTextField,
								required: true,
								type: 'password',
								autoComplete: 'new-password',
								maxLength: 50
							}
						]
					}
				]
			},
			{
				key: 'fullAddressGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepAddress',
						textKey: 'sepAddress',
						component: EditFormSeparatorField,
						icon: 'place',
						className: blockTitleClassName,
						isControlledInput: false
					},
					{
						key: 'addressGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col md:flex-row',
						fields: [
							{
								key: 'addressZipcode',
								textKey: 'addressZipcode',
								component: EditFormTextField,
								maxLength: 16,
								className: 'w-full md:w-1/5 md:mr-12',
								required: true
							},
							{
								key: 'addressCity',
								textKey: 'addressCity',
								component: EditFormTextField,
								maxLength: 40,
								className: 'w-full md:w-2/5 md:mr-12',
								required: true
							},
							{
								key: 'addressCounty',
								textKey: 'addressCounty',
								component: EditFormSelectField,
								items: data.formData.counties,
								className: 'w-full md:w-2/5',
								required: true
							}
						]
					},
					{
						key: 'addressStreetGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col md:flex-row',
						fields: [
							{
								key: 'addressStreet',
								textKey: 'addressStreet',
								component: EditFormTextField,
								maxLength: 100,
								className: 'w-full md:w-4/5 md:mr-12',
								required: true
							},
							{
								key: 'addressStreetType',
								textKey: 'addressStreetType',
								component: EditFormSelectField,
								items: data.formData.publicPlaceTypes,
								className: 'w-full md:w-1/5',
								required: true
							}
						]
					},
					{
						key: 'addressNumberGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-row',
						fields: [
							{
								key: 'addressNumber',
								textKey: 'addressNumber',
								component: EditFormTextField,
								maxLength: 50,
								className: 'w-1/3 mr-12',
								required: true
							},
							{
								key: 'addressFloor',
								textKey: 'addressFloor',
								component: EditFormTextField,
								maxLength: 10,
								className: 'w-1/3 mr-12'
							},
							{
								key: 'addressDoor',
								textKey: 'addressDoor',
								component: EditFormTextField,
								maxLength: 10,
								className: 'w-1/3'
							}
						]
					}
				]
			},
			{
				key: 'profilePicture',
				textKey: 'profilePicture',
				component: EditFormImageInputField,
				imageUrl: file => `${nBossConnection.baseUrl}fileRepository/getFile?key=${file.key}`,
				required: true
			},
			/*{
				key: 'competition',
				textKey: 'competition',
				component: EditFormSelectField,
				items: data.formData.competitorCompetition,
				required: true,
				dependsOn: 'athleteRegType',
				visibleIf: athleteRegType => athleteRegType === 'REG_HU'
			},*/
			{
				key: 'accept',
				textKey: 'accept',
				text: (
					<div>
						A Magyar Triatlon Szövetség{' '}
						<strong>
							<a href="https://triatlon.hu/szovetseg/hivatalos-dokumentumok/" target="_blank" rel="noreferrer" role="button">
								alapszabályzatát
							</a>
						</strong>{' '}
						és{' '}
						<strong>
							<a href="https://triatlon.hu/versenyek/" target="_blank" rel="noreferrer" role="button">
								versenyrendszerét
							</a>
						</strong>{' '}
						megismertem, tartalmát elfogadom, és magamra kötelező érvényűnek tekintem.
					</div>
				),
				component: EditFormCheckboxField,
				required: true
			}
		]
	};
};

function AthleteRegistrationForm(props) {
	const { type } = props;
	const [formData, setFormData] = useState(null);
	const { t } = useTranslation(['reg']);
	const dispatch = useDispatch();
	if (formData == null) {
		PersonRegistrationService.getFormData().then(result => setFormData(result));
		return <></>;
	}
	const booleanList = [
		{
			id: true,
			name: 'Igen'
		},
		{
			id: false,
			name: 'Nem'
		}
	];
	const myConfig = config({ formData, booleanList, dispatch, t, type });
	return <RegistrationFormPage config={myConfig} />;
}

export default AthleteRegistrationForm;
