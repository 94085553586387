/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import { useLocation, withRouter } from 'react-router';

function CompetitorEndMessage(props) {
	const [hidden, setHidden] = useState(false);
	const location = useLocation();

	if (location.pathname !== '/registration') {
		return <></>;
	}
	return (
		<div className="fixed z-50 bottom-0 right-0 flex w-fulll">
			<div className={`m-10 sm:m-20 p-20 bg-white rounded-20 shadow-2xl ${hidden ? 'bg-opacity-50' : 'bg-opacity-95'}`}>
				<div className="flex flex-col gap-16">
					{!hidden && <h2> Kedves Versenyzők!</h2>}

					{!hidden && (
						<div className="text-xs md:text-justify">
							Szeretnénk tájékoztatni Benneteket, hogy a Sporttörvény módosulása miatt 2024. január 1-től megszűnik az <span className="font-semibold">új egyéni regisztrációk</span> indításának lehetősége!
							<br />
							<br />
							A törvényi módosításokkal és az új lehetőségekkel kapcsolatban hamarosan jelentkezünk!
							<br />
						</div>
					)}

					<div className={`flex flex-col sm:flex-row gap-8 justify-between ${!hidden && 'border-t pt-16'}`}>
						<Button variant="contained" color={!hidden ? 'secondary' : 'primary'} className="" aria-label="CLOSE" value="legacy" onClick={() => setHidden(!hidden)}>
							{hidden ? 'Fontos információ' : 'Bezárás'}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(CompetitorEndMessage);
