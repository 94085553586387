import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { useSelector, useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { withRouter, useParams } from 'react-router';
import { useDeepCompareEffect } from '@fuse/hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { motion } from 'framer-motion';
import { Button, Typography, Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import { useEffect, useState } from 'react';
import AthleteTransferService from '../service/AthleteTransferService';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';

function AthleteAnswer(props) {
	const dispatch = useDispatch();
	const routeParams = useParams();
	const user = useSelector(({ auth }) => auth.user);
	const userId = user.data.id;
	const history = useHistory();
	const { t } = useTranslation('editform');
	const [loading, setLoading] = useState(false);
	const [id, setId] = useState(null);
	const [data, setData] = useState(null);

	const schema = yup.object().shape({
		accept: yup
			.boolean()
			.required(t('validation:FIELD_REQUIRED'))
			.test('rejectCheck', 'Egyik lehetőség megadása kötelező', (value, testContext) => {
				const { reject } = testContext.parent;
				return value || reject;
			}),
		reject: yup
			.boolean()
			.required(t('validation:FIELD_REQUIRED'))
			.test('acceptCheck', 'Egyik lehetőség megadása kötelező', (value, testContext) => {
				const { accept } = testContext.parent;
				return value || accept;
			}),
		athleteCheck1: yup
			.boolean()
			.required(t('validation:FIELD_REQUIRED'))
			.when('accept', { is: true, then: yup.boolean().oneOf([true], t('validation:FIELD_REQUIRED')) }),
		athleteCheck2: yup
			.boolean()
			.required(t('validation:FIELD_REQUIRED'))
			.when('accept', { is: true, then: yup.boolean().oneOf([true], t('validation:FIELD_REQUIRED')) }),
		description: yup.string().when('reject', { is: true, then: yup.string().required(t('validation:FIELD_REQUIRED')) })
	});

	const formMethods = useForm({
		defaultValues: { id: null, accept: false, reject: false, athleteCheck1: false, athleteCheck2: false, description: '' },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const accept = useWatch({
		control: formMethods.control,
		name: 'accept'
	});

	const reject = useWatch({
		control: formMethods.control,
		name: 'reject'
	});

	useEffect(() => {
		if (accept && reject) {
			formMethods.setValue('reject', false);
		}
	}, [accept]);

	useEffect(() => {
		if (accept && reject) {
			formMethods.setValue('accept', false);
		}
	}, [reject]);

	useDeepCompareEffect(() => {
		function updateDataState() {
			setId(routeParams.id);
		}
		updateDataState();
	}, [routeParams]);

	useEffect(() => {
		if (id) {
			AthleteTransferService.getData(id).then(resp => {
				if (resp != null) {
					setData(resp);
					formMethods.reset({ id: resp.id, accept: false, reject: false, athleteCheck1: false, athleteCheck2: false, description: '' });
				} else {
					history.push('/athlete-transfer');
				}
			});
		}
	}, [id]);

	const handleSave = values => {
		setLoading(true);
		AthleteTransferService.athleteAnswer(values.id, values.accept, values.description)
			.then(resp => {
				if (resp.successful) {
					dispatch(showMessage({ message: 'Nyilatkozat rögzítve.' }));
					history.push('/athlete-transfer');
				} else {
					dispatch(showMessage({ message: 'Hiba történt a nyilatkozat rögzítése során.' }));
				}
				setLoading(false);
			})
			.catch(e => {
				dispatch(showMessage({ message: 'Hiba történt a nyilatkozat rögzítése során.' }));
				setLoading(false);
			});
	};

	if (data != null) {
		return (
			<FusePageCarded
				classes={{
					toolbar: 'p-0',
					header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
				}}
				header={
					<FormProvider {...formMethods}>
						<div className="flex flex-1 w-full items-center justify-between">
							<div className="flex flex-col items-start max-w-full min-w-0">
								<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
									<Typography className="flex items-center sm:mb-12" component={Button} role="button" onClick={() => history.goBack(history)} color="inherit">
										<Icon className="text-20">arrow_back</Icon>
										<span className="hidden sm:flex mx-4 font-medium">Vissza</span>
									</Typography>
								</motion.div>
								<div className="flex items-center max-w-full">
									<div className="flex flex-col min-w-0 mx-8 sm:mc-16 gap-4">
										<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
											<Typography className="text-16 sm:text-20 truncate font-semibold">{data.targetClub.fullName} átigazolási kérelme</Typography>
										</motion.div>
									</div>
								</div>
							</div>
							<motion.div className="flex" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
								<Button
									className="whitespace-nowrap mx-4"
									variant="contained"
									color="secondary"
									disabled={loading}
									onClick={() => formMethods.handleSubmit(handleSave)()}
									startIcon={<Icon className="hidden sm:flex">save</Icon>}
								>
									Nyilatkozat beküldése
								</Button>
							</motion.div>
						</div>
					</FormProvider>
				}
				content={
					<div className="p-16 sm:p-24">
						<div className={`${blockClassName} mt-8 mb-16`}>
							<div className="pb-16 py-8">
								<div className="flex items-center">
									<Icon color="action">done</Icon>
									<Typography className="h2 mx-10 font-medium" color="textSecondary">
										Elfogadás
									</Typography>
								</div>
							</div>
							<Controller
								control={formMethods.control}
								name="accept"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'person' }}
										fieldConfig={{
											key: 'accept',
											text: 'Az átigazolási kérelemhez hozzájárulok.'
										}}
									/>
								)}
							/>
							<Controller
								control={formMethods.control}
								name="athleteCheck1"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'person' }}
										fieldConfig={{
											key: 'athleteCheck1',
											text: 'Adott évben a Magyar Triatlon Szövetségnél nem rendelkezem érvényes versenyengedéllyel, az MTSZ versenynaptárában szereplő eseményen még nem indultam a hatályos Átigazolási Szabályzatban leírtak szerint.'
										}}
									/>
								)}
							/>
							<Controller
								control={formMethods.control}
								name="athleteCheck2"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'person' }}
										fieldConfig={{
											key: 'athleteCheck2',
											text: 'A hatályos Átigazolási Szabályzatot elolvastam, a benne foglaltakat tudomásul vettem és teljeskörűen betartom.'
										}}
									/>
								)}
							/>
						</div>
						<div className={`${blockClassName} mt-8 mb-16`}>
							<div className="pb-16 py-8">
								<div className="flex items-center">
									<Icon color="action">close</Icon>
									<Typography className="h2 mx-10 font-medium" color="textSecondary">
										Visszautasítás
									</Typography>
								</div>
							</div>
							<Controller
								control={formMethods.control}
								name="reject"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'person' }}
										fieldConfig={{
											key: 'reject',
											text: 'Az átigazolási kérelemhez nem járulok hozzá, mert... (500 karakter max)'
										}}
									/>
								)}
							/>
							<Controller
								control={formMethods.control}
								name="description"
								render={({ field, fieldState }) => (
									<EditFormTextField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'person' }}
										fieldConfig={{
											key: 'description',
											text: 'Indoklás',
											multiline: true,
											maxLength: 500
										}}
									/>
								)}
							/>
						</div>
					</div>
				}
			/>
		);
	}
	return <FuseLoading />;
}

export default withRouter(AthleteAnswer);
