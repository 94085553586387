import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
	sessionName: null,
	config: null,
	service: null
};

const tableBrowserSessionSlice = key =>
	createSlice({
		name: `${key}/session`,
		initialState,
		reducers: {
			setConfig: (state, action) => {
				state.config = action.payload;
			},
			setSessionName: (state, action) => {
				state.sessionName = action.payload;
			},
			setService: (state, action) => {
				state.service = action.payload;
			},
			cleanTableBrowserSessionSlice: (state, action) => {
				state = initialState;
			}
		}
	});

export default tableBrowserSessionSlice;
