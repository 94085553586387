import { authRoles } from 'app/auth';
import UserTable from 'app/admin/user/table/UserTable';
import DashboardRoleTable from 'app/admin/user/table/DashboardRoleTable';
import DashboardRoleEditForm from 'app/admin/user/editform/DashboardRoleEditForm';
import UserEditForm from 'app/admin/user/editform/UserEditForm';
import AgeGroupEditForm from './editform/AgeGroupEditForm';
import AgeGroupTable from './table/AgeGroupTable';

export default [
	{
		path: '/age-group/edit/:id',
		component: AgeGroupEditForm,
		auth: authRoles.valueSet
	},
	{
		path: '/age-group/:sessionNameParam?',
		component: AgeGroupTable,
		auth: authRoles.valueSet
	}
];
