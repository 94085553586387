import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { RaceNameGen } from './RaceNameField';

function CompetitionDistanceUpdater(props) {
	const { competitionPartList } = props;
	const { setValue, getValues } = useFormContext();
	useEffect(() => {
		const distanceList = [];
		competitionPartList.forEach(comPart => {
			if (comPart.distance != null && distanceList.indexOf(comPart.distance) === -1) {
				distanceList.push(comPart.distance);
			}
		});
		getValues('distanceList').forEach((distance, index) => {
			if (distanceList.indexOf(distance.distance) === -1) {
				setValue(`distanceList.${index}.name`, null);
				setValue(`distanceList.${index}.distance`, null);
			}
		});
	}, [competitionPartList]);
	return null;
}

function RaceUpdater(props) {
	const { competitionPartList } = props;
	const { setValue, getValues } = useFormContext();
	useEffect(() => {
		getValues('raceList').forEach((race, index) => {
			const newKeyList = race.competitionPartKeyList.filter(raceCp => competitionPartList.findIndex(cp => cp.key === raceCp) > -1);
			const name = RaceNameGen(competitionPartList, newKeyList);
			if (name !== race.name) {
				setValue(`race.${index}.competitionPartKeyList`, newKeyList);
				setValue(`race.${index}.competitionPartKeyList`, newKeyList);
			}
		});
	}, [competitionPartList]);
	return null;
}

/*function EntryPriceListUpdater(props) {
	const { competitionPartList } = props;
	const { control, setValue } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'entryPriceList',
		keyName: '_id'
	});

	useEffect(() => {
		if (competitionPartList.length < fields.length) {
			const removeIndexList = [];
			fields.forEach((field, index) => {
				if (competitionPartList.findIndex(cp => cp.key === field.competitionPartKey) === -1) {
					removeIndexList.push(index);
				}
			});
			remove(removeIndexList);
		} else if (competitionPartList.length > fields.length) {
			const appendDtoList = [];
			competitionPartList.forEach(comPart => {
				if (fields.findIndex(field => comPart.key === field.competitionPartKey) === -1) {
					appendDtoList.push(
						new EntryPriceEditDto().setup({
							id: null,
							competitionPart: comPart.id,
							competitionPartKey: comPart.key,
							name: comPart.name,
							preRegDeadline: null,
							preRegAmount: null,
							entryDeadline: null,
							entryAmount: null,
							localAmount: null
						})
					);
				}
			});
			append(appendDtoList);
		} else if (competitionPartList.length === fields.length) {
			competitionPartList.forEach((comPart, comPartIndex) => {
				if (comPart.key !== fields[comPartIndex].competitionPartKey || comPart.name !== fields[comPartIndex].name) {
					setValue(`entryPriceList.${comPartIndex}.competitionPartKey`, comPart.key);
					setValue(`entryPriceList.${comPartIndex}.name`, comPart.name);
				}
			});
		}
	}, [competitionPartList]);
	return null;
}*/

function EntryPriceListUpdater(props) {
	const { raceList, formData } = props;
	const { control, setValue } = useFormContext();
	const { fields, insert, remove } = useFieldArray({
		control,
		name: 'entryPriceList',
		keyName: '_id'
	});

	useEffect(() => {
		const availableGroupAgeGroupIdList = [];
		raceList.forEach(race => {
			if (race.ageGroupList.length > 0) {
				race.ageGroupList.forEach(ageGroupId => {
					const ageGroup = formData.ageGroups.find(d => d.id === ageGroupId);
					if (ageGroup && ageGroup.parent && availableGroupAgeGroupIdList.indexOf(ageGroup.parent.id) === -1) {
						availableGroupAgeGroupIdList.push(ageGroup.parent.id);
					}
				});
			}
		});
		if (availableGroupAgeGroupIdList.length > 0) {
			const groupAgeGroupList = [];
			formData.groupAgeGroups.forEach(groupAgeGroup => {
				if (availableGroupAgeGroupIdList.indexOf(groupAgeGroup.id) > -1) groupAgeGroupList.push(groupAgeGroup);
			});
			const _fields = [];
			groupAgeGroupList.forEach((groupAgeGroup, i) => {
				const fieldIndex = fields.findIndex(f => groupAgeGroup.id === f.groupAgeGroup);
				if (fieldIndex > -1) {
					_fields.push(fields[fieldIndex]);
				} else {
					_fields.push({
						name: groupAgeGroup.name,
						groupAgeGroup: groupAgeGroup.id,
						preRegAmount1: null,
						preRegAmount2: null,
						preRegAmount3: null,
						entryAmount: null,
						localAmount: null
					});
				}
			});
			setValue('entryPriceList', _fields);
		} else if (fields.length > 0) {
			setValue('entryPriceList', []);
		}
	}, [raceList]);

	return null;
}

function CompetitionEditFormUpdater(props) {
	const { formData } = props;
	const { control } = useFormContext();
	const competitionPartList = useWatch({ control, name: 'competitionPartList' });
	const raceList = useWatch({ control, name: 'raceList' });

	return (
		<>
			{competitionPartList && <CompetitionDistanceUpdater competitionPartList={competitionPartList} />}
			{competitionPartList && <RaceUpdater competitionPartList={competitionPartList} />}
			{competitionPartList && <EntryPriceListUpdater raceList={raceList} formData={formData} />}
		</>
	);
}
export default CompetitionEditFormUpdater;
