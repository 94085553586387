import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class TrainerEditDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {number} */
		this.qualification = null;
		/** @type {string} */
		this.qualificationOther = null;
		/** @type {string} */
		this.certificate = null;
		/** @type {string} */
		this.qualificationSport = null;
		/** @type {boolean} */
		this.acceptSelfRecommendation = null;
	}

	/**
	 * @typedef {Object} _TrainerEditDtoProps
	 * @property {number} qualification
	 * @property {string} qualificationOther
	 * @property {string} certificate
	 * @property {string} qualificationSport
	 * @property {boolean} acceptSelfRecommendation
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _TrainerEditDtoProps} TrainerEditDtoProps
	 */
	/**
	 *
	 * @param {TrainerEditDtoProps} props
	 * @returns {TrainerEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.qualification = props.qualification;
		this.qualificationOther = props.qualificationOther;
		this.certificate = props.certificate;
		this.qualificationSport = props.qualificationSport;
		this.acceptSelfRecommendation = props.acceptSelfRecommendation;
		return this;
	}
}

export default TrainerEditDto;
