import { authRoles } from 'app/auth';
import AthleteTable from './table/AthleteTable';
import CompetitorTable from './table/CompetitorTable';
import RefereeTable from './table/RefereeTable';
import TrainerTable from './table/TrainerTable';
import PersonEditForm from './editform/PersonEditForm';
import TechnicalCommitteeTable from './table/TechnicalCommitteeTable';

export default [
	{
		path: '/athlete/edit/:id',
		component: () => <PersonEditForm type="athlete" />,
		auth: authRoles.athlete
	},
	{
		path: '/athlete/:sessionNameParam?',
		component: AthleteTable,
		auth: authRoles.athlete
	},
	{
		path: '/competitor/edit/:id',
		component: () => <PersonEditForm type="competitor" />,
		auth: authRoles.competitor
	},
	{
		path: '/competitor/:sessionNameParam?',
		component: CompetitorTable,
		auth: authRoles.competitor
	},
	{
		path: '/referee/edit/:id',
		component: () => <PersonEditForm type="referee" />,
		auth: authRoles.referee
	},
	{
		path: '/referee/:sessionNameParam?',
		component: RefereeTable,
		auth: authRoles.referee
	},
	{
		path: '/trainer/edit/:id',
		component: () => <PersonEditForm type="trainer" />,
		auth: authRoles.trainer
	},
	{
		path: '/trainer/:sessionNameParam?',
		component: TrainerTable,
		auth: authRoles.trainer
	},
	{
		path: '/technicalCommittee/edit/:id',
		component: () => <PersonEditForm type="technicalCommittee" />,
		auth: authRoles.technicalCommittee
	},
	{
		path: '/technicalCommittee/:sessionNameParam?',
		component: TechnicalCommitteeTable,
		auth: authRoles.technicalCommittee
	}
];
