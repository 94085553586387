import NamedDto from 'modules/base/dto/NamedDto';

export class EmailTemplateEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.locale = null;
		/** @type {string} */
		this.subject = null;
		/** @type {string} */
		this.text = null;
		/** @type {string} */
		this.html = null;
	}

	/**
	 * @typedef {Object} _EmailTemplateEditDtoProps
	 * @property {string} locale
	 * @property {string} subject
	 * @property {string} text
	 * @property {string} html
	 * @typedef {import("../NamedDto").NamedDtoProps & _EmailTemplateEditDtoProps} EmailTemplateEditDtoProps
	 */
	/**
	 *
	 * @param {EmailTemplateEditDtoProps} props
	 * @returns {EmailTemplateEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.locale = props.locale != null ? props.locale : 'hu';
		this.subject = props.subject;
		this.text = props.text;
		this.html = props.html;
		return this;
	}
}

export default EmailTemplateEditDto;
