import _ from '@lodash';
import { useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import CompetitionTableService from '../service/CompetitionTableService';

const config = data => {
	return {
		key: 'competitionTable',
		text: 'Megpályázott események',
		textKey: 'competition',
		service: CompetitionTableService,
		editFormState: item => `/competition/edit/${item ? item.id : 'new'}`,
		toolbarAction: _.isArray(data.role) && data.role.includes('NewCompetition') ? ['search', 'refresh', 'new'] : ['search', 'refresh'],
		rowAction: ['subPage', 'modify', 'delete'],
		subPage: {
			tooltip: 'Nevezések',
			state: '/competition-entry/',
			disabledIf: item => !item.approved
		},
		columns: [
			{
				key: 'name',
				text: 'Név',
				textKey: 'name',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'date',
				text: 'Dátum',
				textKey: 'date',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'orgName',
				text: 'Szervező neve',
				textKey: 'orgName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'submitted',
				text: 'Beküldve?',
				textKey: 'submitted',
				type: 'boolean',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'adminApproval',
				text: 'Admin státusz',
				textKey: 'adminApproval',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'technicalCommitteeApproval',
				text: 'Tech.felügyelő státusz',
				textKey: 'technicalCommitteeApproval',
				type: 'text',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

function CompetitionTable() {
	const user = useSelector(({ auth }) => auth.user);

	const myConfig = config({ isSuperAdmin: user.isSuperAdmin, accessType: user.accessType, role: user.role });

	return <TableBrowser config={myConfig} />;
}

export default withReducer('competitionTable', reducer('competitionTable'))(CompetitionTable);
