import { useState } from 'react';
import { motion } from 'framer-motion';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import _ from '@lodash';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { EditFormSubItemFields } from 'modules/ui/editform/subForm/EditFormSubItem';

function EntryPriceSubItemComponent(props) {
	const methods = useFormContext();
	const { t } = useTranslation('editform');
	const { watch, control } = methods;
	const id = watch(`${props.fieldPrefix}id`);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const name = _.isFunction(props.fieldConfig.watchTitleKey)
		? props.fieldConfig.watchTitleKey(props.fieldPrefix, watch())
		: watch(`${props.fieldPrefix}${_.isUndefined(props.fieldConfig.watchTitleKey) ? 'name' : props.fieldConfig.watchTitleKey}`);

	const handleDirectionOnClick = direction => {
		if (props.addReference) props.onAdd(direction);
		else if (props.selectedToMoveTarget) props.onMove(direction);
	};

	return (
		<motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.05 * props.order } }}>
			<div className="rounded-8 border px-16 pt-10 pb-4 mb-16">
				<div className="flex flex-row">
					<div className="py-8 pb-16">
						<div className="flex items-center">
							<Typography className="h2 mx-10 font-medium" color="textSecondary">
								{name || t('SUB_NEW_ITEM')}
							</Typography>
						</div>
					</div>
					<div className="flex flex-row flex-1 justify-end">
						{!props.readOnly && !props.fixedLengthList ? (
							<div>
								<IconButton aria-label="delete" onClick={() => setShowDeleteConfirm(true)}>
									<DeleteIcon fontSize="small" />
								</IconButton>
							</div>
						) : null}
					</div>
				</div>
				<EditFormSubItemFields config={props.config} fieldConfig={props.fieldConfig} fieldPrefix={props.fieldPrefix} field={props.field} />
			</div>
			<ConfirmDialog show={showDeleteConfirm} title={t('SUB_DELETE')} description={t('SUB_DELETE_MESSAGE')} onYes={() => props.onDelete()} onClose={() => setShowDeleteConfirm(false)} />
		</motion.div>
	);
}

export default EntryPriceSubItemComponent;
