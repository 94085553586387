import OrderableNamedDto from 'app/admin/valueset/dto/OrderableNamedDto';
import _ from 'lodash';

export default class DistanceEditDto extends OrderableNamedDto {
	constructor() {
		super();
		/** @type {number[]} */
		this.ageGroupList = null;
	}

	/**
	 * @typedef {Object} _DistanceEditDtoProps
	 * @property {number[]} ageGroupList
	 *
	 * @typedef {import("app/admin/valueset/dto/OrderableNamedDto").OrderableNamedDtoProps & _DistanceEditDtoProps} DistanceEditDtoProps
	 */
	/**
	 *
	 * @param {DistanceEditDtoProps} props
	 * @returns {DistanceEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.ageGroupList = props.ageGroupList != null ? props.ageGroupList : [];
		return this;
	}
}
