import { useState } from 'react';
import { motion } from 'framer-motion';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon, Accordion, AccordionDetails, AccordionSummary, Typography, Card } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import _ from '@lodash';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { fixDependsOn } from '../EditFormUtils';

const titleMessageSeparator = '\u00A0\u00A0-\u00A0\u00A0';

const useStyles = makeStyles(theme => ({
	card: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
		color: theme.palette.text.secondary
	}
}));

function EditFormSubItem(props) {
	const classes = useStyles();
	const methods = useFormContext();
	const { t } = useTranslation('editform');
	const { watch } = methods;
	const id = watch(`${props.fieldPrefix}id`);

	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const name = _.isFunction(props.fieldConfig.watchTitleKey)
		? props.fieldConfig.watchTitleKey(props.fieldPrefix, watch())
		: watch(`${props.fieldPrefix}${_.isUndefined(props.fieldConfig.watchTitleKey) ? 'name' : props.fieldConfig.watchTitleKey}`);
	const [expanded, setExpanded] = useState(_.isUndefined(name) || name == null || name === t('SUB_NEW_ITEM'));
	const handleDirectionOnClick = direction => {
		if (props.addReference) props.onAdd(direction);
		else if (props.selectedToMoveTarget) props.onMove(direction);
	};

	const hasError = _.get(methods.formState.errors, props.fieldPrefix.substring(0, props.fieldPrefix.length - 1));

	return (
		<motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.05 * props.order } }}>
			<Accordion className="border-0 shadow-0 overflow-hidden" expanded={expanded}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					onClick={() => {
						setExpanded(!expanded);
					}}
					classes={{ root: `flex overflow-hidden border border-solid rounded-16 mb-16 ${hasError ? 'border-red-500' : ''}`, content: 'items-center' }}
				>
					<div className="flex flex-grow flex-col md:flex-row">
						<Typography className="font-medium flex text-xs md:text-normal">{name || t('SUB_NEW_ITEM')}</Typography>
						<motion.div className="flex" initial="hidden" animate={props.selectedToMoveBase || props.selectedToMoveTarget ? 'visible' : 'hidden'} variants={{ visible: { opacity: 1, x: 0 }, hidden: { opacity: 0, x: 40 } }}>
							<Typography className="opacity-50 hidden md:block text-xs md:text-normal">{props.selectedToMoveBase || props.selectedToMoveTarget ? titleMessageSeparator : ''}</Typography>
							<EditFormSubItemSelectedToMoveText selectedToMoveBase={props.selectedToMoveBase} selectedToMoveTarget={props.selectedToMoveTarget} />
						</motion.div>
					</div>
					<motion.div
						className="flex items-center"
						initial="hidden"
						animate={props.selectedToMoveTarget || props.addReference ? 'visible' : 'hidden'}
						variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0, transitionEnd: { display: 'none' } } }}
					>
						<EditFormSubItemBefore selectedToMoveTarget={props.selectedToMoveTarget} addReference={props.addReference} onClick={dir => handleDirectionOnClick(dir)} />
						<EditFormSubItemAfter selectedToMoveTarget={props.selectedToMoveTarget} addReference={props.addReference} onClick={dir => handleDirectionOnClick(dir)} />
					</motion.div>
					<EditFormSubItemAdd readOnly={props.readOnly} addReference={props.addReference} fieldConfig={props.fieldConfig} onClick={() => props.onSelectToAdd()} />
					<EditFormSubItemMove readOnly={props.readOnly} fieldConfig={props.fieldConfig} selectedToMoveBase={props.selectedToMoveBase} selectedToMoveTarget={props.selectedToMoveTarget} onClick={() => props.onSelectToMove()} />
					<EditFormSubItemDelete readOnly={props.readOnly} fixedLengthList={props.fixedLengthList} onClick={() => setShowDeleteConfirm(true)} />
				</AccordionSummary>

				<AccordionDetails className="flex flex-col px-0 pt-0 -mt-16">
					<Card className={clsx(classes.card, 'w-full rounded-16 shadow p-24 mt-16')}>
						<div>
							<div className="flex flex-row items-center">
								<Icon className="text-20 mr-8 opacity-30">settings</Icon>
								<Typography className="h3 sm:h2 font-medium">{`${name || t('SUB_NEW_ITEM')} ${t('SUB_ITEM_SETTINGS')}`}</Typography>
							</div>

							<Typography className="w-full mx-8 text-16 mb-16" />

							<Card className="w-full rounded-10 shadow p-24 mb-16">
								<EditFormSubItemFields config={props.config} fieldConfig={props.fieldConfig} fieldPrefix={props.fieldPrefix} field={props.field} />
							</Card>
						</div>
					</Card>
				</AccordionDetails>
			</Accordion>
			<ConfirmDialog show={showDeleteConfirm} title={t('SUB_DELETE')} description={t('SUB_DELETE_MESSAGE')} onYes={() => props.onDelete()} onClose={() => setShowDeleteConfirm(false)} />
		</motion.div>
	);
}

function EditFormSubItemSelectedToMoveText(props) {
	const { t } = useTranslation('editform');
	return (
		<Typography className="opacity-50 text-xs md:text-normal">
			{props.selectedToMoveBase || props.selectedToMoveTarget ? (props.selectedToMoveBase ? t('SUB_SELECTED_TO_MOVE') : '') + (props.selectedToMoveTarget ? t('SUB_SELECTED_TO_MOVE_TARGET') : '') : ''}
		</Typography>
	);
}

function EditFormSubItemBefore(props) {
	const { t } = useTranslation('editform');
	return (
		<motion.div initial="hidden" animate={props.selectedToMoveTarget || props.addReference ? 'visible' : 'hidden'} variants={{ visible: { x: 0, opacity: 1 }, hidden: { x: -60, opacity: 0 } }}>
			<Button
				className="whitespace-nowrap mr-8"
				variant="contained"
				color="secondary"
				startIcon={<Icon className="hidden sm:flex">keyboard_arrow_up</Icon>}
				onClick={e => {
					props.onClick(-1);
					e.stopPropagation();
				}}
			>
				<span>{t('SUB_BEFORE')}</span>
			</Button>
		</motion.div>
	);
}

function EditFormSubItemAfter(props) {
	const { t } = useTranslation('editform');
	return (
		<motion.div initial="hidden" animate={props.selectedToMoveTarget || props.addReference ? 'visible' : 'hidden'} variants={{ visible: { x: 0, opacity: 1 }, hidden: { x: -40, opacity: 0 } }}>
			<Button
				className="whitespace-nowrap mr-8"
				variant="contained"
				color="secondary"
				startIcon={<Icon className="hidden sm:flex">keyboard_arrow_down</Icon>}
				onClick={e => {
					props.onClick(1);
					e.stopPropagation();
				}}
			>
				<span>{t('SUB_AFTER')}</span>
			</Button>
		</motion.div>
	);
}

function EditFormSubItemAdd(props) {
	if (props.readOnly && props.fieldConfig.orderable)
		return (
			<IconButton
				aria-label="add"
				onClick={e => {
					props.onClick();
					e.stopPropagation();
				}}
			>
				{props.addReference ? <CloseIcon fontSize="small" /> : <AddIcon fontSize="small" />}
			</IconButton>
		);
	return null;
}

function EditFormSubItemMove(props) {
	if (!props.readOnly && props.fieldConfig.orderable)
		return (
			<IconButton
				aria-label="move"
				onClick={e => {
					props.onClick();
					e.stopPropagation();
				}}
			>
				{props.selectedToMoveBase || props.selectedToMoveTarget ? <CloseIcon fontSize="small" /> : <DragIndicatorIcon fontSize="small" />}
			</IconButton>
		);
	return null;
}

function EditFormSubItemDelete(props) {
	if (!props.fixedLengthList && !props.readOnly)
		return (
			<IconButton
				aria-label="delete"
				onClick={e => {
					props.onClick();
					e.stopPropagation();
				}}
			>
				<DeleteIcon fontSize="small" />
			</IconButton>
		);
	return null;
}

export function EditFormSubItemFields(props) {
	const { config, fieldConfig, fieldPrefix } = props;
	return fieldConfig.fields.map(fieldRow => <EditFormSubItemField key={fieldRow.key} fieldRow={fieldRow} fieldPrefix={fieldPrefix} config={config} fieldConfig={fieldConfig} />);
}

export function EditFormSubItemField(props) {
	const { fieldRow, fieldPrefix, config, fieldConfig } = props;
	const methods = useFormContext();
	const { control } = methods;

	const watchFields = useWatch({
		name: fixDependsOn(fieldRow.dependsOn, fieldPrefix),
		control
	});

	if (_.isUndefined(fieldRow.visibleIf) || fieldRow.visibleIf(watchFields, methods)) {
		if (_.isUndefined(fieldRow.fields) && fieldRow.isControlledInput !== false) {
			return (
				<Controller
					key={`${fieldPrefix}${fieldRow.key}`}
					name={`${fieldPrefix}${fieldRow.key}`}
					control={control}
					defaultValue={fieldConfig[fieldRow.key]}
					render={({ field, fieldState }) => <fieldRow.component field={field} fieldState={fieldState} config={config} fieldConfig={fieldRow} fieldPrefix={fieldPrefix} />}
				/>
			);
		}
		return <fieldRow.component key={`${fieldPrefix}${fieldRow.key}`} name={`${fieldPrefix}${fieldRow.key}`} config={config} fieldConfig={fieldRow} fieldPrefix={fieldPrefix} field={props.field} />;
	}
	return null;
}

export default EditFormSubItem;
