import { authRoles } from 'app/auth';
import AthleteTransferTable from './table/AthleteTransferTable';
import StartAthleteTransfer from './component/StartAthleteTransfer';
import AthleteAnswer from './component/AthleteAnswer';
import ClubAnswer from './component/ClubAnswer';
import AdminAnswer from './component/AdminAnswer';

export default [
	{
		path: '/athlete-transfer-start',
		component: StartAthleteTransfer,
		auth: authRoles.athleteTransfer
	},
	{
		path: '/athlete-answer/:id',
		component: AthleteAnswer,
		auth: authRoles.athleteTransfer
	},
	{
		path: '/club-answer/:id',
		component: ClubAnswer,
		auth: authRoles.athleteTransfer
	},
	{
		path: '/admin-answer/:id',
		component: AdminAnswer,
		auth: authRoles.athleteTransfer
	},
	{
		path: '/athlete-transfer/:sessionNameParam?',
		component: AthleteTransferTable,
		auth: authRoles.athleteTransfer
	}
];
