import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';
import OrderableNamedDto from '../dto/OrderableNamedDto';

class OrderableValueSetEditService {
	constructor(controlName) {
		this.connection = nBossConnection.connection;
		this.CONTROL = controlName;
	}

	/**
	 * @return {Promise<OrderableNamedDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new OrderableNamedDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<OrderableNamedDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new OrderableNamedDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {OrderableNamedDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {OrderableNamedDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}
}

export default OrderableValueSetEditService;
