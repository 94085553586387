import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';

const config = data => {
	return {
		key: data.tableKey,
		text: data.title,
		service: data.service,
		editFormState: item => `/${data.path}/edit/${item ? item.id : 'new'}`,
		toolbarAction: ['search', 'refresh', 'new'],
		rowAction: ['modify', 'delete'],
		columns: [
			{
				key: 'name',
				text: 'Név',
				textKey: 'name',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'orderIndex',
				text: 'Sorrend',
				textKey: 'orderIndex',
				type: 'text',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

function OrderableValueSetTable(props) {
	const myConfig = config(props);
	return <TableBrowser config={myConfig} />;
}

export default OrderableValueSetTable;
