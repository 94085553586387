import GenericTableService from 'modules/base/service/GenericTableService';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import PersonTransferListDto from '../dto/PersonTransferListDto';
import AthleteTransferEditDto from '../dto/AthleteTransferEditDto';
import AthleteTransferTableDto from '../dto/AthleteTransferTableDto';

class AthleteTransferService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'athleteTransferControl';
	}

	/**
	 * @param {number} transferId
	 * @return {Promise<AthleteTransferTableDto>}
	 */
	getData(transferId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					transferId
				})
				.then(response => resolve(response.data != null ? new AthleteTransferTableDto().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} athleteId
	 * @param {string} paymentMode
	 * @param {string} parentalPermission
	 * @return {Promise<EntityValidationResult>}
	 */
	startTransfer(athleteId, paymentMode, parentalPermission) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/startTransfer`, {
					athleteId,
					paymentMode,
					parentalPermission
				})
				.then(response => resolve(response.data != null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {string} licenseNumber
	 * @return {Promise<PersonTransferListDto>}
	 */
	searchAthlete(licenseNumber) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/searchAthlete`, {
					licenseNumber
				})
				.then(response => resolve(response.data != null ? new PersonTransferListDto().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} transferId
	 * @param {boolean} accept
	 * @param {string} description
	 * @return {Promise<PersonTransferListDto>}
	 */
	adminAnswer(transferId, accept, description) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/adminAnswer`, {
					transferId,
					accept,
					description
				})
				.then(response => resolve(response.data != null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} transferId
	 * @param {boolean} accept
	 * @param {string} description
	 * @return {Promise<PersonTransferListDto>}
	 */
	athleteAnswer(transferId, accept, description) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/athleteAnswer`, {
					transferId,
					accept,
					description
				})
				.then(response => resolve(response.data != null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} transferId
	 * @param {boolean} accept
	 * @param {string} description
	 * @return {Promise<PersonTransferListDto>}
	 */
	clubAnswer(transferId, accept, description) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/clubAnswer`, {
					transferId,
					accept,
					description
				})
				.then(response => resolve(response.data != null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} transferId
	 * @return {Promise<EntityValidationResult>}
	 */
	paymentReceived(transferId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/paymentReceived`, {
					transferId
				})
				.then(response => resolve(response.data != null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}
}

export default new AthleteTransferService();
