import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import huLocale from 'date-fns/locale/hu';

const formatDate = 'yyyy MMMM d.';
const formatDateTime = 'yyyy MMMM d. HH:mm';
const formatOptions = {
	locale: huLocale
};

function CalendarViewEntryPriceList(props) {
	const { data } = props;
	const [headers, setHeaders] = useState([]);

	useEffect(() => {
		if (data && data.entryPriceList) {
			const _headers = [];
			if (data.preRegDeadline1 != null) {
				_headers.push({
					key: 'preRegAmount1',
					value: `${format(data.preRegDeadline1, formatDate, formatOptions)}-ig`
				});
			}
			if (data.preRegDeadline2 != null) {
				_headers.push({
					key: 'preRegAmount2',
					value: `${format(data.preRegDeadline2, formatDate, formatOptions)}-ig`
				});
			}
			if (data.preRegDeadline3 != null) {
				_headers.push({
					key: 'preRegAmount3',
					value: `${format(data.preRegDeadline3, formatDate, formatOptions)}-ig`
				});
			}
			if (data.regDeadline != null) {
				_headers.push({
					key: 'entryAmount',
					value: `${format(data.regDeadline, formatDate, formatOptions)}-ig`
				});
			}
			const localAmount = data.entryPriceList.findIndex(d => d.localAmount != null);
			if (localAmount > -1) {
				_headers.push({
					key: 'localAmount',
					value: 'Helyszíni'
				});
			}
			setHeaders(_headers);
		}
	}, [data]);

	return (
		<TableContainer component={Paper} className="mb-12">
			<Table className="w-full" aria-label="simple table">
				<TableHead>
					<TableRow className="font-bold">
						<TableCell>Korcsoport</TableCell>
						{headers.map(header => (
							<TableCell align="right">{header.value}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.entryPriceList.map((entryPrice, idx) => (
						<TableRow key={idx}>
							<TableCell scope="row">{entryPrice.groupAgeGroup.name}</TableCell>
							{headers.map(header => (
								<TableCell key={header.key} align="right">
									{entryPrice[header.key] != null ? `${entryPrice[header.key]} ,-` : '-'}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default CalendarViewEntryPriceList;
